import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/withJWTServer';
import { actionTypes } from '../actionTypes';

export const fetchSettingsStatus = createAsyncThunk(
  actionTypes.FETCH_SETTINGS_STATUS,
  async () => {
    const response = await axiosInstance.get('/admin/categories-type');
    return response.data;
  }
);

export const updateMatrimonyStatus = createAsyncThunk(
  actionTypes.UPDATE_MATRIMONY_STATUS,
  async (status) => {
    const response = await axiosInstance.post('/admin/categories-type', { category: "MATRIMONY" , status: status? 1:0 });
    return response.data;
  }
);

export const updateRecruitmentStatus = createAsyncThunk(
  actionTypes.UPDATE_RECRUITMENT_STATUS,
  async (status) => {
    const response = await axiosInstance.post('/admin/categories-type', { category: "RECRUITMENT" , status: status? 1:0 });
    return response.data;
  }
);
