import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addVendorJob = createAsyncThunk(actionTypes.ADD_VENDOR_JOB, async (params, thunkAPI) => {
  try {
    // const response = await axiosInstance.post('/admin/reviewer', params);
    // console.log('response of add reviewer', response);
    // return response;
  } catch (error) {
    // return thunkAPI.rejectWithValue(error);
  }
});

export const updateVendorJob = createAsyncThunk(actionTypes.UPDATE_VENDOR_JOB, async (params, thunkAPI) => {
  try {
    // const response = await axiosInstance.patch('/admin/reviewer', params);
    // console.log('response of update reviewer', response);
    // return response;
  } catch (error) {
    // return thunkAPI.rejectWithValue(error);
  }
});

export const getVendorJob = createAsyncThunk(actionTypes.GET_SINGLE_VENDOR_JOB, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.get(`/admin/get-vendor-jobs-by-id?uuid=${params}`);
    console.log('response of get education', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getVendorJobs = createAsyncThunk(actionTypes.GET_VENDOR_JOBS, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-jobs?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin/get-recruitment-jobs?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteVendorJob = createAsyncThunk(actionTypes.DELETE_VENDOR_JOB, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/delete-vendor-jobs?uuid=${params?.uuid}`);
    console.log('response of delet reviewer', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

//   DDWNLOAD excel-------------
export const downloadVendorJobExcel = createAsyncThunk(actionTypes.DOWNLOAD_EXCEL, async (params, thunkAPI) => {
  console.log('params in dwnload ecxwl', params);
  try {
    axiosInstance
      .get(`/admin/get-recruitment-jobs?`, {
        responseType: 'blob',
      })
      .then((res) => {
        console.log('rwescs', res);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `VendorJobList.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    // return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
    // dispatch(HandleExceptionWithSecureCatch(err));
  }
});
