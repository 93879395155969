import { createSlice } from '@reduxjs/toolkit';
import {
  addLanguage,
  updateLanguage,
  getLanguages,
  getLanguage,
  deleteLanguage,
} from '../../actions/recruitment/languageActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  language: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const Language = createSlice({
  name: 'Language',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseLanguage(builder, addLanguage);
    caseLanguage(builder, updateLanguage);
    caseLanguage(builder, getLanguages);
    caseLanguage(builder, getLanguage);
    caseLanguage(builder, deleteLanguage);
  },
});
export default Language.reducer;

const caseLanguage = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);
  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_LANGUAGE)) {
      state.isLoadingt = false;
      state.language = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_LANGUAGES)) {
      state.isLoading = false;
      state.language = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    }
  });
};
