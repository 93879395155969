import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Card, Grid, TextField, Container, Typography, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { addMaritalStatus, updateMaritalStatus } from '../../../Redux-Store/actions/MatrimonyActions';
import ShowMessage from '../../../Component/ShowMessage';
import BackButton from '../../../components/BackButton';

const CreateMaritalStatus = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const maritalStatusFormData = useLocation();

  const maritalStatusData = maritalStatusFormData?.state?.maritalStatusData;

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const MaritalStatusSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^\s*\S.*$/, ' Only spaces are not allowed ')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special characters not allowed')
      .required('Marital Status is required')
      .min(2, 'Marital Status should not less than 2 characters')
      .max(30, 'Marital Status should not more than 30 characters'),
    description: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      // .matches(/^[A-Za-z0-9 ]+$/, ' Special charators not allowed')
      // .required('Description  is required')
      .min(2, 'Description should not less than 2 characters')
      .max(30, 'Description should not more than 30 characters'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: maritalStatusData ? maritalStatusData.name : '',
      description: maritalStatusData ? maritalStatusData.description : '',
      remember: true,
    },
    validationSchema: MaritalStatusSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = maritalStatusData
        ? {
            uuid: maritalStatusData.uuid,
            name: value.name,
            description: value.description,
          }
        : {
            name: value.name.toString().trim(),
            description: value.description.toString().trim(),
            parent_uuid: '',
          };
      if (maritalStatusData?.uuid) {
        dispatch(updateMaritalStatus(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            if (error.response.status === 400) {
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setSubmitLoader(false);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addMaritalStatus(params))
          .unwrap()
          .then((response) => {
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <BackButton />
        <Typography variant="h6" fontWeight={'600'}>
          {maritalStatusData ? ' Update Marital Status' : ' Add Marital Status'}
        </Typography>
        <Typography fontSize={14}>Enter your Marital Status details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={4} sx={{ padding: '40px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    label="Marital Status *"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="description"
                    label="Description"
                    {...getFieldProps('description')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={submitLoader && true}
                    // size="medium"
                    type="submit"
                    variant="contained"
                    sx={{
                      float: 'right',
                      '&:hover': {
                        background: '#0B8238',
                        opacity: 0.9,
                      },
                    }}
                  >
                    {maritalStatusData ? 'Update' : ' Save'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateMaritalStatus;
