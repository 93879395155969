import { createSlice } from '@reduxjs/toolkit';
import {
  addEducation,
  updateEducation,
  getEducations,
  getEducation,
  deleteEducation,
  updateEducationStatus,
} from '../../actions/recruitment/educationActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  education: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const Education = createSlice({
  name: 'Education',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseEducation(builder, addEducation);
    caseEducation(builder, updateEducation);
    caseEducation(builder, getEducations);
    caseEducation(builder, getEducation);
    caseEducation(builder, deleteEducation);
    caseEducation(builder, updateEducationStatus);
  },
});
export default Education.reducer;

const caseEducation = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_EDUCATION)) {
      state.education = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_EDUCATION)) {
      state.isLoadingt = false;
      state.education = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_EDUCATION)) {
      state.education = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_EDUCATIONS)) {
      state.isLoading = false;
      state.education = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_EDUCATION)) {
      state.education = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_EDUCATION_STATUS)) {
      state.education = action.payload;
      state.isLoading = false;
    }
  });
};
