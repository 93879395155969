import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Input = styled('input')({
  display: 'none',
});

const BootstrapCloseButton = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 5,
        top: 5,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

BootstrapCloseButton.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ProfilePhotoModal(props) {
  const { isOpen, imageList } = props;

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={isOpen} onClose={handleClose}>
        {/* <BootstrapCloseButton id="customized-dialog-title" onClose={handleClose} /> */}
        {/* <DialogContent dividers> */}
        {/* <Grid container spacing={12}> */}
        <Grid item xs={12} style={{ width: '600px', height: '500px' }}>
          <Carousel showThumbs={false} swipeable autoPlay emulateTouch showStatus={false}>
            {imageList.map((val, index) => (
              <img src={val} alt="im" style={{ width: '600px', height: '500px' }} />
            ))}
          </Carousel>
        </Grid>
        {/* </Grid> */}
        {/* </DialogContent> */}
      </BootstrapDialog>
    </div>
  );
}
