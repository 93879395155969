import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser',
  },
});

export const createPayment = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_VENDOR,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser',
  },
});
