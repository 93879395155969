import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import { requestForToken } from '../../firebase';
import axiosInstance from '../../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';

export const dashboardStateAction = createAsyncThunk(actionTypes.GET_DASHBOARD_STATE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.get('admin/dashboard-stats');
    console.log('response of dashboard', response);
    HandleExceptionWithSecureCatch(response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
