import { useEffect } from 'react';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import Router from './routes';
import { requestForToken } from './firebase';
import './App.css';

// Push commit
export default function App() {
  useEffect(() => {
    requestForToken();
  }, []);

  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}
