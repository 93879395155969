import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const getMatrimonySendRequests = createAsyncThunk(
  actionTypes.GET_MATRIMONY_SEND_REQUESTS,
  async (params, thunkAPI) => {
    try {
      const url = `/admin/get-matrimony-candidate-request-sent?uuid=${params.candidteId}&sentPage=${
        params?.page + 1
      }&sentLimit=${params?.rowsPerPage}`;

      // &sentPage=${
      //   params?.page + 1
      // }&sentLimit=${params?.rowsPerPage} &recivedPage=${params?.page + 1}&recivedLimit=${params?.rowsPerPage}`;
      const response = await axiosInstance.get(url);
      console.log('response of send req', response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteMatrimonySendRequests = createAsyncThunk(
  actionTypes.DELETE_MATRIMONY_SEND_REQUEST,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(`/admin/delete-matrimony-candidate-request-sent?uuid=${params.uuid}`);
      console.log('response of send req', response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
