import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// material
import { Card, Grid, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { deleteReviewer, getReviewers, updateReviewer } from '../../Redux-Store/actions/reviewerActions';
import Page from '../../components/Page';
// import CommonDeleteModel from '../../Component/CommonDeleteModel';
import ActiveDeactiveModel from '../../Component/ActiveDeactiveModel';
import DataGridHeader from '../../Component/DataGridComponents/DataGridHeader';
import ActionColumn from '../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../Component/PagesTopBar';
import SwitchBtn from '../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../Component/DataGridComponents/StatusColumn';
import ShowMessage from '../../Component/ShowMessage';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../Component/ModelBox/CommonActiveDeactiveBox';

// ----------------------------------------------------------------------
function CustomToolbar(props) {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (e) => {
    console.log('column search', e.target.value);
    // Update the state if you want instant updates
    setSearch(e.target.value);
  };

  const onKeyDown = (e) => {
    // console.log("dwn");
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  };
  const handleColumnSelection = (e, value) => {
    console.log('selection ', value);
  };
  return (
    <DataGridHeader
      showSearch
      showBulkAction={false}
      showDropdown={false}
      showExport
      defaultPropsCols=""
      onAutocompleteChange=""
      quickFilterProps=""
      handleClick={handleClick}
      handleCloseMenu={handleCloseMenu}
      openMenu={openMenu}
      anchorEl={anchorEl}
      onchange={onChange}
      onkeydown={onKeyDown}
      searchValue={search}
      handleColumnSelection={handleColumnSelection}
    />
  );
}

// ----------------------------------------------------------------------

export default function Reviewers() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { reviewers, isloading } = useSelector((state) => ({
    reviewers: state.reviewer?.reviewer?.data,
    isloading: state.reviewer?.isLoading,
  }));

  console.log('reviewers', reviewers);
  console.log('isloading', isloading);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [reviewerDelete, setReviewerDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [serialNumber, setSerialNumber] = useState(0);
  const [submitLoader, setSubmitLoader] = useState(false);

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getReviewers(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (uuid, slug, isactive) => {
    console.log('handleActive', uuid, slug, isactive);

    let params;
    if (slug === 1) {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    } else {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    }
    console.log('params in update', params);
    dispatch(updateReviewer(params))
      .unwrap()
      .then((response) => {
        setSubmitLoader(true);
        console.log('response in update reviewer', response);
        if (response.status === 200) {
          setSubmitLoader(false);
          setOpenActiveDeactive(false);
          setActivateDeactivateObject({});
          setIsShowUpdate(true);
          setErrorMessage(response.data.message);
          dispatch(getReviewers(paramsData));
        }
        setTimeout(() => {
          setIsShowUpdate(false);
          // navigate(-1);
        }, 1000);
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      })
      .catch((err) => {
        // setOpenSuspendModel(false);
        setSubmitLoader(false);
        setActivateDeactivateObject({});
      });
  };

  const handleDelete = (e) => {
    setReviewerDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteReviewer({ uuid: reviewerDelete }))
      .unwrap()
      .then((response) => {
        setSubmitLoader(true);
        console.log('response in delete reviewer', response);
        if (response.status === 200) {
          setSubmitLoader(false);
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getReviewers(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        setSubmitLoader(false);
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object090909', object);
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      uuid: object?.uuid,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    console.log('activeDeactivateObject', activeDeactivateObject);
    handleActivate(activeDeactivateObject?.uuid, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard/create_reviewer', {
      state: {
        reviewerData: rowData,
      },
    });
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setFilterData(value);
    setSearchValue(value);
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  const columns = [
    {
      field: 'reviewer_id',
      headerName: 'Sr no',
      width: 100,
      // flex: 0.6,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: `firstname`,
      headerName: 'Full Name',
      width: 150,
      type: 'string',
      // flex: 2,
      renderCell: (params) => (
        <Typography fontSize={'14px'}>
          {params.row.firstname} {params.row.lastname}
        </Typography>
      ),
    },

    { field: `email`, headerName: 'Email', width: 150, type: 'string' },
    {
      field: 'mobile_number',
      headerName: ' Phone',
      width: 150,
      type: 'string',
      // flex: 1.5,
      renderCell: (params) => <Typography fontSize={'14px'}>+91-{params.row.mobile_number}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      // flex: 0.8,
      renderCell: (params) => <StatusColumn params={params.row} />,
    },
    {
      field: 'activate',
      headerName: 'Active/InActive',
      width: 150,
      renderCell: (params) => (
        <SwitchBtn handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)} statusData={params.row} />
      ),
    },
    {
      field: `createdAt`,
      headerName: 'Created On',
      width: 200,
      type: 'string',
      // flex: 2,

      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('DD/MM/YYYY hh:MM A');
      },
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 200,
      // flex: 1,
      renderCell: (params) => (
        <ActionColumn
          showView={false}
          showEdit
          showDelete
          //  handleView={() => handleView(params.row)}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];

  return (
    <>
      <Page title="User" sx={{ width: '100%' }}>
        {openActiveDeactive ? (
          <CommonActiveDeactiveBox
            description="Are you sure you want to "
            isOpen={openActiveDeactive}
            submitLoader={submitLoader}
            handleClose={handleActiveDeactiveClick}
            handleSubmit={handleActiveDeactiveSubmit}
            handleCancel={handleActiveDeactiveClick}
            activeDeactivateObject={activeDeactivateObject}
          />
        ) : // <ActiveDeactiveModel
        //   isOpen={openActiveDeactive}
        //   handleClose={handleActiveDeactiveClick}
        //   handleSubmit={handleActiveDeactiveSubmit}
        //   handleCancel={handleActiveDeactiveClick}
        //   activeDeactivateObject={activeDeactivateObject}
        // />
        null}
        {open ? (
          <CommonDeleteBox
            openDialog={open}
            handleDeleteYes={handleSubmit}
            handleClose={handleDeleteCancle}
            title="Reviewer"
            description="Are you sure you want to delete reviewer ?"
          />
        ) : null}

        <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
          <PagesTopBar
            Add
            title="Reviewers"
            subTitle="List of all Reviewers in your app."
            btnText="Create Reviewer"
            link="/dashboard/create_reviewer"
            handleSearch={handleSearch}
            clearInput={() => {
              handleSearch('');
            }}
          />

          <Card sx={{ marginTop: '25px' }}>
            <Grid container>
              <Grid item lg={12} xs={12} sm={12}>
                <DataGrid
                  disableColumnMenu
                  style={{
                    padding: '10px',
                    height: 520,
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  sx={{}}
                  paginationModel={paginationModel}
                  onPaginationModelChange={getPeginationModelValue}
                  pageSizeOptions={[10, 20, 30]}
                  paginationMode="server"
                  loading={isloading}
                  rows={reviewers?.data ? reviewers?.data : rows}
                  columns={columns}
                  getRowId={(row) => row.id}
                  rowCount={reviewers?.data_count}
                  localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                  slots={
                    {
                      // toolbar: CustomToolbar,
                    }
                  }
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          bgcolor: '#ffffff',
                          // borderRadius: '8px',
                          // border: '1px solid rgba(0,0,0,0.1)',
                          pb: '0px',
                          '& fieldset': { border: 'none' },
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Container>
        {/* ----------------------Message box-------------------- */}
        <ShowMessage
          onClose={() => setIsShowUpdate(false)}
          title={errorMessage}
          type={errorUpdate ? 'error' : 'success'}
          open={isShowUpdate}
        />
        <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
      </Page>
    </>
  );
}
