import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Container, TextField, Grid, Stack, Typography, Box } from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';
import axiosInstance from '../api/withJWTServer';
import Iconify from './Iconify';
import ShowMessage from '../Component/ShowMessage';
// import { mt } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiInputBase-root': {
      textAlign: 'center',
      backgroundColor: 'white',
      height: '40px',
      fontSize: '0.875rem', // Adjust the font size as needed
    },
  },
  datePickerLabelRoot: {
    marginTop: '-5px',
    fontSize: '12px',
  },
}));

const DateRangePickerMui = () => {
  const classes = useStyles();
  const [startDateTime, setStartDateTime] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [exportClicked, setExportClicked] = useState(false);
  const dispatch = useDispatch();

  const [isError, setIsError] = useState(false);

  const initialValues = {
    startDate: null,
    endDate: null,
  };

  // const validationSchema = Yup.object().shape({
  //   startDate: Yup.date()
  //     .required('Start Date is required')
  //     .test('isValidDate', 'Invalid Date', (value) => {
  //       if (!value) return true; // Allow empty values
  //       return moment(value, 'YYYY-MM-DD', true).isValid();
  //     }),
  //   endDate: Yup.date()
  //     .required('End Date is required')
  //     .test('isValidDate', 'Invalid Date', (value) => {
  //       if (!value) return true; // Allow empty values
  //       return moment(value, 'YYYY-MM-DD', true).isValid();
  //     })
  //     .min(Yup.ref('startDate'), 'End Date cannot be earlier than Start Date'),
  // });
  // const validationSchema = Yup.object().shape({
  //   startDate: Yup.date()
  //     .required('Start Date is required')
  //     .test('isValidDate', 'Invalid Date', (value) => {
  //       if (!value) return true; // Allow empty values
  //       const currentDate = moment().startOf('day');
  //       const selectedDate = moment(value, 'YYYY-MM-DD', true);
  //       return selectedDate.isValid() && selectedDate.isSameOrBefore(currentDate);
  //     }),
  //   endDate: Yup.date()
  //     .required('End Date is required')
  //     .test('isValidDate', 'Invalid Date', (value) => {
  //       if (!value) return true; // Allow empty values
  //       return moment(value, 'YYYY-MM-DD', true).isValid();
  //     })
  //     .min(Yup.ref('startDate'), 'End Date cannot be earlier than Start Date'),
  // });

  const handleSubmit = (values, { resetForm }) => {
    // console.log('values Ed:-', endDate);

    if (values.startDate && values.endDate) {
      const startDate = moment(values.startDate.toDate()).format('YYYY-MM-DD');
      const endDate = moment(values.endDate.toDate()).format('YYYY-MM-DD');

      setIsError(false);
      console.log('values Sd:-', startDate);
      console.log('values Ed:-', endDate);

      axiosInstance
        .get(`admin/get-report?start_date=${startDate}&end_date=${endDate}`, {
          responseType: 'blob',
        })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `VendorList.xlsx`);
          document.body.appendChild(link);
          link.click();
          resetForm({ values: { startDate: null, endDate: null } });
          setStartDateTime(null);
        })
        .catch((err) => {
          // if (!navigator.onLine) {
          //   dispatch(HandleExceptionWithSecureCatch(undefined));
          // }
          // else{
          //   dispatch(HandleExceptionWithSecureCatch(err));
          // }
          // HandleExceptionWithSecureCatch(err);
          resetForm({ values: { startDate: null, endDate: null } });
        });
    } else {
      setIsError(true);
    }
  };

  return (
    <Box sx={{ mt: '0px' }}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue, isValid, dirty }) => (
          <Form>
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                defaultValue={dayjs('2022-07-17')}
                onError={(newError) => setError(newError)}
                slotProps={{
                  textField: {
                    helperText: errorMessage,
                  },
                }}
                minDate={startOfQ12022}
                maxDate={endOfQ12022}
              />
            </LocalizationProvider> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack direction={'row'} spacing={1} sx={{ ml: '10px' }}>
                <Box sx={{ width: '170px' }}>
                  <DatePicker
                    name="startDate"
                    value={values.startDate}
                    disableFuture
                    sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                    onChange={(date) => {
                      // console.log('OnChange', date.toDate?.().toString());
                      setFieldValue('startDate', date);
                      setStartDateTime(date);
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ backgroundColor: 'white' }}
                        className={classes.datePicker}
                        InputLabelProps={{
                          classes: {
                            root: classes.datePickerLabelRoot,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ width: '170px' }}>
                  <DatePicker
                    name="endDate"
                    value={values.endDate}
                    onChange={(date) => setFieldValue('endDate', date)}
                    slotProps={{ textField: { size: 'small' } }}
                    sx={{ backgroundColor: 'white', borderRadius: '10px' }}
                    disableFuture
                    minDate={values.startDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.datePicker}
                        style={{ textAlign: 'center' }}
                        InputLabelProps={{
                          classes: {
                            root: classes.datePickerLabelRoot,
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Grid item xs={3} lg={2} sm={3}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    style={{
                      borderRadius: '10PX',
                      height: '40px',
                      backgroundColor: '#E2EDE7',
                      boxShadow: 'none',
                      border: '1px solid green',
                    }}
                  >
                    <Typography style={{ color: 'green', fontSize: '14px' }}>Export</Typography>
                    <Iconify
                      icon="ic:baseline-cloud-download"
                      sx={{ color: 'green', ml: '5px', height: '20px', width: '20px' }}
                    />
                  </LoadingButton>
                </Grid>
              </Stack>
            </LocalizationProvider>
          </Form>
        )}
      </Formik>
      <ShowMessage
        onClose={() => setIsError(false)}
        title={"Please Select 'From' & 'To' Date to Export Data"}
        type="error"
        open={isError}
      />
    </Box>
  );
};

export default DateRangePickerMui;
