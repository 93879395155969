import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { green, orange, purple, red, teal } from '@mui/material/colors';
import moment from 'moment';
import { useFormik, Form, FormikProvider } from 'formik';

import Line1 from '../../../Assets/Line1.jpg';
import PagesTopBar from '../../../Component/PagesTopBar';
import Linkedin from '../../../Assets/Linkedin.png';
import Rectangle from '../../../Assets/Rectangle.png';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import ProfilePhotoModal from '../../../Component/ModelBox/ProfilePhotoModal';

import {
  getMatrimonySingleCandidate,
  getMatrimonyCandidates,
  deleteMatrimonyCandidate,
} from '../../../Redux-Store/actions/Matrimony/MatrimonyCandidatesAction';
import { fDateTime } from '../../../utils/formatTime';

import ShowMessage from '../../../Component/ShowMessage';

const MatrimonyCandidatesDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const candidatesUuid = useLocation();
  const uuid = candidatesUuid?.state?.candidatesId;
  console.log('uuiufioueocjd', uuid);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [candidateDelete, setCandidateDelete] = useState();
  console.log('imageList', imageList);
  const [serialNumber, setSerialNumber] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };
  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };
  const formik = useFormik({});
  const { errors, touched, getFieldProps } = formik;
  const { userId } = useParams();

  console.log('USER ID', userId);

  const { singleMatrimonyCandidateData, isloading } = useSelector((state) => ({
    singleMatrimonyCandidateData: state.matrimonyCandidtes?.singleMatrimonyCandidateData?.data,
    isloading: state.matrimonyCandidtes?.isLoading,
  }));
  console.log('singleMatrimonyCandidateData', singleMatrimonyCandidateData?.payment_record);
  console.log('isloading', isloading);
  useEffect(() => {
    dispatch(getMatrimonySingleCandidate(uuid));
  }, [uuid]);

  useEffect(() => {
    if (singleMatrimonyCandidateData?.data[0] && singleMatrimonyCandidateData?.data[0].image_paths) {
      const images = singleMatrimonyCandidateData?.data[0].image_paths.map((imagePath, index) => imagePath.image_path);
      setImageList(images);
    }
  }, [singleMatrimonyCandidateData?.data[0]]);

  const column = [
    // {
    //   field: 'id',
    //   headerName: 'Sr no',
    //   width: 70,
    //   renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    // },

    {
      field: 'payment_status',
      headerName: 'Payment Status',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              bgcolor: params.row.payment_status === 1 ? green[700] : orange[800],
              fontSize: '11px',
              fontWeight: '600',
              color: 'white',
              padding: '5px 12px',
              borderRadius: '6px',
            }}
          >
            {params.row.payment_status === 1 ? 'Payment Done' : 'Payment Pending'}
          </Typography>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      width: 180,
      renderCell: (params) => {
        return params.row.transaction_id ? (
          <Typography
            sx={{
              bgcolor: green[50],
              border: '1px solid green',
              fontSize: '10px',
              padding: '5px 8px',
              borderRadius: '6px',
              fontWeight: '600',
              color: green[800],
            }}
          >
            {params.row.transaction_id}
          </Typography>
        ) : (
          <Typography>----</Typography>
        );
      },
    },
    {
      field: 'plan_title',
      headerName: 'Plan Name',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography fontSize={'14px'} fontWeight={'600'}>
            {params?.row?.subscription_plan_title ? params?.row?.subscription_plan_title : '---'}
          </Typography>
        );
      },
    },
    {
      field: 'total_amount',
      headerName: 'Amount',
      width: 100,
      renderCell: (params) => {
        console.log('params1998', params);
        return params?.row?.total_amount ? (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            &#8377; {params?.row?.total_amount}
          </Typography>
        ) : (
          '--'
        );
      },
    },

    {
      field: 'subscription_start_date',
      headerName: 'Start Date',
      width: 130,
      renderCell: (params) => {
        console.log('startdatae', params.row.subscription_start_date);
        return params?.row?.subscription_start_date ? (
          <Typography color={green[900]} fontSize={'14px'} fontWeight={'600'}>
            {params?.row?.subscription_start_date}
          </Typography>
        ) : (
          '---'
        );
      },
    },
    {
      field: 'subscription_end_date',
      headerName: 'End Date',
      width: 130,
      renderCell: (params) => {
        return params?.row?.subscription_end_date ? (
          <Typography color={red[800]} fontSize={'14px'} fontWeight={'600'}>
            {params?.row?.subscription_end_date}
          </Typography>
        ) : (
          '---'
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Date & Time',
      width: 250,
      renderCell: (params) => {
        return (
          <Typography fontSize={'14px'}>
            {/* {moment(params.row._updatedAt).format('DD-MM-YYYY hh:mm a')} */}
            {moment(singleMatrimonyCandidateData?.data[0]?.payment_record?.updatedAt).format('DD-MM-YYYY hh:mm a')}
          </Typography>
        );
      },
    },
  ];
  const row = [];
  // 1st row
  const columns = [
    { field: 'mobile_number', headerName: 'Mobile No' },
    { field: 'email', headerName: 'Email' },
    { field: 'date_of_birth', headerName: 'DOB' },
    { field: 'gender', headerName: 'Gender' },
  ];
  const rows = [
    {
      id: 1,
      mobile_number: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].mobile_number : '',
      email: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].email_address : '',
      date_of_birth: singleMatrimonyCandidateData?.data[0]?.date_of_birth
        ? new Date(singleMatrimonyCandidateData?.data[0]?.date_of_birth).toISOString().split('T')[0]
        : '',
      gender: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].gender : '',
    },
  ];
  // 2nd row
  const columns2 = [
    { field: 'Marital_Status', headerName: 'Marital Status' },
    { field: 'Weight', headerName: 'Weight' },
    { field: 'Height', headerName: 'Height' },
    { field: 'Family_Type', headerName: 'Family Type' },
  ];
  const rows2 = [
    {
      id: 2,
      Marital_Status: singleMatrimonyCandidateData?.data[0]
        ? singleMatrimonyCandidateData?.data[0].marital_status_name
        : '',
      Weight: singleMatrimonyCandidateData?.data[0] ? `${singleMatrimonyCandidateData?.data[0].weight} kg` : '',
      Height: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].height : '',
      Family_Type: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].family_type_name : '',
    },
  ];

  // 3rd row
  const columns3 = [
    { field: 'Caste', headerName: 'Caste' },
    { field: 'SubCaste', headerName: 'SubCaste' },
    { field: 'FamilyValues', headerName: 'Family Values' },
    { field: 'ZodiacSign', headerName: 'Zodiac Sign' },
  ];
  const rows3 = [
    {
      id: 3,
      Caste: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].caste_name : '',
      SubCaste: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].sub_cast_name : '',
      FamilyValues: singleMatrimonyCandidateData?.data[0]
        ? singleMatrimonyCandidateData?.data[0].family_value_name
        : '',
      ZodiacSign: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].zodiac_sign_name : '',
    },
  ];

  // 4th row
  const columns4 = [
    { field: 'Education', headerName: 'Education' },
    { field: 'AnnualIncome', headerName: 'Annual Income' },
    { field: 'Occupation', headerName: 'Occupation' },
    { field: 'Veg', headerName: 'Veg / Non Veg' },
  ];
  const rows4 = [
    {
      id: 4,
      Education: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].education_name : '',
      AnnualIncome: singleMatrimonyCandidateData?.data[0]
        ? singleMatrimonyCandidateData?.data[0].annual_income_name
        : '',
      Occupation: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].occupation_name : '',
      Veg: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].diet_type_name : '',
    },
  ];

  // 5th row
  const columns5 = [
    { field: 'SmokingHabits', headerName: 'Smoking Habits' },
    { field: 'DrinkingHabits', headerName: 'Drinking Habits' },
    { field: 'State', headerName: 'State' },
    { field: 'City', headerName: 'City' },
  ];
  const rows5 = [
    {
      id: 5,
      SmokingHabits: singleMatrimonyCandidateData?.data[0]
        ? singleMatrimonyCandidateData?.data[0].smoking_habits_name
        : '',
      DrinkingHabits: singleMatrimonyCandidateData?.data[0]
        ? singleMatrimonyCandidateData?.data[0].drinking_habits_name
        : '',
      State: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].state : '',
      City: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].city : '',
    },
  ];
  // 6th row
  const columns6 = [
    { field: 'Address', headerName: 'Address' },
    { field: 'CreatedOn', headerName: 'Created On' },
  ];
  const rows6 = [
    {
      id: 6,
      Address: singleMatrimonyCandidateData?.data[0] ? singleMatrimonyCandidateData?.data[0].address : '',
      CreatedOn: singleMatrimonyCandidateData?.data[0]?.createdAt
        ? fDateTime(singleMatrimonyCandidateData?.data[0]?.createdAt)
        : 'NA',
    },
  ];

  const styles = {
    card: {
      width: 656,
      height: 158,
      margin: '10px',
      marginTop: '20px',
      padding: '20px',
    },
    languageContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: 10,
    },
    language: {
      padding: '5px 10px',
      borderRadius: '15px',
      backgroundColor: '#e8e4e4',
    },
  };
  const styles1 = {
    card: {
      width: 656,
      height: 196,
      margin: '10px',
      marginTop: '20px',
      padding: '20px',
    },
  };

  const cardStyles = {
    card: {
      width: '100%',
      height: 250,
      marginTop: '20px',
      padding: '20px',
    },
  };

  const handleDelete = (e) => {
    setCandidateDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };
  const [dataLaoding, setDataLoading] = useState(isloading);

  const handleSubmit = () => {
    dispatch(deleteMatrimonyCandidate({ uuid }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage(response.data.message);
          // navigate(-1);
          // dispatch(getMatrimonyCandidates(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const viewAllPhotos = () => {
    setPhotoModalOpen(!photoModalOpen);
  };

  const handleSendRequest = () => {
    navigate(`/dashboard/matrimony/candidates/view-details/${userId}/candidateRequests`, {
      state: {
        candidatesDataId: uuid,
        candidateName: singleMatrimonyCandidateData?.data[0]
          ? `${singleMatrimonyCandidateData?.data[0].first_name || ''} ${
              singleMatrimonyCandidateData?.data[0].last_name || ''
            }`
          : '',

        value: 1,
      },
    });
  };
  const handleRecivedRequest = () => {
    navigate(`/dashboard/matrimony/candidates/view-details/${userId}/candidateRequests`, {
      state: {
        candidatesDataId: uuid,
        candidateName: singleMatrimonyCandidateData?.data[0]
          ? `${singleMatrimonyCandidateData?.data[0].first_name || ''} ${
              singleMatrimonyCandidateData?.data[0].last_name || ''
            }`
          : '',
        value: 2,
      },
    });
  };

  return (
    <div>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Matrimiony Candidate"
          description="Are you sure you want to delete Candidate ?"
        />
      ) : null}
      <ProfilePhotoModal isOpen={photoModalOpen} imageList={imageList} handleClose={() => viewAllPhotos()} />

      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          title=" Candidate Details"
          subTitle="Showing details of candidate"
          btnText="Delete Profile"
          Delete
          handleDelete={handleDelete}
          hideSearch
        />
        {/* <Divider sx={{ mt: '15px' }} /> */}
        {isloading ? (
          <Box sx={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Card sx={{ padding: '20px' }}>
              <FormikProvider value={formik}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                  }}
                >
                  Basic Details
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  It is showing your candidate details
                </Typography>
                <img
                  src={Line1}
                  alt="line"
                  style={{
                    width: '660.01px',
                    height: '0px',
                    top: '297px',
                    left: '415.99px',
                    gap: '0px',
                    border: '1px solid #7979791F', // border format: width style color
                    // opacity: '0px',
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '200px' }}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '30px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        letterSpacing: '0.6500000953674316px',
                        textAlign: 'left',
                        marginTop: '20px',
                      }}
                    >
                      {/* Riya Sharma */}
                      {singleMatrimonyCandidateData?.data[0]
                        ? `${singleMatrimonyCandidateData?.data[0].first_name} ${singleMatrimonyCandidateData?.data[0].last_name}`
                        : ''}
                    </Typography>
                  </div>
                  {/* <div>
                <img
                  src={Rectangle}
                  alt="Rectangle"
                  style={{
                    width: '95px',
                    height: '99px',
                    gap: '0px',
                    borderRadius: '50%',
                  }}
                />
              </div> */}
                </div>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    marginTop: '10px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                  }}
                >
                  About Me
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22.5px',
                    textAlign: 'left',
                  }}
                >
                  {singleMatrimonyCandidateData?.data[0]
                    ? singleMatrimonyCandidateData?.data[0].profile_description
                    : ''}
                </Typography>
                {/* Row 1 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Row 2 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows2.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns2.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Row 3 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows3.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns3.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Row 4 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows4.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns4.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Row 5 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows5.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns5.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>

                {/* Row 6 */}
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows6.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns6.map((column) => (
                          <Grid item xs key={column.field} lg={3} sm={3}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </FormikProvider>
            </Card>
            {/* <Divider sx={{ mt: '10px' }} /> */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card sx={cardStyles.card}>
                  <h3>View Photos</h3>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <Box
                    sx={{ height: '170px', justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}
                  >
                    <p style={{ marginTop: '20px', marginBottom: '10px' }}>
                      You can view candidate photos tap on below button button it will show all photos of candidate.{' '}
                    </p>

                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        // textAlign: 'center',
                        backgroundColor: '#02742D',
                        borderRadius: '10px',
                        border: '1.4px solid #0B8238',
                        width: 'max-content',
                        p: '10px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                      onClick={() => viewAllPhotos()}
                    >
                      View All Photos
                    </Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card
                  sx={cardStyles.card}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  // onClick={handleSendRequest}
                >
                  <h3>Request Sent</h3>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '30px',
                      fontWeight: 700,
                      lineHeight: '24px',
                      letterSpacing: '0.6500000953674316px',
                      marginTop: '20px',
                      color: '#E44D4D',
                    }}
                  >
                    {singleMatrimonyCandidateData?.data[0]
                      ? singleMatrimonyCandidateData?.data[0].total_sent_request_count
                      : ''}
                  </Typography>
                  <p style={{ marginTop: '20px', marginBottom: '10px' }}>You have sent above no of requests.</p>
                  {singleMatrimonyCandidateData?.data[0]?.total_sent_request_count === 0 ? (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        // textAlign: 'center',
                        borderRadius: '10px',
                        border: '1.4px solid #CCCCCC',
                        width: 'max-content',
                        p: '10px',
                        justifyContent: 'center',
                        display: 'flex',
                        backgroundColor: '#DDDDDD' /* Light gray background for disabled */,
                        borderColor: ' #CCCCCC' /* Lighter border color for disabled */,
                        color: '#AAAAAA' /* Light gray text color for disabled */,
                        // cursor: not - allowed,
                      }}
                      // onClick={handleSendRequest}
                    >
                      View Requests
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        // textAlign: 'center',
                        backgroundColor: '#02742D',
                        borderRadius: '10px',
                        border: '1.4px solid #0B8238',
                        width: 'max-content',
                        p: '10px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                        justifyContent: 'center',
                        display: 'flex',
                      }}
                      onClick={handleSendRequest}
                    >
                      View Requests
                    </Typography>
                  )}
                </Card>
              </Grid>

              <Grid item xs={3}>
                <Card
                  sx={cardStyles.card}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                  // onClick={handleRecivedRequest}
                >
                  <h3>Request Recieved</h3>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '30px',
                      fontWeight: 700,
                      lineHeight: '24px',
                      letterSpacing: '0.6500000953674316px',
                      marginTop: '20px',
                      color: '#0B8238',
                    }}
                  >
                    {singleMatrimonyCandidateData?.data[0]
                      ? singleMatrimonyCandidateData?.data[0].total_received_request_count
                      : ''}
                  </Typography>
                  <p style={{ marginTop: '20px', marginBottom: '10px' }}>You have received above no of requests</p>
                  {singleMatrimonyCandidateData?.data[0]?.total_received_request_count === 0 ? (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        // textAlign: 'center',
                        borderRadius: '10px',
                        border: '1.4px solid #CCCCCC',
                        width: 'max-content',
                        p: '10px',
                        justifyContent: 'center',
                        display: 'flex',
                        backgroundColor: '#DDDDDD' /* Light gray background for disabled */,
                        borderColor: ' #CCCCCC' /* Lighter border color for disabled */,
                        color: '#AAAAAA' /* Light gray text color for disabled */,
                        // cursor: not - allowed,
                      }}
                      // onClick={handleSendRequest}
                    >
                      View Requests
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        backgroundColor: '#02742D',
                        borderRadius: '10px',
                        border: '1.4px solid #0B8238',
                        width: 'max-content',
                        p: '10px',
                        color: '#FFFFFF',
                        cursor: 'pointer',
                      }}
                      onClick={handleRecivedRequest}
                    >
                      View Requests
                    </Typography>
                  )}
                </Card>
              </Grid>
            </Grid>
            {/* PAYMENT  */}

            {/* payment and plan */}
            <Grid item xs={12} sx={{ mt: '20px' }}>
              <Card sx={{ padding: '20px' }}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                  }}
                >
                  Payment and Plan Details
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  It is showing current plan payment details
                </Typography>
                <img
                  src={Line1}
                  alt="line"
                  style={{
                    width: '660.01px',
                    height: '0px',
                    top: '297px',
                    left: '415.99px',
                    gap: '0px',
                    border: '1px solid #7979791F', // border format: width style color
                    // opacity: '0px',
                  }}
                />

                {/* ---- */}
                <Grid container spacing={2} sx={{ mt: '10px' }}>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Plan name
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>
                      {singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details
                        ?.plan_title
                        ? singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details
                            ?.plan_title
                        : '---'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Plan description
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>
                      {singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details
                        ?.plan_description
                        ? singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details
                            ?.plan_description
                        : '---'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Start Date
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>
                      <Typography sx={{ fontWeight: 700 }}>
                        {singleMatrimonyCandidateData?.data[0]?.subscription_details?.start_date
                          ? singleMatrimonyCandidateData?.data[0]?.subscription_details?.start_date
                          : '---'}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      End date
                    </Typography>

                    <Typography sx={{ fontWeight: 700 }}>
                      {singleMatrimonyCandidateData?.data[0]?.subscription_details?.end_date
                        ? singleMatrimonyCandidateData?.data[0]?.subscription_details?.end_date
                        : '---'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Purchase at
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>
                      {singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details?.createdAt
                        ? fDateTime(
                            singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_plan_details
                              ?.createdAt
                          )
                        : '---'}
                    </Typography>
                  </Grid>
                </Grid>
                {/* Plan details */}
                <Divider sx={{ mt: '15px' }} />
                <Grid container spacing={2} sx={{ mt: '10px' }}>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Transaction ID
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: 600,
                        marginTop: '5px',
                        textAlign: 'center',
                        backgroundColor: '#EEECEC',
                        color: '#035421',
                        borderRadius: '5px',
                        border: '1px solid #060606',
                        width: 'fit-content',
                        padding: '4px 10px',
                      }}
                    >
                      {singleMatrimonyCandidateData?.data[0]?.payment_record?.transaction_id
                        ? singleMatrimonyCandidateData?.data[0]?.payment_record?.transaction_id
                        : '---'}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Recipt Id
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        fontWeight: 600,
                        marginTop: '5px',
                        textAlign: 'center',
                        backgroundColor: '#EEECEC',
                        color: '#035421',
                        borderRadius: '5px',
                        border: '1px solid #060606',
                        width: 'fit-content',
                        padding: '4px 10px',
                      }}
                    >
                      {singleMatrimonyCandidateData?.data[0]?.payment_record?.receipt_id
                        ? singleMatrimonyCandidateData?.data[0]?.payment_record?.receipt_id
                        : '---'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Date & Time
                    </Typography>
                    {singleMatrimonyCandidateData?.data[0]?.payment_record?.updatedAt ? (
                      <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                        {fDateTime(singleMatrimonyCandidateData?.data[0]?.payment_record?.updatedAt)}
                      </Typography>
                    ) : (
                      '---'
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Amount
                    </Typography>
                    {singleMatrimonyCandidateData?.data[0]?.payment_record?.total_amount ? (
                      <Typography variant="body1" component="div" sx={{ fontWeight: 700, color: '#035421' }}>
                        &#8377; {singleMatrimonyCandidateData?.data[0]?.payment_record?.total_amount}
                      </Typography>
                    ) : (
                      '---'
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" component="div">
                      Payment Status
                    </Typography>
                    {(() => {
                      const subscriptionStatus =
                        singleMatrimonyCandidateData?.data[0]?.subscription_details?.subscription_status;

                      if (subscriptionStatus === 'paid') {
                        return (
                          <Typography
                            variant="body1"
                            component="div"
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontWeight: 600,
                              textAlign: 'center',
                              backgroundColor: green[700],
                              borderRadius: '5px',
                              width: 'fit-content',
                              padding: '5px 15px',
                              color: '#FFFFFF',
                              paddingTop: '5px',
                              textTransform: 'uppercase',
                            }}
                          >
                            Paid
                          </Typography>
                        );
                      }
                      if (subscriptionStatus === 'expired') {
                        return (
                          <Typography
                            variant="body1"
                            component="div"
                            sx={{
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              fontWeight: 600,
                              textAlign: 'center',
                              backgroundColor: red[600],
                              borderRadius: '5px',
                              width: 'fit-content',
                              padding: '5px 15px',
                              color: '#FFFFFF',
                              paddingTop: '5px',
                              textTransform: 'uppercase',
                            }}
                          >
                            Expired
                          </Typography>
                        );
                      }

                      return (
                        // <Typography
                        //   variant="body1"
                        //   component="div"
                        //   sx={{
                        //     fontFamily: 'Poppins',
                        //     fontSize: '14px',
                        //     fontWeight: 600,
                        //     textAlign: 'center',
                        //     color: '#000000', // Adjust this styling for the default case if needed
                        //   }}
                        // >
                        ' ---'
                        // </Typography>
                      );
                    })()}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            {/* Table payment list */}
            <Grid item lg={12} xs={12} sm={12} sx={{ marginTop: '20px' }}>
              <Card sx={{ padding: '25px' }}>
                <DataGrid
                  disableColumnMenu
                  style={{
                    padding: '10px',
                    height: '300px',
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  onPaginationModelChange={getPeginationModelValue}
                  paginationModel={paginationModel}
                  pageSizeOptions={[10, 20, 30]}
                  loading={isloading}
                  rows={
                    singleMatrimonyCandidateData?.payment_record ? singleMatrimonyCandidateData?.payment_record : row
                  }
                  columns={column}
                  getRowId={(row) => row.id}
                  localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          bgcolor: '#ffffff',
                          // borderRadius: '8px',
                          // border: '1px solid rgba(0,0,0,0.1)',
                          pb: '0px',
                          '& fieldset': { border: 'none' },
                        },
                      },
                    },
                  }}
                />
              </Card>
            </Grid>
          </>
        )}
      </Container>

      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </div>
  );
};

export default MatrimonyCandidatesDetails;
