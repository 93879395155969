import { createSlice } from '@reduxjs/toolkit';
import {
  addVendorJob,
  updateVendorJob,
  getVendorJobs,
  getVendorJob,
  deleteVendorJob,
} from '../../actions/recruitment/vendorJobActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  vendorJob: undefined,
  error: '',
  isLoading: false,
  vendorJobDetails: {},
};

// slice or reducer
const VendorJob = createSlice({
  name: 'VendorJob',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseVendorJob(builder, addVendorJob);
    caseVendorJob(builder, updateVendorJob);
    caseVendorJob(builder, getVendorJobs);
    caseVendorJob(builder, getVendorJob);
    caseVendorJob(builder, deleteVendorJob);
  },
});
export default VendorJob.reducer;

const caseVendorJob = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_VENDOR_JOB)) {
      state.vendorJob = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_VENDOR_JOB)) {
      state.isLoadingt = false;
      state.vendorJob = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_VENDOR_JOB)) {
      state.vendorJobDetails = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_VENDOR_JOBS)) {
      state.isLoading = false;
      state.vendorJob = action.payload;
    } else if (action.type.includes(actionTypes.DOWNLOAD_VENDOR_EXCEL)) {
      state.vendorJob = action.payload;
      state.isLoading = false;
    }
  });
};
