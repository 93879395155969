import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import {
  Typography,
  Container,
  Card,
  Grid,
  Button,
  Chip,
  CardContent,
  Paper,
  Box,
  Divider,
  Stack,
} from '@mui/material';
import { green, orange, purple, red, teal } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import moment from 'moment';
// import { fDateTime } from 'src/utils/formatTime';
import { fDateTime } from '../utils/formatTime';
import Line1 from '../Assets/Line1.jpg';
import Iconify from '../components/Iconify';
import axiosInstance from '../api/withJWTServer';
import documentSideImage from '../Assets/documentSideImage.png';
import logoNew from '../Assets/userDetailsLogo.png';
import footerLogo from '../Assets/sadhuImage.png';
import noBusinessLogo from '../Assets/noBusinessLogoNew.png';
import '../Component/index.css';
import VendorDocModel from '../Component/ModelBox/VendorDocModel';
import BusinesImages from '../Component/BusinesImages';
import { detailsVendor } from '../Redux-Store/actions/vendorActions';

const Userdetail = () => {
  const dispatch = useDispatch();
  const { uuid, updatedBy } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vendorDetail, setVendorDetail] = useState({});

  const [paymentRecord, setPaymentRecord] = useState();
  const [loader, setLoader] = useState(false);
  console.log('vendorDetail876745', vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.remainingDays);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [docsImage, setDocsImage] = useState([]);
  const [open, setOpen] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [showImage, setShowImage] = useState('');
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [expiredMessage, setExpiredMessage] = useState(false);
  const newEndDate = vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.end_date;
  const today = new Date();
  const todayDate = today.toISOString().split('T')[0];
  const newToday = today.toDateString();
  console.log('abccc', todayDate);

  console.log('today', todayDate);
  console.log('endDate', newEndDate);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    console.log('todayDateUpdate', today.toDateString());
    const endDate = new Date(newEndDate);
    endDate.setHours(0, 0, 0, 0);

    console.log('endDate', endDate.toDateString());
    if (endDate <= today) {
      setExpiredMessage(true);
      console.log('Expired Message Set:', true);
    } else {
      setExpiredMessage(false);
      console.log('Expired Message Set:', false);
    }
  }, [newEndDate]);

  const { profileData, selectedPlans, upgradePlans } = useSelector((state) => ({
    profileData: state?.profileData,
    selectedPlans: state?.selectedPlans,
    updradePlans: state?.upgradePlan,
  }));

  console.log('selectedPlansSSDD', selectedPlans);
  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    // setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };
  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  const companyLogoSingle = vendorDetail?.vendorBusinessDocs?.filter((val) => val.doc_category_name === 'company_logo');
  const ownerProfile = vendorDetail?.vendorBusinessDocs?.filter((val) => val.doc_category_name === 'vendor_profile');

  const column = [
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              bgcolor: params.row.payment_status === 1 ? green[700] : orange[800],
              fontSize: '11px',
              fontWeight: '600',
              color: 'white',
              padding: '5px 12px',
              borderRadius: '6px',
            }}
          >
            {params.row.payment_status === 1 ? 'Payment Done' : 'Payment Pending'}
          </Typography>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      width: 180,
      renderCell: (params) => {
        return params?.row?.transaction_id ? (
          <Typography
            sx={{
              bgcolor: green[50],
              border: '1px solid green',
              fontSize: '10px',
              padding: '5px 8px',
              borderRadius: '6px',
              fontWeight: '600',
              color: green[800],
            }}
          >
            {params?.row?.transaction_id}
          </Typography>
        ) : (
          <Typography>----</Typography>
        );
      },
    },
    // {
    //   field: 'order_id',
    //   headerName: 'Payment Via',
    //   width: 180,
    //   renderCell: (params) => {
    //     console.log('params89');
    //     return (
    //       params.row.consumerSubscriptionDetails[0].isactive === 1 &&
    //       (params.row.order_id ? (
    //         <Typography
    //           sx={{
    //             bgcolor: purple[50],
    //             border: '1px solid purple',
    //             fontSize: '10px',
    //             padding: '5px 8px',
    //             borderRadius: '6px',
    //             fontWeight: '600',
    //             color: purple[800],
    //           }}
    //         >
    //           Payment Gateway
    //         </Typography>
    //       ) : (
    //         <Typography
    //           sx={{
    //             bgcolor: teal[50],
    //             border: '1px solid teal',
    //             fontSize: '10px',
    //             padding: '5px 8px',
    //             borderRadius: '6px',
    //             fontWeight: '600',
    //             color: teal[800],
    //           }}
    //         >
    //           Payment Link
    //         </Typography>
    //       ))
    //     );
    //   },
    // },

    {
      field: 'plan_title',
      headerName: 'Plan Name',
      width: 150,
      renderCell: (params) => {
        console.log('params1998', params.row);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'}>
            {vendorDetail.vendorSubscriptionDetailsModel.length > 0
              ? vendorDetail?.vendorSubscriptionDetailsModel[0].subscription_plan.plan_title
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'plan_price',
      headerName: 'Amount',
      width: 100,
      renderCell: (params) => {
        return vendorDetail.paymentRecordsModels[0].total_amount ? (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            &#8377; {vendorDetail.paymentRecordsModels[0].total_amount}
          </Typography>
        ) : (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            ----
          </Typography>
        );
      },
    },
    // {
    //   field: 'subscription_type',
    //   headerName: 'Subscription Type',
    //   width: 250,

    //   renderCell: (params) => {
    //     return params?.row?.Vendor?.vendor_subscription_details[0]?.subscription_plan?.subscription_type;
    //   },
    // },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 130,
      renderCell: (params) => {
        return (
          <Typography color={green[900]} fontSize={'14px'} fontWeight={'600'}>
            {vendorDetail.vendorSubscriptionDetailsModel.length > 0
              ? moment(vendorDetail.vendorSubscriptionDetailsModel[0].start_date).format('DD MMMM YYYY')
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 130,
      renderCell: (params) => {
        return (
          <Typography color={red[800]} fontSize={'14px'} fontWeight={'600'}>
            {vendorDetail.vendorSubscriptionDetailsModel.length
              ? moment(vendorDetail.vendorSubscriptionDetailsModel[0].end_date).format('DD MMMM YYYY')
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Date & Time',
      width: 200,
      renderCell: (params) => {
        return vendorDetail?.paymentRecordsModels[0].updatedAt ? (
          <Typography>
            {moment(vendorDetail?.paymentRecordsModels[0].updatedAt).format('DD-MM-YYYY hh:mm A')}
          </Typography>
        ) : (
          '---'
        );
      },
    },
  ];

  const row = [];
  useEffect(() => {
    // axiosInstance
    //   .get('/admin/vendor-details', {
    //     params: {
    //       uuid,
    //     },
    //   })
    setLoader(true);
    dispatch(detailsVendor({ uuid }))
      .unwrap()
      .then((res) => {
        console.log('res897877', res);

        const data = res.data.data[0];

        if (res.status === 200) {
          setLoader(false);

          if (data) {
            setCompanyLogo(data.vendorBusinessDocs?.filter((val) => val.doc_category_name === 'vendor_profile'));
            setDocsImage(data.vendorBusinessDocs?.filter((val) => val.doc_category_name !== 'vendor_profile'));
            setVendorDetail(data);
            // setPaymentRecord(res.data.setPaymentRecord);
            setImagesData(data.vendorBusinessImages?.map((val) => val.image_path));
          }
        }
      })
      .catch((err) => {
        setVendorDetail({});
      });
  }, [uuid]);

  // useEffect(() => {
  //   const startDateNew = vendorDetail.vendorSubscriptionDetailsModel?.[0]?.start_date
  //   const endDateNew = vendorDetail.vendorSubscriptionDetailsModel?.[0]?.end_date

  //   console.log("startDateNew","endDateNew",startDateNew,endDateNew)
  //   const currentDate = new Date();
  //   if(currentDate> endDateNew)
  //     setExpired(true)
  //   },[])

  const cardRef = useRef(null);
  const vendorTag =
    vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_tags !== ''
      ? vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_tags.split(',')
      : [];
  const isOpen = [
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.sunday_open, day: 'Sun', TodayDay: 'Sunday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.monday_open, day: 'Mon', TodayDay: 'Monday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.tuesday_open, day: 'Tue', TodayDay: 'Tuesday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.wednesday_open, day: 'Wed', TodayDay: 'Wednesday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.thursday_open, day: 'Thu', TodayDay: 'Thursday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.friday_open, day: 'Fri', TodayDay: 'Friday' },
    { isOpen: vendorDetail?.buinessTimingsModels?.[0]?.saturday_open, day: 'Sat', TodayDay: 'Saturday' },
  ];

  const useStyles = makeStyles((theme) => ({
    sideBar: {
      '@media print': {
        display: 'none',
      },
    },
    align: {
      '@media print': {
        marginTop: '-70px',
      },
    },
    margin: {
      '@media print': {
        marginBottom: '50px !important',
      },
    },
    breakWords: {
      width: '200px',
      overflowWrap: 'break-word',
    },
    '@media print': {
      body: {
        '-webkitPrintColorAdjust': 'exact' /* Chrome, Safari */,
        colorAdjust: 'exact' /* Firefox */,
      },
    },
    printBg: {
      '@media print': {
        backgroundColor: '#07703C !important' /* Use !important to override any existing styles */,
        color: '#fff',
      },
    },
    printDocs: {
      '@media print': {
        height: '150px',
      },
    },
    printDocsImage: {
      '@media print': {
        height: '100px',
        width: '100px',
      },
    },

    root: {
      backgroundColor: '#e4e4e4',
      width: '100%',
    },
    heading: {
      fontSize: '16px',
      fontWeight: '900',
      color: '#060606',
    },
    detail: {
      fontSize: '16px',
      fontWeight: '400',
      color: '#060606',
    },
    parentCard: {
      marginBottom: '20px',
      // width: '100% !important',
      // border: '2px solid red',
    },
    secondheading: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#060606',
    },
    secondheadingone: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#060606',
      height: '50px',
    },

    button: {
      width: '146px',
      height: '36px',
      borderRadius: '17px',
      border: '1.5px solid #0B8238',
      backgroundColor: '#0B8238',
      marginTop: '15px',
      cursor: 'pointer',
    },

    textcol: {
      fontSize: '12px',
      fontWeight: '600',
      color: 'white',
    },

    textcolTwo: {
      fontSize: '13px',
      fontWeight: '400',
      color: '#060606',
      marginTop: '5px',
    },

    textcolTwotwo: {
      fontSize: '13px',
      fontWeight: '400',
      color: '#060606',
      marginTop: '5px',
      height: '55px',
    },
    selectedplan: {
      fontSize: '15px',
      fontWeight: '400',
      color: '#000000',
    },
  }));
  const classes = useStyles();

  const statusColors = {
    Active: '#035421',
    'Under Process': '#E17A15',
    Renewal: '#B507D1',
    'With-Hold': '#D0250E',
    // Default color if status doesn't match any of the above
    default: '#0B1782',
  };
  const statusBackground = {
    Active: '#d1e7d9',
    'Under Process': '#f4ddc6',
    Renewal: '#f4daf8',
    'With-Hold': '#f6d3cf',
    default: '#dbdaec',
  };

  const borderColor = `1.5px solid ${statusColors[vendorDetail?.status] || statusColors.default}`;
  const textColor = `${statusColors[vendorDetail?.status] || statusColors.default}`;
  const cardBackground = `${statusBackground[vendorDetail?.status] || '#dbdaec'}`;

  const openDay = isOpen.filter((val) => val.TodayDay === moment().format('dddd') && val.isOpen === 1);

  const handlePrint = () => {
    const scale = 1.0;

    // Create a new style element and set the scale for print media
    const style = document.createElement('style');
    // style.textContent = `
    //  @media print {        body {           transform: scale(${scale}); transform-origin: top left; } } `;
    style.textContent = `
      @media print {
        body {
          transform: scale(${scale});
          transform-origin: center center; /* Center the content horizontally and vertically */
        }
      }
    `;
    // Append the style element to the document head
    document.head.appendChild(style); // Trigger the print dialog
    window.print(); // Remove the dynamically added style element after printing
    style.remove();
  };

  function getButtonConfig(updatedBy) {
    switch (updatedBy) {
      case 'Admin':
        return {
          style: {
            padding: '4px 15px',
            borderRadius: '8px',
            backgroundColor: '#4d56a4',
            border: '1px solid #00000040',
            color: '#fff',
            fontSize: '12px',
            fontWeight: 700,
          },
          text: updatedBy.toUpperCase(),
        };

      case 'Reviewer':
        return {
          style: {
            padding: '4px 15px',
            borderRadius: '8px',
            backgroundColor: '#bf8328',
            border: '1px solid #00000040',
            color: '#fff',
            fontSize: '12px',
            fontWeight: 700,
          },
          text: updatedBy.toUpperCase(),
        };

      default:
        return {
          style: {
            padding: '4px 15px',
            borderRadius: '8px',
            backgroundColor: '#d9d9d9',
            border: '1.5px solid #00000040',
          },
          text: 'NOT YET APPROVED',
        };
    }
  }

  const { style, text } = getButtonConfig(updatedBy);
  const handleNewUserClick = () => {
    setOpen(!open);
  };
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#8b8b8b',
    paddingLeft: '10px',
    fontSize: '15px',
  };

  const checkPdf = (parmas) => {
    return vendorDetail?.vendorBusinessDocs
      ?.filter((val) => val.doc_category_name === parmas)?.[0]
      ?.doc_original_name?.includes('.pdf');
  };
  console.log('valllllll', vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.subscriptionPlansModels?.[0]);

  return (
    <>
      {showImage === 'business_image' ? (
        <BusinesImages isOpen={open} docs={imagesData} handleClose={handleNewUserClick} />
      ) : (
        <VendorDocModel
          isOpen={open}
          docs={docsImage.filter((val) => val.doc_category_name === showImage)}
          handleClose={handleNewUserClick}
        />
      )}

      <div className={classes.root}>
        <Grid container style={{ paddingTop: '30px', paddingBottom: '30px' }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography fontWeight={'700'} fontSize={'18px'} ml={'30px'}>
              Vendor Details
            </Typography>
            <Typography fontWeight={'400'} fontSize={'15px'} ml={'30px'}>
              Showing details of vendor
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper>
              <LoadingButton
                size="large"
                variant="contained"
                className={`${classes.sideBar}`}
                style={{ float: 'right' }}
                onClick={handlePrint}
                sx={{ marginRight: '30px' }}
              >
                Download Pdf
              </LoadingButton>
            </Paper>
          </Grid>
        </Grid>
        {loader ? (
          <Box sx={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {/* 1ST NEW */}
            <Container maxWidth="lg" sx={{ width: '100%' }}>
              <Card className={classes.parentCard}>
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: '20px', borderBottom: '1px solid #7979791F !important' }}>
                    <Typography ml={'30px'} fontWeight={'700'} fontSize={'18px'}>
                      Business Details
                    </Typography>
                    <Typography ml={'30px'} fontWeight={'400'} fontSize={'14px'}>
                      It is showing your business details
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <div style={{ borderTop: '1px solid #7979791F', marginLeft: '30px', marginTop: '10px' }}>
                      <button
                        style={{
                          padding: '5px 12px',
                          borderRadius: '8px',
                          marginTop: '10px',
                          border: `${borderColor}`,
                          color: `${textColor}`,
                          background: `${cardBackground}`,
                          textTransform: 'uppercase',
                        }}
                      >
                        <Typography style={{ fontSize: '14px', fontWeight: '600' }}>
                          {vendorDetail?.status === 'Active' ? 'Approved' : vendorDetail?.status}
                        </Typography>
                      </button>

                      <Typography
                        style={{ fontSize: 30, fontWeight: 600, letterSpacing: 0.6500000953674316, textAlign: 'left' }}
                      >
                        {vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_name}
                      </Typography>

                      <Box height={'10px'} />
                      <Typography fontWeight={'700'} fontSize={'14px'}>
                        Business Description
                      </Typography>
                      <Typography fontWeight={'400'} fontSize={'14px'}>
                        {vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_details}
                      </Typography>

                      <Box height={'10px'} />

                      <Grid container className={classes.parentCard}>
                        <Grid item xs={4}>
                          <Typography fontWeight={'700'} fontSize={'14px'}>
                            Mobile No.
                          </Typography>
                          <Typography className={classes.detail}>
                            +91 {vendorDetail?.vendorBusinessDetailsModels?.[0]?.mobile_number}
                            {vendorDetail?.vendorBusinessDetailsModels?.[0]?.alternate_mobile_number ? (
                              <span>{`/ ${vendorDetail.vendorBusinessDetailsModels[0].alternate_mobile_number}`}</span>
                            ) : null}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography fontWeight={'700'} fontSize={'14px'}>
                            Category
                          </Typography>
                          <Typography>
                            <button
                              style={{
                                padding: '5px 15px',
                                borderRadius: '8px',
                                border: '1.5px solid green',
                                backgroundColor: '#0B8238',
                              }}
                            >
                              <Typography style={{ color: 'white', fontSize: '12px', fontWeight: '600' }}>
                                {vendorDetail?.vendorBusinessDetailsModels?.[0]?.category_label.toUpperCase()}
                              </Typography>
                            </button>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid className={classes.parentCard}>
                        <Typography fontWeight={'700'} fontSize={'14px'}>
                          Address
                        </Typography>
                        <Typography className={classes.detail}>
                          {`${vendorDetail?.vendorBusinessDetailsModels?.[0]?.address_line_1},
                       ${vendorDetail?.vendorBusinessDetailsModels?.[0]?.address_line_2},
                       ${vendorDetail?.vendorBusinessDetailsModels?.[0]?.city},
                       ${vendorDetail?.vendorBusinessDetailsModels?.[0]?.state},
                       ${vendorDetail?.vendorBusinessDetailsModels?.[0]?.pin}`}
                        </Typography>
                      </Grid>

                      <Grid container className={classes.parentCard}>
                        {vendorDetail?.vendorBusinessDetailsModels?.[0]?.address_line_2 ? (
                          <Grid item xs={4}>
                            <Typography fontWeight={'700'} fontSize={'14px'}>
                              Landmark
                            </Typography>
                            <Typography className={classes.detail}>
                              {vendorDetail?.vendorBusinessDetailsModels?.[0]?.address_line_2}
                            </Typography>
                          </Grid>
                        ) : null}

                        <Grid item xs={6}>
                          <Typography fontWeight={'700'} fontSize={'14px'}>
                            State
                          </Typography>
                          <Typography className={classes.detail}>
                            {vendorDetail?.vendorBusinessDetailsModels?.[0]?.state}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={'700'} fontSize={'14px'}>
                            Country
                          </Typography>
                          <Typography className={classes.detail}>
                            {vendorDetail?.vendorBusinessDetailsModels?.[0]?.country}
                          </Typography>
                        </Grid>
                      </Grid>
                      {vendorDetail?.vendorOutletAddressModels?.length > 0
                        ? vendorDetail?.vendorOutletAddressModels?.map((val, index) => (
                            <div key={index}>
                              <Grid container className={classes.parentCard}>
                                <Typography fontWeight={'700'} fontSize={'14px'}>
                                  Outlet Address for Location {index + 1}
                                </Typography>
                              </Grid>
                              <Grid container className={classes.parentCard} style={{ marginTop: '-20px' }}>
                                <Typography className={classes.detail}>
                                  {val?.address_line_1},{val?.address_line_2},{val?.city}, {val?.state}, {val?.pin}
                                </Typography>
                              </Grid>

                              <Grid container className={classes.parentCard}>
                                {val?.address_line_2 ? (
                                  <Grid item xs={4}>
                                    <Typography fontWeight={'700'} fontSize={'14px'}>
                                      Landmark
                                    </Typography>
                                    <Typography className={classes.detail}>{val?.address_line_2}</Typography>
                                  </Grid>
                                ) : null}

                                <Grid item xs={6}>
                                  <Typography fontWeight={'700'} fontSize={'14px'}>
                                    State
                                  </Typography>
                                  <Typography className={classes.detail}>{val?.state}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={'700'} fontSize={'14px'}>
                                    Country
                                  </Typography>
                                  <Typography className={classes.detail}>{val.country}</Typography>
                                </Grid>
                              </Grid>
                            </div>
                          ))
                        : null}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={4} lg={4}>
                    <img
                      src={companyLogoSingle?.[0]?.doc_path ? companyLogoSingle?.[0]?.doc_path : noBusinessLogo}
                      style={{ margin: '0 auto', height: '200px', width: '200px', borderRadius: '10px' }}
                      alt="logo"
                    />
                  </Grid>

                  <Grid
                    container
                    style={{
                      marginLeft: '30px',
                      paddingBottom: '20px',
                      paddingTop: '20px',
                      borderTop: '1px solid #7979791F',
                    }}
                  >
                    {/*  */}

                    <Grid item xs={12} sm={12} lg={3}>
                      <Typography fontWeight={'700'} fontSize={'14px'}>
                        Approved By
                      </Typography>

                      <Box height={'5px '} />

                      <button style={style}>
                        <Typography fontWeight={'600'} fontSize={'12px'}>
                          {text}
                        </Typography>
                      </button>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <Typography fontWeight={'700'} fontSize={'14px'}>
                        Is Suspended
                      </Typography>
                      <Box height={'5px '} />
                      {vendorDetail.isactive === 0 ? (
                        <>
                          <button
                            style={{
                              backgroundColor: '#35752e',
                              borderRadius: '8px',
                              padding: '3px 15px',
                              border: 'none',
                            }}
                          >
                            <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#FFF' }}> Yes</Typography>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            style={{
                              backgroundColor: '#D0250E',
                              borderRadius: '8px',
                              padding: '3px 15px',
                              border: 'none',
                            }}
                          >
                            <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#FFF' }}> No</Typography>
                          </button>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} lg={3}>
                      <Typography fontWeight={'700'} fontSize={'14px'}>
                        Created On
                      </Typography>
                      <Typography className={classes.detail}>
                        {moment(vendorDetail.createdat).format('DD MMM YYYY h:mm A')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Container>

            <Container maxWidth="lg" className={classes.parentCard}>
              <Grid container spacing={3}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Card>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12} lg={12} md={12} sm={12} style={{ borderBottom: '1px solid #7979791F' }}>
                          <Typography fontWeight={'700'} fontSize={'18px'}>
                            Owner Details
                          </Typography>
                          <Typography fontWeight={'400'} fontSize={'14px'}>
                            It is showing your business owner details
                          </Typography>
                          <Box height={'10px'} />
                        </Grid>

                        <Grid container style={{ marginTop: '15px' }}>
                          <Grid item lg={3} md={3} sm={12} xs={12}>
                            <img
                              src={ownerProfile?.[0]?.doc_path ? ownerProfile?.[0]?.doc_path : noBusinessLogo}
                              alt="logo"
                              style={{ width: '70px', height: '70px', borderRadius: '50%' }}
                            />
                          </Grid>

                          <Grid item lg={9} md={9} sm={12} xs={12}>
                            <Typography fontWeight={'500'} fontSize={'14px'}>
                              Owner Name
                            </Typography>
                            <Typography style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
                              {vendorDetail.firstname}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: '15px' }}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography fontWeight={'700'} fontSize={'14px'}>
                              Mobile No.
                            </Typography>
                            <Typography className={classes.detail}>+91 {vendorDetail.mobile_number}</Typography>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography fontWeight={'700'} fontSize={'14px'}>
                              Email
                            </Typography>
                            <Typography className={classes.detail}>{vendorDetail.email}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Card>
                    <CardContent sx={{ height: '250px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              borderBottom: '1px solid #7979791F',
                              color: '#0B8238',
                              fontSize: '30px',
                              fontWeight: '700',
                            }}
                          >
                            {vendorDetail?.vendorBusinessImages?.length}
                          </Typography>

                          <Box height={'10px'} />
                          <Typography style={{ fontSize: '18px', fontWeight: '600' }}> Business Images</Typography>
                          <Typography>It is showing the info of your business images.</Typography>
                          <Box height={'10px'} />
                          {vendorDetail?.vendorBusinessImages?.length === 0 ? null : (
                            <button className={classes.button} style={{ marginTop: '10px' }}>
                              <Typography
                                className={classes.textcol}
                                onClick={() => {
                                  setOpen(!open);
                                  setShowImage('business_image');
                                }}
                              >
                                View Images
                              </Typography>
                            </button>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item lg={3} md={3} sm={12} xs={12}>
                  <Card>
                    <CardContent sx={{ height: '250px' }}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              borderBottom: '1px solid #7979791F',
                              fontSize: '30px',
                              fontWeight: '700',
                              color: '#0B8238',
                            }}
                          >
                            {vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_expertise
                              ? `${vendorDetail?.vendorBusinessDetailsModels?.[0]?.business_expertise} years`
                              : '0 year'}
                          </Typography>
                          <Box height={'10px'} />
                          <Typography style={{ fontSize: '16px', marginTop: '4px', fontWeight: '600' }}>
                            Business Experience
                          </Typography>
                          <Typography>It is showing the info of your business experience..</Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>

            {/* 3RD New */}

            <Container maxWidth="lg" className={classes.parentCard}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Card style={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={12} sm={12}>
                        <Typography fontWeight={'700'} fontSize={'18px'}>
                          Current Plan Details
                        </Typography>
                        <Typography fontWeight={'500'} fontSize={'14px'}>
                          It is showing your plan details
                        </Typography>

                        {vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.remainingDays && (
                          <button
                            style={{
                              padding: '5px 15px',
                              borderRadius: '5px',
                              border: '1.5px solid #0B8238',
                              backgroundColor: '#cce5d5',
                              marginTop: '15px',
                            }}
                          >
                            <Typography style={{ color: '#035421', fontSize: '20px', fontWeight: '700' }}>
                              {vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.remainingDays < 0
                                ? 0
                                : vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.remainingDays}{' '}
                              Days
                            </Typography>
                          </button>
                        )}
                      </Grid>

                      <Grid item xs={12} lg={12} sm={12}>
                        {vendorDetail?.vendorBusinessDetailsModels?.[0]?.selected_plan_name ? (
                          <>
                            <Typography style={{ fontSize: '22px', fontWeight: '700', color: '#035421' }}>
                              {vendorDetail?.vendorBusinessDetailsModels?.[0]?.selected_plan_name}
                            </Typography>
                            <Typography className={classes.selectedplan}>
                              You have selected the{' '}
                              {
                                vendorDetail?.vendorSubscriptionDetailsModel?.[0]?.subscriptionPlansModels?.[0]
                                  ?.plan_title
                              }
                            </Typography>
                            <Divider />
                            {expiredMessage ? (
                              <p>
                                {' '}
                                <Stack direction={'row'} my={'10px'}>
                                  <Box
                                    sx={{
                                      bgcolor: red[600],
                                      borderRadius: '8px',
                                      padding: '8px 15px ',
                                      color: 'white',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <EventBusyIcon fontSize="12px" />
                                    <Typography fontSize={'12px'} fontWeight={'600'} ml={'5px'}>
                                      Your current plan is expired now. You have to renew your subscription plan.
                                    </Typography>
                                  </Box>
                                </Stack>
                              </p>
                            ) : (
                              'Active'
                            )}

                            <Grid container style={{ marginTop: '12px' }}>
                              <Grid item xs={6}>
                                <Typography className={classes.selectedplan}>Start Date</Typography>
                                <Typography className={classes.secondheading}>
                                  {/* {vendorDetail?.vendorSubscriptionDetailsModels?.[0]?.start_date} */}
                                  {vendorDetail?.vendor_subscription_details.length > 0
                                    ? moment(vendorDetail?.vendor_subscription_details[0]?.start_date).format(
                                        'DD MMMM YYYY'
                                      )
                                    : '---'}
                                </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography className={classes.selectedplan}>End Date</Typography>
                                <Typography className={classes.secondheading}>
                                  {/* {vendorDetail?.vendorSubscriptionDetailsModels?.[0]?.end_date} */}
                                  {vendorDetail?.vendor_subscription_details.length > 0
                                    ? moment(vendorDetail?.vendor_subscription_details[0]?.end_date).format(
                                        'DD MMMM YYYY'
                                      )
                                    : '---'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Box style={containerStyle} sx={{ height: '150px' }}>
                              <Iconify icon={'ic:baseline-security'} width={'30px'} height={'30px'} />
                              <h6 style={{ color: '#585858', fontSize: '14px', fontWeight: '500' }}>
                                No Plan Selected
                              </h6>
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Container>

            {/* 4ht */}

            <Container maxWidth="lg" className={classes.parentCard}>
              <Grid container spacing={3}>
                <Grid item lg={6} sm={12} sx={12}>
                  <Card style={{ padding: '5px' }}>
                    <CardContent>
                      <Typography style={{ fontSize: '18px', fontWeight: '600' }}>Business Keyword</Typography>
                      <Typography style={{ fontSize: '15px', fontWeight: '500', borderBottom: '1px solid #7979791F' }}>
                        It is showing the list of business keywords.
                        <Box height={'10px'} />
                      </Typography>
                      <div style={{ marginTop: '10px' }}>
                        {vendorTag?.length > 0 ? (
                          vendorTag?.map((val, index) => (
                            <>
                              <Chip
                                label={val}
                                style={{
                                  background: '#e0e0e0',
                                  color: '#3A3A3A',
                                  fontSize: '15px',
                                  marginRight: '15px',
                                  margin: '5px',
                                }}
                              />
                            </>
                          ))
                        ) : (
                          <Box style={containerStyle}>
                            <Iconify icon={'ic:baseline-keyboard'} width={'30px'} height={'30px'} />
                            <h6 style={{ color: '#585858', fontSize: '14px', fontWeight: '500' }}>
                              No Keywords Added
                            </h6>{' '}
                          </Box>
                        )}
                      </div>
                      <br />
                      <br />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} sm={12} sx={12}>
                  <Card>
                    <CardContent>
                      <h2
                        style={{ paddingLeft: '0px', color: `${openDay?.[0]?.isOpen === 1 ? '#07703C' : '#c90202'}` }}
                      >
                        {moment().format('dddd')}{' '}
                        <span>
                          {openDay?.[0]?.isOpen === 1 ? (
                            <Chip
                              label={`OPEN`}
                              style={{ marginLeft: '10px', background: '#07703c', color: '#fff', marginTop: '-5px' }}
                            />
                          ) : (
                            <Chip
                              label={`CLOSED`}
                              style={{ marginLeft: '10px', background: '#c90202', color: '#fff', marginTop: '-5px' }}
                            />
                          )}
                        </span>
                      </h2>
                      <Box height={'5px'} />
                      <Divider />
                      <Box height={'5px'} />
                      <Typography style={{ fontSize: '18px', fontWeight: '600' }}>Business Hours</Typography>
                      <Typography style={{ fontSize: '14px', fontWeight: '500' }}>
                        It is showing the business hour info
                      </Typography>

                      <Typography>
                        {isOpen.map((val) => (
                          <>
                            {val.isOpen === 0 ? (
                              <>
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  style={{
                                    height: '27px',
                                    width: '40px',
                                    fontSize: '10px',
                                    fontWeight: '600',
                                    borderRadius: '46.53px',
                                    marginTop: '7px',
                                    marginLeft: '2px',
                                    background: '#c90202',
                                  }}
                                  className="margin"
                                >
                                  {val.day.toUpperCase()}
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="contained"
                                  size="small"
                                  sx={{
                                    height: '27px',
                                    width: '40px',
                                    fontSize: '10px',
                                    fontWeight: '600',
                                    borderRadius: '46.53px',
                                    marginTop: '7px',
                                    marginLeft: '2px',
                                    '&:hover': {
                                      bgcolor: 'green',
                                    },
                                  }}
                                  className="margin"
                                >
                                  {val.day.toUpperCase()}
                                </Button>
                              </>
                            )}
                          </>
                        ))}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item lg={2} sm={12} sx={12}>
                  <Card>
                    <Paper elevation={3} sx={{ padding: '17px' }}>
                      <div style={{ borderBottom: '1px solid #7979791F' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: '600' }}>
                          {vendorDetail?.buinessTimingsModels?.[0]?.start_timing}
                        </Typography>

                        <Typography style={{ fontSize: '14px', fontWeight: '600', marginBottom: '20px' }}>
                          Opening Time
                        </Typography>
                      </div>

                      <div style={{ marginTop: '20px' }}>
                        <Typography style={{ fontSize: '20px', fontWeight: '600' }}>
                          {vendorDetail?.buinessTimingsModels?.[0]?.end_timing}
                        </Typography>
                        <Typography style={{ fontSize: '14px', fontWeight: '600', marginBottom: '20px' }}>
                          Closing Time
                        </Typography>
                      </div>
                    </Paper>
                  </Card>
                </Grid>
              </Grid>
            </Container>
            {/* 5th */}
            <Container maxWidth="lg">
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid item xs={12} style={{ borderBottom: '1px solid #7979791F' }}>
                        <Typography variant="h6">Business Documents</Typography>
                        <Typography>It is showing the list of business documents.</Typography>
                        <Box height={'10px'} />
                      </Grid>
                      {vendorDetail?.vendorBusinessDocs?.length > 0 ? (
                        <Grid container sx={{ marginTop: '10px' }}>
                          {vendorDetail?.vendorBusinessDocs?.map((val) => {
                            if (
                              val.doc_category_name !== 'vendor_profile' &&
                              val.doc_category_name !== 'company_logo'
                            ) {
                              return (
                                <Grid
                                  item
                                  xs={3}
                                  style={{ borderRight: '1px solid #7979791F', padding: '20px' }}
                                  key={val.doc_category_name}
                                >
                                  <Typography fontSize={'16px'} fontWeight={'600'}>
                                    {val.doc_category_name.replaceAll('_', ' ').toUpperCase()}
                                  </Typography>
                                  <Typography fontSize={'14px'} fontWeight={'400'}>
                                    Tap to view the Document
                                  </Typography>
                                  <Button
                                    variant="contained"
                                    sx={{ marginTop: '10px', '&:hover': { bgcolor: 'green' } }}
                                  >
                                    <Typography
                                      onClick={() => {
                                        setOpen(!open);
                                        setShowImage(val.doc_category_name);
                                      }}
                                      sx={{ fontSize: '15px', fontWeight: '600' }}
                                    >
                                      View Document
                                    </Typography>
                                  </Button>
                                </Grid>
                              );
                            }
                            return null; // This ensures nothing is rendered if the condition is not met
                          })}
                        </Grid>
                      ) : (
                        <Box style={containerStyle}>
                          <Box height={'50px'} />
                          <Iconify icon={'ic:outline-document-scanner'} width={'30px'} height={'30px'} />
                          <h6 style={{ color: '#585858', fontSize: '14px', fontWeight: '500' }}>
                            No Documents Added
                          </h6>{' '}
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>

            {/* payment model */}

            {/* <Grid container>
              <Grid item xs={12}>
                <CardContent>
                  <img src={logoNew} width="250px" alt="SunnGrahk" style={{ marginTop: '50px' }} />
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: '400',
                      marginLeft: '10px',
                      color: '#898989',
                      marginTop: '10px',
                    }}
                  >
                    “Do not figure out big plans at first, but, begin slowly, feel your ground and proceed up and up.” –
                    Swami Vivekananda
                  </Typography>

                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <img src={footerLogo} alt="logo" style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
                    <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                      <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#898989' }}>
                        In association with
                      </Typography>
                      <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#898989' }}>
                        International Sindhi Samaj Sangathan
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Grid>
            </Grid> */}
            {/* Table payment list */}

            <Container maxWidth="lg">
              <Grid item lg={12} xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <Card sx={{ padding: '20px' }}>
                  <DataGrid
                    disableColumnMenu
                    style={{
                      padding: '10px',
                      height: '300px',
                      width: '100%',
                      borderRadius: '10px',
                      fontSize: '14px',
                    }}
                    onPaginationModelChange={getPeginationModelValue}
                    paginationModel={paginationModel}
                    pageSizeOptions={[10, 20, 30]}
                    // loading={isloading}
                    rows={vendorDetail?.paymentRecordsModels ? vendorDetail?.paymentRecordsModels : row}
                    columns={column}
                    getRowId={(row) => row.id}
                    // rowCount={}
                    localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: {
                          variant: 'outlined',
                          size: 'small',
                          sx: {
                            bgcolor: '#ffffff',
                            // borderRadius: '8px',
                            // border: '1px solid rgba(0,0,0,0.1)',
                            pb: '0px',
                            '& fieldset': { border: 'none' },
                          },
                        },
                      },
                    }}
                  />
                </Card>
              </Grid>
            </Container>
          </>
        )}
      </div>
    </>
  );
};

export default Userdetail;
