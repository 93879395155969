import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addLanguage = createAsyncThunk(actionTypes.ADD_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-recruitment-language', params);
    console.log('response of add languages', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateLanguage = createAsyncThunk(actionTypes.UPDATE_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-recruitment-language', params);
    console.log('response of update languages', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateLanguageStatus = createAsyncThunk(actionTypes.UPDATE_LANGUAGE_STATUS, async (params, thunkAPI) => {
  try {
    console.log('paramsUpdate', params);
    const response = await axiosInstance.post('/admin/update-master-status', params);
    console.log('response of update language', response);
    return response;
  } catch (error) {
    return console.log(error);
  }
});

export const getLanguage = createAsyncThunk(actionTypes.GET_SINGLE_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/admin/get-recruitment-language-by-id?id=${params}`);
    console.log('response of get languages', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getLanguages = createAsyncThunk(actionTypes.GET_LANGUAGES, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin//get-recruitment-languages?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin//get-recruitment-languages?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteLanguage = createAsyncThunk(actionTypes.DELETE_LANGUAGE, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/delete-recruitment-language?uuid=${params?.uuid}`);
    console.log('response of delete languages', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
