// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('ic:baseline-space-dashboard'),
    isReviewer: true,
  },
  {
    title: 'Users',
    path: '/dashboard/user',
    icon: getIcon('ic:round-person-2'),
    isReviewer: false,
  },
  {
    title: 'Vendors',
    path: '/dashboard/vendor',
    icon: getIcon('ic:round-storefront'),
    isReviewer: true,
  },
  {
    title: 'Transactions',
    path: '/dashboard/transactions',
    icon: getIcon('ic:baseline-currency-rupee'),
    isReviewer: false,
  },
  {
    title: 'Reviewers',
    path: '/dashboard/reviewer',
    icon: getIcon('ic:round-groups-2'),
    isReviewer: false,
  },
  {
    title: 'Subscriptions',
    path: '/dashboard/subscription',
    icon: getIcon('ic:round-verified-user'),
    isReviewer: false,
  },
  {
    title: 'Categories',
    path: '/dashboard/category',
    icon: getIcon('ic:baseline-apps'),
    isReviewer: false,
  },
  {
    title: 'Recruitment',
    path: '/dashboard/recruitment',
    icon: getIcon('ic:round-work-history'),
    isReviewer: false,
    children: [
      {
        title: 'Master',
        path: '/dashboard/recruitment/master',
      },
      {
        title: 'Vendor Jobs',
        path: '/dashboard/recruitment/vendorjob',
      },

      {
        title: 'Candidates',
        path: '/dashboard/recruitment/candidates',
      },
    ],
  },
  {
    title: 'Matrimony',
    path: '/dashboard/matrimony',
    icon: getIcon('mingcute:love-fill'),
    isReviewer: false,
    children: [
      {
        title: 'Master',
        path: '/dashboard/matrimony/master',
      },
      {
        title: 'Candidates',
        path: '/dashboard/matrimony/candidates',
      },
    ],
  },
  {
    title: 'Settings',
    path: '/dashboard/settings',
    icon: getIcon('ic:baseline-settings'),
    isReviewer: false,
  },
];

export default navConfig;
