import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Grid, Menu, MenuItem, Tab, Typography } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Page from '../../components/Page';
import JobCatergories from './masterData/jobCategories/JobCatergories';
import Languages from './masterData/languages/Languages';
import Skills from './masterData/skills/Skills';
import Education from './masterData/education/Education';
import JobType from './masterData/jobType/JobType';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

export default function RecruitmentMaster() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('ststeinr', state);

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tabValue', newValue);
  };

  const saveTabValue = (newValue) => {
    localStorage.setItem('tabValue', newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState();
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // ------------Handle add---------------
  const handleAddJobCategory = () => {
    try {
      setAnchorEl(null);
      saveTabValue(1);
      navigate('/dashboard/recruitment/master/create_jobcategories');
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };
  const handleAddLanguage = () => {
    try {
      setAnchorEl(null);
      saveTabValue(2);
      navigate('/dashboard/recruitment/master/create_language');
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };
  const handleAddSkill = () => {
    try {
      setAnchorEl(null);
      saveTabValue(3);
      navigate('/dashboard/recruitment/master/create_skill');
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };
  const handleAddEduction = () => {
    try {
      setAnchorEl(null);
      saveTabValue('4');
      navigate('/dashboard/recruitment/master/create_eduaction', {});
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };
  const handleAddJobType = () => {
    try {
      setAnchorEl(null);
      saveTabValue('5');
      navigate('/dashboard/recruitment/master/create_jobtype');
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };

  const getTabValue = () => {
    const persistValue = localStorage.getItem('tabValue');
    console.log('persistValue', persistValue);
    if (persistValue !== null) {
      setValue(persistValue);
    }
  };
  // useEffect(() => {
  //   getTabValue();
  // }, []);
  useEffect(() => {
    getTabValue();
    if (state && state.tab) {
      setValue(state.tab);
    }
  }, [state]);

  return (
    <RootStyle>
      <Page title="Master(Recruitment)" sx={{ width: '100%' }}>
        <Grid container md={12} sx={{ px: 4 }}>
          <Grid
            container
            sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}
          >
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={25}>
                Master <span style={{ fontSize: '20px' }}> (Recruitment)</span>
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Master data of recruitment.
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="error"
                size="small"
                disableElevation
                endIcon={<ArrowDropDownCircleOutlinedIcon />}
                sx={{
                  padding: '10px 15px',
                  bgcolor: '#0B8238',
                  fontSize: '14px',
                  '&:hover': {
                    background: '#0B8238',
                    opacity: 0.9,
                  },
                }}
                id="basic-menu-button"
                aria-controls={openMenu ? 'basic-menu-popup' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
              >
                Actions
              </Button>
            </Grid>
          </Grid>
          <Menu id="basic-menu-popup" open={openMenu} onClose={handleCloseMenu} anchorEl={anchorEl}>
            <MenuItem onClick={handleAddJobCategory} sx={{ fontSize: '13px' }}>
              Add Job Category
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleAddLanguage} sx={{ fontSize: '13px' }}>
              Add Language
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleAddSkill} sx={{ fontSize: '13px' }}>
              Add Skills
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleAddEduction} sx={{ fontSize: '13px' }}>
              Add Education
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleAddJobType} sx={{ fontSize: '13px' }}>
              Add Job Type
            </MenuItem>
          </Menu>

          <Grid md={12}>
            <TabContext value={value}>
              <Grid item md={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList sx={{ mt: '-18px' }} onChange={handleChange}>
                    <Tab label="Job Categories" value="1" />
                    <Tab label="Languages" value="2" />
                    <Tab label="Skills" value="3" />
                    <Tab label="Education" value="4" />
                    <Tab label="Job Type" value="5" />
                  </TabList>
                </Box>
              </Grid>

              <Grid item md={12}>
                <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                  <JobCatergories />
                </TabPanel>
                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                  <Languages />
                </TabPanel>
                <TabPanel value="3" sx={{ m: 0, p: 0 }}>
                  <Skills />
                </TabPanel>
                <TabPanel value="4" sx={{ m: 0, p: 0 }}>
                  <Education />
                </TabPanel>
                <TabPanel value="5" sx={{ m: 0, p: 0 }}>
                  <JobType />
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </Grid>
      </Page>
    </RootStyle>
  );
}
