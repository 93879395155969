import { createSlice } from '@reduxjs/toolkit';
import { getVendorList, updateVendorList, deleteVendor, detailsVendor } from '../../actions/vendorActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  report: undefined,
  vendors: undefined, // logged in users details
  error: '', // any api error
  isLoading: false, // loading flag
};

// slice or reducer
const VendorInfo = createSlice({
  name: 'vendor_information',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseVendor(builder, getVendorList);
    caseVendor(builder, updateVendorList);
    // caseVendor(builder, deleteVendor);
    caseVendor(builder, detailsVendor);
  },
});
export default VendorInfo.reducer;

const caseVendor = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.UPDATE_VENDOR)) {
      state.isLoadingt = false;
      state.vendors = action.payload;
    } else if (action.type.includes(actionTypes.GET_VENDORS)) {
      state.vendors = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.SINGLE_VENDOR)) {
      console.log('payload', action.payload);
      state.vendors = action.payload;
      state.isLoading = false;
      console.log(' state.vendors', state.vendors);
    }
  });
};
