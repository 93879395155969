import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Grid, Menu, MenuItem, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Page from '../../components/Page';
// import SendRequest from '../matrimony/candidates';
// import RecivedRequest from '..RecivedRequest';
import Rectangle from '../../Assets/Rectangle.png';
import VendorTransactions from './VendorTransactions';
import MatrimonyTransactions from './MatrimonyTransactions';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

export default function TransactionsMaster() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tabValueTrans', newValue);
  };

  const getTabValue = () => {
    const persistValue = localStorage.getItem('tabValueTrans');
    console.log('persistValueinTrasns', persistValue);
    if (persistValue !== null) {
      setValue(persistValue);
    }
  };
  useEffect(() => {
    getTabValue();
  }, []);

  const masterDataMenuItem = [
    {
      id: '1',
      name: 'Vendor Transactions',
    },
    {
      id: '2',
      name: 'Matrimony Transactions',
    },
  ];

  return (
    <RootStyle>
      <Page title="Master(Recruitment)" sx={{ width: '100%' }}>
        <Grid container md={12} sx={{ px: 4 }}>
          <Grid container sx={{ marginBottom: '25px', display: 'flex', alignItem: 'center', gap: '20px' }}>
            <Box>
              <Typography fontWeight={'600'} fontSize={25}>
                Transactions
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                {'List of your all transactions'}
              </Typography>
            </Box>
          </Grid>

          <Grid md={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  sx={{ mt: '-18px' }}
                  //   variant="scrollable"
                  //   scrollButtons
                  //   aria-label="visible arrows tabs example"
                  //   sx={{
                  //     [`& .${tabsClasses.scrollButtons}`]: {
                  //       '&.Mui-disabled': { opacity: 0.3 },
                  //     },
                  //   }}
                >
                  {masterDataMenuItem.map((value, index) => (
                    <Tab label={value.name} value={value.id} />
                  ))}
                </TabList>
              </Box>

              <Grid item md={12}>
                <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                  <VendorTransactions />
                </TabPanel>
                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                  <MatrimonyTransactions />
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </Grid>
      </Page>
    </RootStyle>
  );
}
