import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addSkill = createAsyncThunk(actionTypes.ADD_SKILL, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-recruitment-job-skill', params);
    console.log('response of add skills', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateSkill = createAsyncThunk(actionTypes.UPDATE_SKILL, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-recruitment-job-skill', params);
    console.log('response of update skills', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateSkillStatus = createAsyncThunk(actionTypes.UPDATE_SKILL_STATUS, async (params, thunkAPI) => {
  try {
    console.log('paramsUpdate', params);
    const response = await axiosInstance.post('/admin/update-master-status', params);
    console.log('response of update education', response);
    return response;
  } catch (error) {
    return console.log(error);
  }
});

export const getSkill = createAsyncThunk(actionTypes.GET_SINGLE_SKILL, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/admin/get-recruitment-job-skill-by-id?id=${params}`);
    console.log('response of get skills', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSkills = createAsyncThunk(actionTypes.GET_SKILLS, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-job-skills?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin/get-recruitment-job-skills?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteSkill = createAsyncThunk(actionTypes.DELETE_SKILL, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/delete-recruitment-job-skill?uuid=${params?.uuid}`);
    console.log('response of delete skills', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
