import { createSlice } from '@reduxjs/toolkit';
import { fetchSettingsStatus, updateMatrimonyStatus, updateRecruitmentStatus } from '../../actions/settingActrions';

const initialState = {
  matrimonyOption: false,
  recruitmentOption: false,
};


const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //  .addCase(fetchMatrimonyStatus.fulfilled, (state, action) => {
    //   state.matrimonyOption = action.payload;
    // })
    .addCase(fetchSettingsStatus.fulfilled, (state, action) => {
      state.settingsCardOption = action.payload;
    })
    .addCase(updateMatrimonyStatus.fulfilled, (state, action) => {
      state.matrimonyOption = action.payload;
    })
    .addCase(updateRecruitmentStatus.fulfilled, (state, action) => {
      state.recruitmentOption = action.payload;
    });
  },
});
export default settings.reducer;