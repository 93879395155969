import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowMessage from '../../../../Component/ShowMessage';
import { addSkill, updateSkill } from '../../../../Redux-Store/actions/recruitment/skillActions';
import { getAllJobCategories, getJobCategories } from '../../../../Redux-Store/actions/recruitment/jobCategoryActions';
import BackButton from '../../../../components/BackButton';

const CreateSkill = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const skillFormData = useLocation();
  const skillData = skillFormData?.state?.skillData;
  console.log('skillData', skillData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { jobCategory } = useSelector((state) => ({
    jobCategory: state?.jobCategories?.jobCategory?.data,
    // isloading: state.reviewer?.isLoading,
  }));

  useEffect(() => {
    dispatch(getAllJobCategories({}));
  }, []);

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      // .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 !@#$%^&*()-_=+{};:'",.<>?`~|]+$/, 'Special characters are allowed')
      .matches(/^\s*\S.*$/, ' Only spaces are not allowed ')
      .required('Skills are required')
      .min(2, 'Name should not less than 2 characters')
      .max(30, 'Name should not  more than 30 characters'),

    description: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[\w\s!@#$%^&*()-_=+[{};:'",.<>?`~|]*$/, 'Special characters are allowed')
      .matches(/^\s*\S.*$/, ' Only spaces are not allowed ')
      .min(2, 'Description should not less than 2 characters')
      .max(30, 'Description should not more than 30 characters'),

    recruitment_job_category_uuid: Yup.string().required('Job category is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      recruitment_job_category_uuid: skillData ? skillData.recruitment_job_category_uuid : '',
      name: skillData ? skillData.name : '',
      description: skillData ? skillData.description : '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = skillData
        ? {
            uuid: skillData.uuid,
            recruitment_job_category_uuid: value.recruitment_job_category_uuid,
            name: value.name.toString().trim(),
            description: value.description.toString().trim(),
          }
        : {
            recruitment_job_category_uuid: value.recruitment_job_category_uuid,
            name: value.name.toString().trim(),
            description: value.description.toString().trim(),
          };
      if (skillData?.id) {
        dispatch(updateSkill(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update skill', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addSkill(params))
          .unwrap()
          .then((response) => {
            console.log('response of add skill', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="skill" sx={{ width: '100%' }} maxWidth="xl">
        <BackButton currentPage="createSkill" />
        <Typography variant="h6" fontWeight={'600'}>
          {skillData ? ' Update Skill' : ' Create Skill'}
        </Typography>
        <Typography fontSize={14}>Enter Skill details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={4} sx={{ padding: '40px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    select
                    autoComplete="recruitment_job_category_uuid"
                    label="Recruitment Job Category*"
                    {...getFieldProps('recruitment_job_category_uuid')}
                    error={Boolean(touched.recruitment_job_category_uuid && errors.recruitment_job_category_uuid)}
                    helperText={touched.recruitment_job_category_uuid && errors.recruitment_job_category_uuid}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            width: 250,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem disabled initialValues value="">
                      <em>Recruitment Job Category*</em>
                    </MenuItem>
                    {jobCategory?.data?.map((option) => (
                      <MenuItem key={option.uuid} value={option.uuid} initialValues="">
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    label="Skills*"
                    {...getFieldProps('name')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="description"
                    type="text"
                    label="Description"
                    {...getFieldProps('description')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    //   loading={submitLoader && true}
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      float: 'right',
                      '&:hover': {
                        background: '#0B8238',
                        opacity: 0.9,
                      },
                    }}
                  >
                    {skillData ? 'Update' : ' Save'}
                  </LoadingButton>
                </Grid>
                <Grid item xs={5} />
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateSkill;
