import { createSlice } from '@reduxjs/toolkit';
import { addSkill, updateSkill, getSkills, getSkill, deleteSkill } from '../../actions/recruitment/skillActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  skill: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const Skill = createSlice({
  name: 'Skill',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseSkill(builder, addSkill);
    caseSkill(builder, updateSkill);
    caseSkill(builder, getSkills);
    caseSkill(builder, getSkill);
    caseSkill(builder, deleteSkill);
  },
});
export default Skill.reducer;

const caseSkill = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_SKILL)) {
      state.skill = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_SKILL)) {
      state.isLoadingt = false;
      state.skill = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_SKILL)) {
      state.skill = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_SKILLS)) {
      state.isLoading = false;
      state.skill = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_SKILL)) {
      state.skill = action.payload;
      state.isLoading = false;
    }
  });
};
