// // 1. Define Redux Slice
// import { createSlice } from '@reduxjs/toolkit';

// const statusSlice = createSlice({
//   name: 'status',
//   initialState: {
//     job: {},
//     language: {},
//     skill: {},
//     education: {},
//     loading: false,
//     error: null,
//   },
//   reducers: {
//     setStatusPending(state) {
//       state.loading = true;
//       state.error = null;
//     },
//     setStatusSuccess(state, action) {
//       state.loading = false;
//       state[action.payload.category] = action.payload.data;
//     },
//     setStatusError(state, action) {
//       state.loading = false;
//       state.error = action.payload;
//     },
//   },
// });

// export const { setStatusPending, setStatusSuccess, setStatusError } = statusSlice.actions;

// // 2. Define Async Thunk
// export const updateStatus = (category, status) => async (dispatch) => {
//   try {
//     dispatch(setStatusPending());
//     // Make API request to update status
//     const response = await fetch(`/admin/update-master-status`, {
//       method: 'POST',
//       body: JSON.stringify({ status }),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     const data = await response.json();
//     dispatch(setStatusSuccess({ category, data }));
//   } catch (error) {
//     dispatch(setStatusError(error.message));
//   }
// };

// export default statusSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const statusSlice = createSlice({
  name: 'status',
  initialState: {
    job: {},
    language: {},
    skill: {},
    education: {},
    loading: false,
    error: null,
  },
  reducers: {
    setStatusPending(state) {
      state.loading = true;
      state.error = null;
    },
    setStatusSuccess(state, action) {
      state.loading = false;
      state[action.payload.category] = action.payload.data;
    },
    setStatusError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setStatusPending, setStatusSuccess, setStatusError } = statusSlice.actions;

export default statusSlice.reducer;
