// @mui
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import { Container, Grid, Stack, Typography, Button } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: ' #E53935',
  height: '100%',

  alignItems: 'center',
  alignContent: 'center',
  alignSelf: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

// ----------------------------------------------------------------------

export default function FailPayment() {
  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();

  return (
    <Page title="FailPayment" style={{ height: '100%' }}>
      <RootStyle>
        <Stack sx={{ p: '10px' }}>
          <center>
            <Iconify icon="ic:twotone-cancel" color={'white'} sx={{ height: 50, width: 50 }} />
          </center>

          <Typography fontSize={24} fontWeight={'700'} color={'white'}>
            Payment Failed !
          </Typography>

          <Typography fontSize={16} fontWeight={'400'} color={'rgba(255,255,255,0.8)'}>
            The payment is failed please try after some time.
          </Typography>
        </Stack>
      </RootStyle>
    </Page>
  );
}
