import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addJobType = createAsyncThunk(actionTypes.GET_JOB_TYPES, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-recruitment-master-jobtype', params);
    console.log('response of add reviewer', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateJobType = createAsyncThunk(actionTypes.UPDATE_JOB_TYPE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-recruitment-master-jobtype', params);
    console.log('response of update reviewer', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getJobType = createAsyncThunk(actionTypes.GET_SINGLE_JOB_TYPE, async (params, thunkAPI) => {
  try {
    // const response = await axiosInstance.post('role/get-roles', params);
    // console.log('response of get reviewer', response);
    // return response;
  } catch (e) {
    // return thunkAPI.rejectWithValue(e);
  }
});

export const getJobTypes = createAsyncThunk(actionTypes.GET_JOB_TYPES, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-master-jobtype?key=${params?.filterData}&page=${params?.page + 1}&limit=${
          params?.rowsPerPage
        }`
      : `/admin/get-recruitment-master-jobtype?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteJobType = createAsyncThunk(actionTypes.DELETE_JOB_TYPE, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/delete-recruitment-master-jobtype-by-uuid?uuid=${params?.uuid}`);
    console.log('response of delet reviewer', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const updateJobTypeStatus = createAsyncThunk(actionTypes.UPDATE_JOBTYPE_STATUS, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.post(`admin/update-master-status`, params);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
