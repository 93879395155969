import { createSlice } from '@reduxjs/toolkit';
import {
  //   addCandidate,
  //   updateCandidate,
  //   getCandidates,
  getMatrimonyCandidates,
  deleteMatrimonyCandidate,
  getMatrimonySingleCandidate,
} from '../../actions/Matrimony/MatrimonyCandidatesAction';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  matrimonyCandidate: undefined,
  error: '',
  isLoading: false,
  singleMatrimonyCandidateData: {},
};

// slice or reducer
const MatrimonyCandidate = createSlice({
  name: 'MatrimonyCandidate',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    // caseMatrimonyCandidate(builder, addCandidate);
    // caseMatrimonyCandidate(builder, updateCandidate);
    caseMatrimonyCandidate(builder, getMatrimonyCandidates);
    caseMatrimonyCandidate(builder, getMatrimonySingleCandidate);
    caseMatrimonyCandidate(builder, deleteMatrimonyCandidate);
  },
});
export default MatrimonyCandidate.reducer;

const caseMatrimonyCandidate = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // if (action.type.includes(actionTypes.ADD_CANDIDATE)) {
    //   state.candidate = action.payload;
    //   state.isLoading = false;
    // } else if (action.type.includes(actionTypes.UPDATE_CANDIDATE)) {
    //   state.isLoadingt = false;
    //   state.candidate = action.payload;
    // } else
    if (action.type.includes(actionTypes.GET_SINGLE_MATRIMONY_CANDIDATE)) {
      state.singleMatrimonyCandidateData = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_MATRIMONY_CANDIDATES)) {
      state.isLoading = false;
      state.matrimonyCandidate = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_MATRIMONY_CANDIDATE)) {
      state.matrimonyCandidate = action.payload;
      state.isLoading = false;
    }
  });
};
