import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Card, Grid, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Page from '../../../../components/Page';
import ActionColumn from '../../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../../Component/PagesTopBar';
import SwitchBtn from '../../../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../../../Component/DataGridComponents/StatusColumn';
import ShowMessage from '../../../../Component/ShowMessage';
import CommonDeleteBox from '../../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../../Component/ModelBox/CommonActiveDeactiveBox';
import MasterTopBar from '../../../../Component/MasterTopBar';
import { deleteSkill, getSkills, updateSkillStatus } from '../../../../Redux-Store/actions/recruitment/skillActions';

const Skills = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { skill, isloading } = useSelector((state) => ({
    skill: state.skills?.skill?.data,
    isloading: state.skills?.isLoading,
  }));

  console.log('skill', skill);
  console.log('isloading', isloading);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [skillDelete, setSkillDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [serialNumber, setSerialNumber] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getSkills(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (id, slug, isactive) => {
    console.log('handleActive', id, slug, isactive);

    let params;
    if (slug === 1) {
      params = {
        id: id.toString(),
        type: 'job_skills',
        status: isactive ? 1 : 0,
      };
    } else {
      params = {
        id: id.toString(),
        type: 'job_skills',
        status: isactive ? 1 : 0,
      };
    }
    console.log('params in update', params);
    dispatch(updateSkillStatus(params))
      .unwrap()
      .then((response) => {
        console.log('response in update skill', response);
        if (response.status === 200) {
          // setSubmitLoader(false);
          setOpenActiveDeactive(false);
          setActivateDeactivateObject({});
          setIsShowUpdate(true);
          setErrorMessage(response.data.message);
          dispatch(getSkills(paramsData));
        }
        setTimeout(() => {
          setIsShowUpdate(false);
          // navigate(-1);
        }, 1000);
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      })
      .catch((err) => {
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      });
  };

  const handleDelete = (e) => {
    console.log('e86867sss', e);
    setSkillDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setSkillDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteSkill({ uuid: skillDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete skill', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getSkills(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object090909', object);
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      id: object?.id,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    console.log('activeDeactivateObject', activeDeactivateObject);
    handleActivate(activeDeactivateObject?.id, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard/recruitment/master/create_skill', {
      state: {
        skillData: rowData,
      },
    });
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setFilterData(value);
    setSearchValue(value);
  };

  const columns = [
    {
      field: 'skill_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `name`, headerName: 'Skills', width: 150, type: 'string' },
    { field: 'description', headerName: ' Description', width: 200, type: 'string' },
    {
      field: `recruitmentJobCategoryModel.name`,
      headerName: 'Job Category Name',
      width: 250,
      type: 'string',
      renderCell: (params) =>
        params.row.recruitmentJobCategoryModel ? params.row.recruitmentJobCategoryModel.name || '' : '',
    },
    { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => <StatusColumn params={params.row} /> },
    {
      field: 'activate',
      headerName: 'Active/Inactive',
      width: 150,
      renderCell: (params) => (
        <SwitchBtn handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)} statusData={params.row} />
      ),
    },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <ActionColumn
          showView={false}
          showEdit
          showDelete
          //  handleView={() => handleView(params.row)}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description={'Are you sure you want to'}
          categoryName={'Skill'}
          isOpen={openActiveDeactive}
          handleClose={handleActiveDeactiveClick}
          handleSubmit={handleActiveDeactiveSubmit}
          handleCancel={handleActiveDeactiveClick}
          activeDeactivateObject={activeDeactivateObject}
        />
      ) : // <ActiveDeactiveModel
      //   isOpen={openActiveDeactive}
      //   handleClose={handleActiveDeactiveClick}
      //   handleSubmit={handleActiveDeactiveSubmit}
      //   handleCancel={handleActiveDeactiveClick}
      //   activeDeactivateObject={activeDeactivateObject}
      // />
      null}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Skill"
          description="Are you sure you want to delete skill ?"
        />
      ) : null}

      <Box id="skill" sx={{ width: '100%', mt: '20px' }} maxWidth="xl">
        <MasterTopBar
          title="Skills"
          subTitle="List of all Skills in your app."
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[5, 10, 25]}
                paginationMode="server"
                loading={isloading}
                rows={skill?.data ? skill?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={skill?.data_count}
                localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default Skills;
