import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// @mui
import { Box, Divider, Typography, MenuItem,  Avatar, IconButton } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';

import MenuPopover from '../../components/MenuPopover';
// mocks_
import account from '../../_mock/account';
// import { Logout } from '../../Redux-Store/actions/';
import Server from '../../api';

// ----------------------------------------------------------------------

export default function AccountPopover(props) {
  const [profileDetail, setProfileDetail] = useState({});

  const { profile, isloading } = useSelector((state) => ({
    profile: state.profile?.profile?.data?.data[0],
    isloading: state.profile?.isLoading,
  }));
  console.log('profile', profile);

  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    // dispatch(Logout());
    props.handleLogutFromApp();
    setOpen(null);
  };

  const fetchData = () => {
    try {
      Server.get('admin/get-profile', {
        headers: {
          token: localStorage.getItem('token'),
        },
      }).then((res) => {
        console.log('res of account', res);
        setProfileDetail(res.data.data[0]);
      });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [profile]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <Box sx={{ padding: '8px', bgcolor: '#E5E6E8', borderRadius: '200px' }}>
          <Avatar src={account.photoURL} alt="photoURL" sx={{ width: '25px', height: '25px' }} />
        </Box>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {profileDetail?.firstname} {profileDetail?.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {profileDetail.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Link to={'profile'} style={{ textDecoration: 'none', color: '#000' }}>
          <MenuItem sx={{ m: 1 }} onClick={handleClose}>
            Account Setting
          </MenuItem>
        </Link>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1, color: 'red' }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
