import { createSlice } from '@reduxjs/toolkit';
import { deleteMatrimonySendRequests, getMatrimonySendRequests } from '../../actions/Matrimony/MatrimonySendRequestAction';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  MatrimonySendRequests: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const MatrimonySendRequests = createSlice({
  name: 'MatrimonySendRequests',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseMatrimonySendrequest(builder, getMatrimonySendRequests);
    caseMatrimonySendrequest(builder, deleteMatrimonySendRequests);
  },
});
export default MatrimonySendRequests.reducer;

const caseMatrimonySendrequest = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.GET_MATRIMONY_SEND_REQUESTS)) {
      state.MatrimonySendRequests = action.payload;
      state.isLoading = false;
    }
  });
};
