import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Card, Grid, Container, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import Page from '../../../components/Page';
import ActionColumn from '../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../Component/PagesTopBar';
import SwitchBtn from '../../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../../Component/DataGridComponents/StatusColumn';
import ShowMessage from '../../../Component/ShowMessage';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../Component/ModelBox/CommonActiveDeactiveBox';
import MasterTopBar from '../../../Component/MasterTopBar';
import {
  deleteMatrimonyMasterField,
  getMatrimonyMasterField,
  updateMatrimonyMasterFieldStatus,
} from '../../../Redux-Store/actions/MatrimonyActions';

const MatrimonyMasterOccupation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { matrimonyMaster, isloading } = useSelector((state) => ({
    matrimonyMaster: state.matrimony?.matrimonyMaster?.data,
    isloading: state.matrimony?.isLoading,
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [languageDelete, setLanguageDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [serialNumber, setSerialNumber] = useState(0);

  const getPeginationModelValue = (e) => {
    setPaginationModel(e);
    setSerialNumber(e.page * e.pageSize);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
    masterType: 'OCCUPATION',
  };

  useEffect(() => {
    dispatch(getMatrimonyMasterField(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (id, slug, isactive) => {
    let params;
    if (slug === 1) {
      params = {
        id: id.toString(),
        type: 'occupation',
        status: isactive ? 1 : 0,
      };
    } else {
      params = {
        id: id.toString(),
        type: 'occupation',
        status: isactive ? 1 : 0,
      };
    }
    dispatch(updateMatrimonyMasterFieldStatus(params))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          // setSubmitLoader(false);
          setOpenActiveDeactive(false);
          setActivateDeactivateObject({});
          setIsShowUpdate(true);
          setErrorMessage(response.data.message);
          dispatch(getMatrimonyMasterField(paramsData));
        }
        setTimeout(() => {
          setIsShowUpdate(false);
          // navigate(-1);
        }, 1000);
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      })
      .catch((err) => {
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      });
  };

  const handleDelete = (e) => {
    setLanguageDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setLanguageDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteMatrimonyMasterField({ uuid: languageDelete }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage(response.data.message);
          dispatch(getMatrimonyMasterField(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      id: object?.id,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    handleActivate(activeDeactivateObject?.id, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard/matrimony/master/create-occupation', {
      state: {
        matrimonyMasterData: rowData,
      },
    });
  };

  const handleSearch = (value) => {
    setFilterData(value);
    setSearchValue(value);
  };

  const columns = [
    {
      field: 'language_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `name`, headerName: 'Name', width: 150, type: 'string' },
    {
      field: `description`,
      headerName: 'Description',
      width: 300,
      renderCell: (params) => (params?.row?.description ? params?.row?.description : '---'),
    },
    { field: 'status', headerName: 'Status', width: 150, renderCell: (params) => <StatusColumn params={params.row} /> },
    {
      field: 'activate',
      headerName: 'Activate/Deactivate',
      width: 200,
      renderCell: (params) => (
        <SwitchBtn handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)} statusData={params.row} />
      ),
    },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <ActionColumn
          showView={false}
          showEdit
          showDelete
          //  handleView={() => handleView(params.row)}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description="Are you sure you want to "
          isOpen={openActiveDeactive}
          handleClose={handleActiveDeactiveClick}
          handleSubmit={handleActiveDeactiveSubmit}
          handleCancel={handleActiveDeactiveClick}
          activeDeactivateObject={activeDeactivateObject}
        />
      ) : // <ActiveDeactiveModel
      //   isOpen={openActiveDeactive}
      //   handleClose={handleActiveDeactiveClick}
      //   handleSubmit={handleActiveDeactiveSubmit}
      //   handleCancel={handleActiveDeactiveClick}
      //   activeDeactivateObject={activeDeactivateObject}
      // />
      null}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Occupation"
          description="Are you sure you want to delete Occupation ?"
        />
      ) : null}

      <Box id="language" sx={{ width: '100%', mt: '20px' }} maxWidth="xl">
        {/* <PagesTopBar
          Add
          title="Candidates"
          subTitle="List of all Candidates in your app."
          btnText="Add Candidate"
          link="/dashboard/create_candidate"
          handleSearch={handleSearch}
        /> */}
        <MasterTopBar
          title="Occupation"
          subTitle="List of all  Occupation in your app."
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[5, 10, 25]}
                paginationMode="server"
                loading={isloading}
                rows={matrimonyMaster?.data ? matrimonyMaster?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={matrimonyMaster?.data_count}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default MatrimonyMasterOccupation;
