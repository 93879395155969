import Typography from '@mui/material/Typography';
import { green, red, orange, deepOrange } from '@mui/material/colors';
import React from 'react';

const StatusColumn = (props) => {
  console.log('propsinstatus', props);
  // Determine color variables based on props.params.value
  let bgColor;
  let textColor;
  let borderColor;

  if (props?.params?.isactive === 1) {
    bgColor = green[50];
    textColor = green[900];
    borderColor = green[900];
  } else if (props?.params?.isactive === 0) {
    bgColor = red[50];
    textColor = red[900];
    borderColor = red[900];
  } else {
    bgColor = orange[50];
    textColor = deepOrange[900];
    borderColor = deepOrange[900];
  }

  return (
    <Typography
      sx={{
        bgcolor: bgColor,
        borderRadius: '100px',
        padding: '2px 10px',
        color: textColor,
        fontSize: '12px',
        fontWeight: '550',
        textTransform: 'uppercase',
        borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        width: 'max-content',
        // p: '5px',
      }}
    >
      {props.params.isactive === 1 ? 'Open' : 'Close'}
    </Typography>
  );
};

export default StatusColumn;
