import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ uuid, actionTaken, isactive, vendordetail, showAllMenu }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const role = localStorage.getItem('role');

  const handleAction = (action) => {
    actionTaken(uuid, action, isactive);
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {showAllMenu && (
          <MenuItem onClick={() => handleAction('Active')}>
            <ListItemIcon>
              <Iconify icon="eva:checkmark-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Approved"
              primaryTypographyProps={{ variant: 'body2' }}
              onClick={() => handleAction('Active')}
            />
          </MenuItem>
        )}

        {showAllMenu && (
          <MenuItem component={RouterLink} to="#" onClick={() => handleAction('Under Process')}>
            <ListItemIcon>
              <Iconify icon="eva:bar-chart-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Under Process" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {showAllMenu && (
          <MenuItem component={RouterLink} to="#" onClick={() => handleAction('Renewal')}>
            <ListItemIcon>
              <Iconify icon="eva:person-done-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Renewal" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {showAllMenu && (
          <MenuItem component={RouterLink} to="#" onClick={() => handleAction('With-Hold')}>
            <ListItemIcon>
              <Iconify icon="eva:close-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="With-Hold" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {showAllMenu && <Divider />}

        {showAllMenu && (
          <MenuItem
            component={RouterLink}
            to={{
              pathname: `/dashboard/userDetail/${uuid}/${vendordetail.status_updated_by}`,
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:file-text-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Details" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {showAllMenu && <Divider />}
        {/* Delete */}

        <MenuItem onClick={() => handleAction('Delete')}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-delete-forever" width={24} height={24} color={'red'} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{ variant: 'body2', color: 'red', fontWeight: '600' }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
