import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../api/withJWTServer';
import { actionTypes } from '../../actionTypes';

// -------------------Get Users Action------------------------
export const getVendorTransactions = createAsyncThunk(actionTypes.GET_VENDOR_TRANSACTION, async (params, thunkAPI) => {
  // console.log('params of vendor transactions', params);
  try {
    // console.log('params of vendor transactions', params);
    const url = `admin/get-subscription-payments?user_type=VENDOR&page=${
      params?.page + 1
    }&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    console.log('response of vendor transactions', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
