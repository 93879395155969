import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

// export const addCandidate = createAsyncThunk(actionTypes.ADD_CANDIDATE, async (params, thunkAPI) => {
//   try {
//     // const response = await axiosInstance.post('/admin/reviewer', params);
//     // console.log('response of add reviewer', response);
//     // return response;
//   } catch (error) {
//     // return thunkAPI.rejectWithValue(error);
//   }
// });

// export const updateCandidate = createAsyncThunk(actionTypes.UPDATE_CANDIDATE, async (params, thunkAPI) => {
//   try {
//     // const response = await axiosInstance.patch('/admin/reviewer', params);
//     // console.log('response of update reviewer', response);
//     // return response;
//   } catch (error) {
//     // return thunkAPI.rejectWithValue(error);
//   }
// });

export const getMatrimonySingleCandidate = createAsyncThunk(
  actionTypes.GET_SINGLE_MATRIMONY_CANDIDATE,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/admin/get-matrimony-candidate-by-uuid?uuid=${params}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getMatrimonyCandidates = createAsyncThunk(
  actionTypes.GET_MATRIMONY_CANDIDATES,
  async (params, thunkAPI) => {
    try {
      const url = params?.filterData
        ? `admin/get-matrimony-candidates?key=${params?.filterData}&page=${params?.page + 1}&limit=${
            params?.rowsPerPage
          }`
        : `/admin/get-matrimony-candidates?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
      const response = await axiosInstance.get(url);
      console.log('response of candidates', response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const deleteMatrimonyCandidate = createAsyncThunk(
  actionTypes.DELETE_MATRIMONY_CANDIDATE,
  async (params, thunkAPI) => {
    try {
      console.log('paramsinroll', params);
      const response = await axiosInstance.delete(`admin/delete-matrimony-candidate?uuid=${params?.uuid}`);
      // console.log('response of delet reviewer', response.data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// export const downloadResume = createAsyncThunk(actionTypes.GET_SINGLE_CANDIDATE, async (params, thunkAPI) => {
//   try {
//     const url = `admin/download-resume?uuid=${params}`;
//     const response = await axiosInstance.get(url);
//     const link = document.createElement('a');
//     link.href = response.data.data.resume_link;
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     return response;
//   } catch (e) {
//     return thunkAPI.rejectWithValue(e);
//   }
// }
// );
