import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import { Card, Container, Grid, Typography, Chip } from '@mui/material';
import { red } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { downloadUserExcel, getUsersList, deleteUser } from '../../Redux-Store/actions/userInfoActions';
// components
import Page from '../../components/Page';
import PagesTopBar from '../../Component/PagesTopBar';
import DataGridHeader from '../../Component/DataGridComponents/DataGridHeader';
import ShowMessage from '../../Component/ShowMessage';
import ActionColumn from '../../Component/TableComponent/ActionColumn';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';

export default function User() {
  const dispatch = useDispatch();
  const { users, isloading, dataCount } = useSelector((state) => ({
    users: state.userInfo?.users?.data,
    isloading: state.userInfo?.isLoading,
  }));

  console.log('users', users, isloading, users?.data_count);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [vendorData, setVendorData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [count, setCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [serialNumber, setSerialNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [userDelete, setUserDelete] = useState();
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const params = {
    filterData,
    page,
    rowsPerPage,
  };
  useEffect(() => {
    dispatch(getUsersList(params));
  }, [rowsPerPage, page, filterData]);

  const downloadXlsx = () => {
    dispatch(downloadUserExcel());
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setFilterData(value);
    setSearchValue(value);
  };
  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };
  const handleDelete = (e) => {
    setUserDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteUser({ uuid: userDelete }))
      .unwrap()
      .then((response) => {
        setSubmitLoader(true);
        console.log('response in delete user', response);
        if (response.status === 200) {
          setSubmitLoader(false);
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getUsersList(params));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        setSubmitLoader(false);
        console.log('error');
      });
  };

  // const handleEdit = (rowData) => {
  //   navigate('/dashboard/create_subscription', {
  //     state: {
  //       subscriptionData: rowData,
  //     },
  //   });
  // };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 100,
      flex: 0.3,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      type: 'string',
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      type: 'string',

      renderCell: (params) => (
        <Typography fontWeight={'500'} fontSize={'13px'}>
          {params.row.email}
        </Typography>
      ),
    },
    {
      field: 'mobile_number',
      headerName: ' Phone',
      width: 250,
      type: 'string',

      renderCell: (params) => (
        <Typography fontWeight={'400'} fontSize={'14px'}>
          {`+91-${params.row.mobile_number}`}
        </Typography>
      ),
    },
    {
      field: `recruitment`,
      headerName: 'Is Work Profile Created',
      width: 200,
      type: 'string',

      renderCell: (params) => {
        return params.row.recruitmentCandidate ? (
          <Chip
            label="YES"
            style={{
              background: 'teal',
              height: '28px',
              borderRadius: '4px',
              border: '1px solid teal',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 700,
            }}
          />
        ) : (
          <Chip
            label="NO"
            style={{
              background: red[400],
              height: '28px',
              borderRadius: '4px',
              border: '1px solid red',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 700,
            }}
          />
        );
      },
    },
    {
      field: `matrimony`,
      headerName: 'Is Matrimony Profile Created',
      width: 200,
      type: 'string',

      renderCell: (params) => {
        return params.row.matrimonyCandidatesModel ? (
          <Chip
            label="YES"
            style={{
              background: 'teal',
              height: '28px',
              borderRadius: '4px',
              border: '1px solid teal',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 700,
            }}
          />
        ) : (
          <Chip
            label="NO"
            style={{
              background: red[400],
              height: '28px',
              borderRadius: '4px',
              border: '1px solid red',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 700,
            }}
          />
        );
      },
    },
    {
      field: `createdAt`,
      headerName: 'Created On',
      width: 200,
      type: 'string',

      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('DD/MM/YYYY hh:MM A');
      },
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <ActionColumn
          showView={false}
          showEdit={false}
          showDelete
          // handleView={() => handleView(params.row)}
          // handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="User"
          description="Are you sure you want to delete user ?"
        />
      ) : null}
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          downloadXlsx={downloadXlsx}
          excel
          Add={false}
          title="Users"
          subTitle="List of all Users in your app."
          btnText="AddUserss"
          link="/dashboard/create_user"
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 500,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[10, 20, 30]}
                paginationMode="server"
                loading={isloading}
                rows={users?.data ? users?.data : rows}
                columns={columns}
                rowCount={users?.data_count}
                localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                // getRowId={(row) => row.role_id}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
}
