import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/styles';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Box, Stack, AppBar, Toolbar, IconButton, Chip, Typography } from '@mui/material';
import axiosInstance from '../../api/withJWTServer';
import { dashboardStateAction } from '../../Redux-Store/actions/dashboardActions';
// components
import Iconify from '../../components/Iconify';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Server from '../../api';
import LogoutModelBox from '../../Component/ModelBox/LogoutModelBox';

import { logoutAdmin } from '../../Redux-Store/actions/userActions';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  background: '#FFFFFF',

  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const dispatch = useDispatch();
  const [dashboardCount, setDashboardCount] = useState({});
  const [openLogoutModel, setOpenLogoutModel] = useState(false);

  const [name, setName] = useState('----');

  const navigate = useNavigate();

  const { dashboardState } = useSelector((state) => ({
    dashboardState: state.dashboard.dashboardState?.data?.data[0],
  }));
  const { profile, isloading } = useSelector((state) => ({
    profile: state.profile?.profile?.data?.data[0],
    isloading: state.profile?.isLoading,
  }));

  // const { role } = useSelector((state) => ({
  //   role: state.authentication.user?.data?.data[0].role,
  // }));

  const role = localStorage.getItem('role');

  useEffect(() => {
    dispatch(dashboardStateAction());
  }, []);

  useEffect(() => {
    setDashboardCount(dashboardState);
  }, [dashboardState]);

  const useStyles = makeStyles((theme) => ({
    sideBar: {
      '@media print': {
        display: 'none',
      },
    },
  }));

  useEffect(() => {
    dispatch(dashboardStateAction());
  }, []);

  const classes = useStyles();

  const fetchData = () => {
    try {
      Server.get('admin/get-profile', {
        headers: {
          token: localStorage.getItem('token'),
        },
      }).then((res) => {
        console.log('resprofile', res);
        setProfileDetail(res?.data?.data[0]);
        setName(res?.data?.data[0].firstname);
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [profileDetail, setProfileDetail] = useState({});
  console.log('profileDetail', profileDetail);

  useEffect(() => {
    fetchData();
  }, [profile]);

  const cropName = (name) => {
    const all = name;
    const words = all?.split(' ');

    const truncatedText = words?.slice(0, 1).join(' ');

    // Add ellipsis if there are more than three words
    const displayText = words?.length > 1 ? `${truncatedText} ` : truncatedText;
    console.log('displayText', displayText);
    return displayText;
  };

  const logoutFromApp = () => {
    setOpenLogoutModel(false);
    dispatch(logoutAdmin());
    navigate('/login', { replace: true });
  };

  return (
    <RootStyle>
      {openLogoutModel ? (
        <LogoutModelBox
          isOpen={openLogoutModel}
          handleLogoutCancel={() => {
            setOpenLogoutModel(false);
          }}
          handleLogout={() => {
            logoutFromApp();
          }}
        />
      ) : null}

      <ToolbarStyle className={`${classes.sideBar}`}>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box>
          <Typography style={{ color: '#060606', display: 'block', fontSize: '15px', fontWeight: '500' }}>
            Welcome
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Tooltip title={profileDetail?.firstname}>
              <Typography
                sx={{
                  alignItems: 'flex-start',
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: '22px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {name}
              </Typography>
            </Tooltip>
            <Chip
              label={`${role?.toUpperCase()}`}
              style={{
                background: '#d1e7d9',
                color: '#035421',
                fontSize: '12px',
                marginLeft: '10px',
                border: '1.5px solid #0B8238',
                borderRadius: '10px',
                fontWeight: '600',
              }}
            />

            {process.env.REACT_APP_ENV === 'stage' && (
              <Chip
                label={'Testing Environment'}
                style={{
                  background: '#EC5210',
                  color: 'white',
                  fontSize: '12px',
                  marginLeft: '10px',
                  border: '1.5px solid #D32F31',
                  borderRadius: '10px',
                  fontWeight: '600',
                }}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* {role === 'Reviewer' ? null : <NotificationsPopover />} */}
          <AccountPopover
            handleLogutFromApp={() => {
              setOpenLogoutModel(true);
            }}
          />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
