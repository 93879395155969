import { createSlice } from '@reduxjs/toolkit';
import {
  addCandidate,
  updateCandidate,
  getCandidates,
  getCandidate,
  deleteCandidate,
} from '../../actions/recruitment/candidateActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  candidate: undefined,
  error: '',
  isLoading: false,
  singleCandidateData: {},
};

// slice or redu
const Candidate = createSlice({
  name: 'Candidate',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseCandidate(builder, addCandidate);
    caseCandidate(builder, updateCandidate);
    caseCandidate(builder, getCandidates);
    caseCandidate(builder, getCandidate);
    caseCandidate(builder, deleteCandidate);
  },
});
export default Candidate.reducer;

const caseCandidate = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_CANDIDATE)) {
      state.candidate = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_CANDIDATE)) {
      state.isLoadingt = false;
      state.candidate = action.payload;
    } else 
    if (action.type.includes(actionTypes.GET_SINGLE_CANDIDATE)) {
      state.singleCandidateData = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_CANDIDATES)) {
      state.isLoading = false;
      state.candidate = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_CANDIDATE)) {
      state.candidate = action.payload;
      state.isLoading = false;
    }
  });
};
