// Import Firebase app and modules
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import axiosInstance from './api/withJWTServer';

const firebaseConfig = {
  apiKey: "AIzaSyDOweSDzMKb5qEpu1vO7imzXDXDq1bNjYA",
  authDomain: "sunn-grahak.firebaseapp.com",
  projectId: "sunn-grahak",
  storageBucket: "sunn-grahak.appspot.com",
  messagingSenderId: "864315570501",
  appId: "1:864315570501:web:ac70de93536623110da62c",
  measurementId: "G-HYZZDZE5LP"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

 const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, { vapidKey: "BCb-a_NHi9CJA4rqR8vU0xZBKsWaxoQ-mkW79m_6hbi-hiFV-SLZEEvuPrB_UZ5I5K4AxYgxdQlZWbik5Q0J1cg" });
    const accessTokenObj = localStorage.getItem("token");
    if (currentToken && accessTokenObj) {
      const params = {
        token: currentToken,
        role: 'admin',
        tokenPlatform: 'web',
      };
      try{
        axiosInstance.post('/admin/fcm-token', params).then((res)=>{
          // console.log("resnotification", res)
        })
      }catch(error){
        // console.log("error", error);
      }
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export { messaging, getToken, onMessage, requestForToken };
