import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

// material
import { Link, Stack, TextField, IconButton, InputAdornment, Card, Typography, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loginAdmin } from '../../../Redux-Store/actions/userActions';

// component
import Iconify from '../../../components/Iconify';
import ForgetPassword from './ForgetPassword';
import ShowMessage from '../../../Component/ShowMessage';

// ----------------------------------------------------------------------
const SectionStyle = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(0, 0, 0, 0),
  borderRadius: 33,
  position: 'relative',
  alignItems: 'center',
}));

const SubSection = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '100px',
}));

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  const { isLogged } = useSelector((state) => ({
    isLogged: state.authentication.isLogged,
  }));

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      // .matches(/@/, 'Email must contain the "@" symbol'),
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
      .matches(/(\.com|\.in)$/, 'Email must end with ".com" or ".in"'),
    password: Yup.string()
      .required('Password is required')
      .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter.')
      .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter.')
      .matches(/^(?=.*\d)/, 'Password must contain at least one numeric value.')
      .matches(/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/, 'Password must contain at least one special character.')
      .min(8, 'Password must be at least 8 characters long.'),
  });

  const firstRun = useRef(true);
  // useEffect(() => {
  //   if (firstRun.current) {
  //     firstRun.current = false;
  //     return;
  //   }
  //   navigate('/dashboard/app', { replace: true });
  //   setSubmitLoader(false);
  // }, [isLogged]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      dispatch(
        loginAdmin({
          email: value.email,
          password: value.password,
        })
      )
        .unwrap()
        .then((response) => {
          console.log('response in loginkk', response?.data?.status);
          if (response?.data?.status === 200) {
            setIsShow(true);
            setError(false);
            // setOpen(!open);
            console.log('response', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
              navigate('/dashboard/app', { replace: true });
            }, 1500);
          } else if (response?.data?.status === 404) {
            setIsShow(true);
            setError(true);
            // setOpen(!open);
            console.log('responsehgjhj', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
              // navigate('/dashboard/app', { replace: true });
            }, 1500);
          } else {
            console.log('else');
          }
        })
        .catch((error) => {
          console.log('error', error);
        });
      // setTimeout(() => {}, 1000);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  function toggleForgotPassword() {
    setIsForgetPassword(!isForgetPassword);
  }
  const handleForget = () => {
    setIsForgetPassword(!isForgetPassword);
  };
  return (
    <span>
      {!isForgetPassword ? (
        <>
          <SectionStyle>
            <SubSection>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ fontSize: 20, marginTop: 20 }}>
                    LOGIN
                  </Typography>
                  <Typography style={{ fontSize: 15, marginBottom: 15 }}>
                    Please provide your credential to proceed.
                  </Typography>
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Stack spacing={2}>
                        <TextField
                          fullWidth
                          label="Email Address"
                          autoComplete="username"
                          type="email"
                          {...getFieldProps('email')}
                          placeholder="Enter Email Address"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          focused="on"
                        />

                        <TextField
                          fullWidth
                          label="Password"
                          autoComplete="Password"
                          placeholder="Enter Password"
                          type={showPassword ? 'text' : 'password'}
                          {...getFieldProps('password')}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={handleShowPassword} edge="end">
                                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.password && errors.password)}
                          helperText={touched.password && errors.password}
                          focused="on"
                        />
                      </Stack>
                      <Stack sx={{ mt: 2 }}>
                        <LoadingButton
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          loading={submitLoader}
                          sx={{
                            '&:hover': {
                              bgcolor: 'green',
                            },
                          }}
                          style={{ borderRadius: 29.5, height: 46 }}
                        >
                          Login
                        </LoadingButton>
                      </Stack>

                      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                        <Link
                          component={Link}
                          variant="subtitle2"
                          to="#"
                          underline="hover"
                          onClick={() => toggleForgotPassword()}
                          style={{ textDecoration: 'none', margin: '0 auto', fontSize: '15px', cursor: 'pointer' }}
                        >
                          Forgot password?
                        </Link>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Grid>
              </Grid>
            </SubSection>
            <p
              style={{
                color: '#0B8238',
                fontSize: '14px',
                fontWeight: '400px',
                textAlign: 'center',
                position: 'absolute',
                bottom: '30px',
                left: '40px',
                right: '0px',
                width: '100%',
              }}
            >
              <span style={{ color: '#0B8238', fontWeight: '400px' }}>
                <b>ADMIN</b> & <b>REVIEWER</b>
              </span>{' '}
              both can login from this portal.
            </p>
          </SectionStyle>
        </>
      ) : (
        <SectionStyle>
          <SubSection>
            <Grid container>
              <Grid item xs={12}>
                <ForgetPassword handleForget={handleForget} />
              </Grid>
            </Grid>
          </SubSection>
          <p
            style={{
              color: '#0B8238',
              fontSize: '14px',
              fontWeight: '400px',
              textAlign: 'center',
              position: 'absolute',
              bottom: '30px',
              left: '40px',
              right: '0px',
              width: '100%',
            }}
          >
            <span style={{ color: '#0B8238', fontWeight: '400px' }}>
              <b>ADMIN</b> & <b>REVIEWER</b>
            </span>{' '}
            both can login from this portal.
          </p>
        </SectionStyle>
      )}
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
    </span>
  );
}
