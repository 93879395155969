export const actionTypes = {
  //  --------------Auth---------------
  ON_LOGIN: 'ON_LOGIN',
  ON_LOGOUT: 'ON_LOGOUT',
  RESET_STATE: 'RESET_STATE',

  //  --------------Dashboard---------------
  GET_DASHBOARD_STATE: 'GET_DASHBOARD_STATE',
  GET_USERS: 'GET_USERS',
  DELETE_USER: 'DELETE_USER',
  DOWNLOAD_EXCEL: 'DOWNLOAD_EXCEL',

  //  --------------Vendor---------------
  GET_VENDORS: 'GET_VENDORS',
  DELETE_VENDOR: 'DELETE_VENDOR',
  UPDATE_VENDOR: 'DELETE_VENDOR',
  SINGLE_VENDOR: 'SINGLE_VENDOR',

  //  --------------Transactions---------------
  GET_TRANSACTIONS: 'GET_TRANSACTIONS',

  //  --------------Categories---------------
  GET_CATEGORIES: 'GET_CATEGORIES',
  ADD_CATEGORIES: 'ADD_CATEGORIES',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  DELETE_CATEGORIES: 'DELETE_CATEGORIES',

  //  --------------Reviewers---------------
  GET_SINGLE_REVIEWER: 'GET_SINGLE_REVIEWER',
  GET_REVIEWERS: 'GET_REVIEWERS',
  ADD_REVIEWER: 'ADD_REVIEWER',
  UPDATE_REVIEWER: 'UPDATE_REVIEWER',
  DELETE_REVIEWER: 'DELETE_REVIEWER',

  //  --------------Subscription---------------
  GET_SINGLE_SUBSCRIPTION: 'GET_SINGLE_SUBSCRIPTION',
  GET_SUBSCRIPTIONS: 'GET_SUBSCRIPTIONS',
  ADD_SUBSCRIPTION: 'ADD_SUBSCRIPTIONS',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',

  // --------------------Profile-----------
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  GET_PROFILE: 'GET_PROFILE',
  // ---------------Recruitment---------------------
  // Vendor jobs
  GET_SINGLE_VENDOR_JOB: 'GET_SINGLE_VENDOR_JOB',
  GET_VENDOR_JOBS: 'GET_VENDOR_JOBS',
  ADD_VENDOR_JOB: 'ADD_VENDOR_JOB',
  UPDATE_VENDOR_JOB: 'UPDATE_VENDOR_JOB',
  DELETE_VENDOR_JOB: 'DELETE_VENDOR_JOB',
  DOWNLOAD_VENDOR_EXCEL: 'DOWNLOAD_VENDOR_EXCEL',

  //  Candidates
  GET_SINGLE_CANDIDATE: 'GET_SINGLE_CANDIDATE',
  GET_CANDIDATES: 'GET_CANDIDATES',
  ADD_CANDIDATE: 'ADD_CANDIDATE',
  UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
  DELETE_CANDIDATE: 'DELETE_CANDIDATE',

  // mster /job categories
  GET_SINGLE_JOB_CATEGORY: 'GET_SINGLE_JOB_CATEGORY',
  GET_JOB_CATEGORIES: 'GET_JOB_CATEGORIES',
  ADD_JOB_CATEGORY: 'ADD_JOB_CATEGORY',
  UPDATE_JOB_CATEGORY: 'UPDATE_JOB_CATEGORY',
  DELETE_JOB_CATEGORY: 'DELETE_JOB_CATEGORY',
  UPDATE_JOBCATEGORY_STATUS: 'UPDATE_JOBCATEGORY_STATUS',

  GET_JOB_TYPES: 'GET_JOB_TYPES',
  UPDATE_JOB_TYPE: 'UPDATE_JOB_TYPE',
  DELETE_JOB_TYPE: 'DELETE_JOB_TYPE',
  GET_SINGLE_JOB_TYPE: 'GET_SINGLE_JOB_TYPE',
  UPDATE_JOBTYPE_STATUS: 'UPDATE_JOBTYPE_STATUS',

  // mster /language
  GET_SINGLE_LANGUAGE: 'GET_SINGLE_LANGUAGE',
  GET_LANGUAGES: 'GET_LANGUAGES',
  ADD_LANGUAGE: 'ADD_LANGUAGE',
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  DELETE_LANGUAGE: 'DELETE_LANGUAGE',
  UPDATE_LANGUAGE_STATUS: 'UPDATE_LANGUAGE_STATUS',

  // mster /skill
  GET_SINGLE_SKILL: 'GET_SINGLE_SKILL',
  GET_SKILLS: 'GET_SKILLS',
  ADD_SKILL: 'ADD_SKILL',
  UPDATE_SKILL: 'UPDATE_SKILL',
  DELETE_SKILL: 'DELETE_SKILL',
  UPDATE_SKILL_STATUS: 'UPDATE_SKILL_STATUS',

  // mster /eduction
  GET_SINGLE_EDUCATION: 'GET_SINGLE_EDUCATION',
  GET_EDUCATIONS: 'GET_EDUCATIONS',
  ADD_EDUCATION: 'ADD_EDUCATION',
  UPDATE_EDUCATION: 'UPDATE_EDUCATION',
  DELETE_EDUCATION: 'DELETE_EDUCATION',
  UPDATE_EDUCATION_STATUS: 'UPDATE_EDUCATION_STATUS',

  // mster /sallary type
  // sallaray
  GET_SINGLE_SALARY_TYPE: 'GET_SINGLE_SALARY_TYPE',
  GET_SALARY_TYPES: 'GET_SALARY_TYPES',
  ADD_SALARY_TYPE: 'ADD_SALLAREAY_TYPE',
  UPDATE_SALARY_TYPE: 'UPDATE_SALARY_TYPE',
  DELETE_SALARY_TYPE: 'DELETE_SALARY_TYPE',

  // settings
  FETCH_MATRIMONY_STATUS: 'FETCH_MATRIMONY_STATUS',
  UPDATE_MATRIMONY_STATUS: 'UPDATE_MATRIMONY_STATUS',
  FETCH_RECRUITMENT_STATUS: 'FETCH_RECRUITMENT_STATUS',
  UPDATE_RECRUITMENT_STATUS: 'UPDATE_RECRUITMENT_STATUS',
  FETCH_SETTINGS_STATUS: 'FETCH_SETTINGS_STATUS',

  // catagories status
  UPDATE_STATUS: 'UPDATE_STATUS',

  // Matrimony
  GET_MARITAL_STATUS: 'GET_MARITAL_STATUS',
  ADD_MARITAL_STATUS: 'ADD_MARITAL_STATUS',
  UPDATE_MARITAL_STATUS: 'UPDATE_MARITAL_STATUS',
  DELETE_MARITAL_STATUS: 'DELETE_MARITAL_STATUS',

  // matrimony candidtaes
  GET_MATRIMONY_CANDIDATES: 'GET_MATRIMONY_CANDIDATES',
  DELETE_MATRIMONY_CANDIDATE: 'DELETE_MATRIMONY_CANDIDATE',
  GET_SINGLE_MATRIMONY_CANDIDATE: 'GET_MATRIMONY_SINGLE_CANDIDATE',

  // matrimony /language
  GET_MATRIMONY_SINGLE_LANGUAGE: 'GET_MATRIMONY_SINGLE_LANGUAGE',
  GET_MATRIMONY_LANGUAGES: 'GET_MATRIMONY_LANGUAGES',
  ADD_MATRIMONY_LANGUAGE: 'ADD_MATRIMONY_LANGUAGE',
  UPDATE_MATRIMONY_LANGUAGE: 'UPDATE_MATRIMONY_LANGUAGE',
  DELETE_MATRIMONY_LANGUAGE: 'DELETE_MATRIMONY_LANGUAGE',
  UPDATE_MATRIMONY_LANGUAGE_STATUS: 'UPDATE_MATRIMONY_LANGUAGE_STATUS',

  // matrimony master field
  GET_MATRIMONY_MASTER_FIELD: 'GET_MATRIMONY_MASTER_FIELD',
  ADD_MATRIMONY_MASTER_FIELD: 'ADD_MATRIMONY_MASTER_FIELD',
  UPDATE_MATRIMONY_MASTER_FIELD: 'UPDATE_MATRIMONY_MASTER_FIELD',
  DELETE_MATRIMONY_MASTER_FIELD: 'DELETE_MATRIMONY_MASTER_FIELD',
  UPDATE_MATRIMONY_MASTER_FIELD_STATUS: 'UPDATE_MATRIMONY_MASTER_FIELD_STATUS',
  GET_MATRIMONY_CAST: 'GET_MATRIMONY_CAST',

  // Matrimony send and receive requests
  GET_MATRIMONY_SEND_REQUESTS: 'GET_MATRIMONY_SEND_REQUESTS',
  DELETE_MATRIMONY_SEND_REQUEST: '  DELETE_MATRIMONY_SEND_REQUEST',
  GET_MATRIMONY_RECEIVE_REQUESTS: 'GET_MATRIMONY_RECEIVE_REQUESTS',
  DELETE_MATRIMONY_RECEIVE_REQUEST: '  DELETE_MATRIMONY_RECEIVE_REQUEST',

  // Vendor transactions Actions
  GET_VENDOR_TRANSACTION: 'GET_VENDOR_TRANSACTION',
  GET_MATRIMONY_TRANSACTION: 'GET_MATRIMONY_TRANSACTION',

  // GET_MATRIMONY_rE_REQUESTS // //  matrimony Candidates
  // GET_MATRIMONY_SINGLE_CANDIDATE: 'GET_MATRIMONY_SINGLE_CANDIDATE',
  // GET_MATRIMONY_CANDIDATES: 'GET_MATRIMONY_CANDIDATES',
  // ADD_MATRIMONY_CANDIDATE: 'ADD_MATRIMONY_CANDIDATE',
  // UPDATE_MATRIMONY_CANDIDATE: 'UPDATE_MATRIMONY_CANDIDATE',
  // DELETE_MATRIMONY_CANDIDATE: 'DELETE_MATRIMONY_CANDIDATE',
};
