import { createSlice } from '@reduxjs/toolkit';
import {
  deleteMatrimonyrReceivedRequests,
  getMatrimonyReceiveRequests,
} from '../../actions/Matrimony/MatrimonyReceiveRequestAction';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  MatrimonyReceiveRequests: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const MatrimonyReceiveRequests = createSlice({
  name: 'MatrimonyReceiveRequests',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseMatrimonyReceiverequest(builder, getMatrimonyReceiveRequests);
    caseMatrimonyReceiverequest(builder, deleteMatrimonyrReceivedRequests);
  },
});
export default MatrimonyReceiveRequests.reducer;

const caseMatrimonyReceiverequest = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.GET_MATRIMONY_RECEIVE_REQUESTS)) {
      state.MatrimonyReceiveRequests = action.payload;
      state.isLoading = false;
    }
  });
};
