import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/withJWTServer';
import { actionTypes } from '../actionTypes';
import Server from '../../api/index';

// -------------------Get Users Action------------------------
export const getVendorList = createAsyncThunk(actionTypes.GET_VENDORS, async (params, thunkAPI) => {
  console.log('params in vendorlist', params);
  try {
    console.log('params in userlidt', params);
    const url = `admin/list-vendors?key=${params?.filterData ?? ''}&page=${params?.page + 1}&limit=${
      params?.rowsPerPage
    }`;
    const response = await axiosInstance.get(url);
    console.log('response vendor', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// ------------------update vendor-----------------------
export const updateVendorList = createAsyncThunk(actionTypes.UPDATE_VENDOR, async (params, thunkAPI) => {
  console.log('params in update vendor', params);
  try {
    console.log('params in userlidt', params);
    const response = await axiosInstance.patch('/admin/update-vendor', params);
    console.log('response vendor', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// ------------------DELETE vendor-----------------------
export const deleteVendor = createAsyncThunk(actionTypes.DELETE_VENDOR, async (params, thunkAPI) => {
  console.log('params in delete vendor', params);
  try {
    console.log('params in delete vendor', params);
    const response = await axiosInstance.delete(`admin/delete-vendor?vendor_uuid=${params}`);
    console.log('response vendor', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// ------------------DETAILS vendor-----------------------
export const detailsVendor = createAsyncThunk(actionTypes.SINGLE_VENDOR, async (params, thunkAPI) => {
  console.log('params in delails vendor', params);
  try {
    console.log('params in delails vendor', params);
    const response = await axiosInstance.get(`admin/vendor-details`, { params });
    console.log('response vendor', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
