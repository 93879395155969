import { createSlice } from '@reduxjs/toolkit';
import { getTransactions } from '../../actions/transactioActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  transactions: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const Transactions = createSlice({
  name: 'Transactions',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseTransactions(builder, getTransactions);
  },
});
export default Transactions.reducer;

const caseTransactions = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.GET_TRANSACTIONS)) {
      state.isLoading = false;
      state.transactions = action.payload;
    }
  });
};
