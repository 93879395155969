import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

export const addReviewer = createAsyncThunk(actionTypes.ADD_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/reviewer', params);
    console.log('response of add reviewer', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateReviewer = createAsyncThunk(actionTypes.UPDATE_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/reviewer', params);
    console.log('response of update reviewer', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getReviewer = createAsyncThunk(actionTypes.GET_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('role/get-roles', params);
    console.log('response of get reviewer', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getReviewers = createAsyncThunk(actionTypes.GET_REVIEWERS, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/reviewer-list?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin/reviewer-list?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;

    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteReviewer = createAsyncThunk(actionTypes.DELETE_REVIEWER, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/reviewer?uuid=${params?.uuid}`);
    console.log('response of delet reviewer', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
