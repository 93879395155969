import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import noPdf from '../../Assets/noPdf.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function VendorDocModel(props) {
  const { isOpen, docs, data } = props;
  const handleClose = () => {
    props.handleClose();
  };

  const DistrictsSchema = Yup.object().shape(data ? {} : {});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ? {} : {},
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {},
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange } = formik;
  const getDocumentName = (docCategoryName) => {
    switch (docCategoryName) {
      case 'vendor_profile':
        return 'Vendor Profile';
      case 'company_logo':
        return 'Company Logo';
      case 'gumasta_doc':
        return 'Gumasta License';
      case 'business_doc':
        return 'Business Docs';
      case 'gst_registration_doc':
        return 'GST Docs';
      case 'pan_card':
        return 'PAN Card';
      case 'aadhar_card_front':
        return 'Aadhar Card Front';
      case 'aadhar_card_back':
        return 'Aadhar Card Back';
      default:
        return null;
    }
  };

  const handleClick = async (imageName) => {
    // Add your logic here to handle the click event
    const image = await fetch(
      `${
        process.env.REACT_APP_ENV === 'STAGE'
          ? process.env.REACT_APP_BASE_VENDOR_URL_STAGE
          : process.env.REACT_APP_BASE_VENDOR_URL_PROD
      }${imageName}`
    );

    // Split image name
    const nameSplit = imageName.split('/');
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = `${duplicateName}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={isOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Document List
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={12}>
            <Grid item xs={12} style={{ width: '1000px', height: '600px' }}>
              {docs?.length === 0 ? (
                <div>
                  <p className="legend">AadharCard</p>
                </div>
              ) : (
                docs?.map((val, index) => (
                  <>
                    <div>
                      <h3 style={{ color: '#000', textAlign: 'center' }}>{getDocumentName(val?.doc_category_name)}</h3>
                      {/\.pdf$/i.test(val?.doc_path) ? (
                        <div style={{ marginTop: '30px', textAlign: 'center' }}>
                          <img
                            src={noPdf}
                            alt="Selected"
                            style={{ height: '300px', aligned: 'center', margin: '0 auto' }}
                          />

                          <div
                            style={{
                              background: 'green',
                              color: 'white',
                              width: '350px',
                              fontSize: '16px',
                              borderRadius: '10px',
                              padding: '10px',
                              textAlign: 'center',
                              bottom: '0px',
                              marginTop: '20px',
                              margin: '20px auto',
                            }}
                          >
                            <a
                              href={`${val?.doc_path}`}
                              rel="noreferrer"
                              style={{ textDecoration: 'none', color: 'white' }}
                            >
                              Download Document
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div style={{ marginTop: '10px' }}>
                          <img
                            src={val?.doc_path ? val?.doc_path : val?.image_path}
                            alt="Imagelll"
                            style={{ height: '450px', objectFit: 'contain', alignSelf: 'center', margin: '0 auto' }}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ))
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
