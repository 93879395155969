import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const getMatrimonyReceiveRequests = createAsyncThunk(
  actionTypes.GET_MATRIMONY_RECEIVE_REQUESTS,
  async (params, thunkAPI) => {
    try {
      const url = `/admin/get-matrimony-candidate-request-sent?uuid=${params.candidteId}&receivedPage=${
        params?.page + 1
      }&receivedLimit=${params?.rowsPerPage}`;
      const response = await axiosInstance.get(url);
      console.log('response of receive req', response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const deleteMatrimonyrReceivedRequests = createAsyncThunk(
  actionTypes.DELETE_MATRIMONY_RECEIVE_REQUEST,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(`/admin/delete-matrimony-candidate-request-sent?uuid=${params.uuid}`);
      console.log('response of delete req', response);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
