import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addJobCategory = createAsyncThunk(actionTypes.ADD_JOB_CATEGORY, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-recruitment-job-category', params);
    console.log('response of add jobCategory', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateJobCategory = createAsyncThunk(actionTypes.UPDATE_JOB_CATEGORY, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-recruitment-job-category', params);
    console.log('response of update jobCategory', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getJobCategory = createAsyncThunk(actionTypes.GET_SINGLE_JOB_CATEGORY, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/admin/get-recruitment-job-category-by-id?id=${params}`);
    console.log('response of get jobCategory', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateJobCategoryStatus = createAsyncThunk(actionTypes.UPDATE_JOBCATEGORY_STATUS, async (params, thunkAPI) => {
  try {
    console.log('paramsUpdate', params);
    const response = await axiosInstance.post('/admin/update-master-status', params);
    console.log('response of update job category', response);
    return response;
  } catch (error) {
    return console.log(error);
  }
});

export const getJobCategories = createAsyncThunk(actionTypes.GET_JOB_CATEGORIES, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-job-categories?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin/get-recruitment-job-categories?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAllJobCategories = createAsyncThunk(actionTypes.GET_JOB_CATEGORIES, async (params, thunkAPI) => {
  try {
    const url = `/admin/get-recruitment-job-categories?page=1&limit=100`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteJobCategory = createAsyncThunk(actionTypes.DELETE_JOB_CATEGORY, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`/admin/delete-recruitment-job-category?uuid=${params?.uuid}`);
    console.log('response of delete jobCategory', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
