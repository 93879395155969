import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowMessage from '../../../../Component/ShowMessage';
import { addLanguage, updateLanguage } from '../../../../Redux-Store/actions/recruitment/languageActions';
import BackButton from '../../../../components/BackButton';

const CreateLanguage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const languageFormData = useLocation();
  const languageData = languageFormData?.state?.languageData;
  console.log('languageData', languageData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      // .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[a-zA-Z0-9\s'-]+$/, 'Special characters are not allowed')
      .matches(/^\s*\S.*$/, ' Only spaces are not allowed ')
      .required('Language is required')
      .min(2, 'Name should not less than 2 characters')
      .max(30, 'Name should not  more than 30 characters'),

    description: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[\w\s!@#$%^&*()-_=+[{};:'",.<>?`~|]*$/, 'Special characters are allowed')
      .matches(/^\s*\S.*$/, ' Only spaces are not allowed ')
      .min(2, 'Description should not less than 2 characters')
      .max(30, 'Description should not more than 30 characters'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: languageData ? languageData.name : '',
      description: languageData ? languageData.description : '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = languageData
        ? {
            uuid: languageData.uuid,
            name: value.name.toString().trim(),
            description: value.description.toString().trim(),
          }
        : {
            name: value.name.toString().trim(),
            description: value.description.toString().trim(),
          };
      if (languageData?.uuid) {
        dispatch(updateLanguage(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update language', response);
            if (response?.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            // console.log('errorinypdate', error);
            // if (error?.response?.status === 400) {
            //   console.log('error', error.response.status, error.response.data.error);
            //   setIsShowUpdate(true);
            //   setErrorUpdate(true);
            //   setErrorMessage(error.response.data.error);
            //   setTimeout(() => {
            //     setIsShowUpdate(false);
            //   }, 3000);
            // } else {
            //   setErrorUpdate(false);
            // }
          });
      } else {
        dispatch(addLanguage(params))
          .unwrap()
          .then((response) => {
            console.log('response of add language', response);
            if (response?.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('errorinadd', error);
            if (error?.response?.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="language" sx={{ width: '100%' }} maxWidth="xl">
        <BackButton currentPage="createLanguage" />
        <Typography variant="h6" fontWeight={'600'}>
          {languageData ? ' Update Language' : ' Create Language'}
        </Typography>
        <Typography fontSize={14}>Enter language details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={4} sx={{ padding: '40px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="name"
                    label="Language Name*"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="description"
                    label="Description"
                    {...getFieldProps('description')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    //   loading={submitLoader && true}
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      float: 'right',
                      '&:hover': {
                        background: '#0B8238',
                        opacity: 0.9,
                      },
                    }}
                  >
                    {languageData ? 'Update' : ' Save'}
                  </LoadingButton>
                </Grid>
                <Grid item xs={5} />
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateLanguage;
