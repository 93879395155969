import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Grid, Container, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { deleteCategories, getCategories } from '../../Redux-Store/actions/categoryActions';

// Import necessary components and functions
import SwitchBtn from '../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../Component/DataGridComponents/StatusColumn';
import CommonActiveDeactiveBox from '../../Component/ModelBox/CommonActiveDeactiveBox';
import CommonDeleteBox from '../../Component/ModelBox/CommonDeleteBox';
import PagesTopBar from '../../Component/PagesTopBar';
import Page from '../../components/Page';
import ActionColumn from '../../Component/TableComponent/ActionColumn';
import DataGridHeader from '../../Component/DataGridComponents/DataGridHeader';
import ShowMessage from '../../Component/ShowMessage';
import defaultImg from '../../Assets/noImagecamera.png';

function CustomToolbar(props) {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const onChange = (e) => {
    console.log('column search', e.target.value);
    // Update the state if you want instant updates
    setSearch(e.target.value);
  };

  const onKeyDown = (e) => {
    // console.log("dwn");
    if (e.key === 'Enter') {
      setSearch(e.target.value);
    }
  };
  const handleColumnSelection = (e, value) => {
    console.log('selection ', value);
  };
  return (
    <DataGridHeader
      showSearch
      showBulkAction={false}
      showDropdown={false}
      showExport
      defaultPropsCols=""
      onAutocompleteChange=""
      quickFilterProps=""
      handleClick={handleClick}
      handleCloseMenu={handleCloseMenu}
      openMenu={openMenu}
      anchorEl={anchorEl}
      onchange={onChange}
      onkeydown={onKeyDown}
      searchValue={search}
      handleColumnSelection={handleColumnSelection}
    />
  );
}

const Category = () => {
  // Define state variables inside the functional component
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [open, setOpen] = useState(false);
  // const [isloading, setIsLoading] = useState(false);
  const [categoryDelete, setcategoryDelete] = useState();
  const [filterData, setFilterData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  // const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});

  const [serialNumber, setSerialNumber] = useState(0);

  const { category, isloading } = useSelector((state) => ({
    category: state?.category?.category?.data,
    isloading: state.category?.isLoading,
  }));
  console.log('category', category);

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };
  useEffect(() => {
    dispatch(getCategories(paramsData));
  }, [filterData, paginationModel]);

  // Define event handlers or utility functions here
  const handleSubmit = () => {
    dispatch(deleteCategories({ id: categoryDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete category', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getCategories(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };

  const handleDelete = (e) => {
    console.log('eeeeee', e);
    setcategoryDelete(e.id);
    setOpen(!open);
  };
  const handleEdit = (rowData) => {
    navigate('/dashboard/create_category', {
      state: {
        categoryData: rowData,
      },
    });
  };

  const handleSearch = (value) => {
    console.log('search', value);
    setFilterData(value);
    setSearchValue(value);
  };

  const getPeginationModelValue = (e) => {
    // Handle pagination model change
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object', object);
    setActivateDeactivateObject(object);
    setOpenActiveDeactive(!openActiveDeactive);
  };
  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 100,
      flex: 0.4,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: `category_Image`,
      headerName: 'Category Image',
      flex: 0.7,
      // width: 150,
      type: 'binary',
      renderCell: (params) => {
        const imagePath = defaultImg;
        console.log('imagePrams', params);
        console.log('imagePath', imagePath);
        return (
          <img
            src={`${params.row?.icon_file_path ? params.row?.icon_file_path : imagePath}`}
            height="40px"
            width="40px"
            style={{ margin: '10px', borderRadius: '6px' }}
            alt="img"
          />
        );
      },
    },
    { field: 'category_name', flex: 1, headerName: 'Title', type: 'string', width: 250 },
    {
      field: 'category_description',
      headerName: 'Description',
      // flex: 1,
      width: 250,
    },

    {
      field: 'status',
      headerName: 'Status',
      // flex: 0.6,
      width: 150,
      renderCell: (params) => <StatusColumn params={params.row} />,
    },
    {
      field: 'IsTrending',
      headerName: 'Is Trending',
      // flex: 0.6,
      width: 150,
      renderCell: (params) => {
        return params.row.istrending === 1 ? (
          <>
            <button
              style={{
                backgroundColor: '#35752e',
                borderRadius: '8px',
                padding: '3px 15px',
                border: 'none',
              }}
            >
              <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#FFF' }}> Yes</Typography>
            </button>
          </>
        ) : (
          <>
            <button
              style={{
                backgroundColor: red[600],
                borderRadius: '8px',
                padding: '3px 15px',
                border: 'none',
              }}
            >
              <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#FFF' }}> No</Typography>
            </button>
          </>
        );
      },
    },
    {
      headerName: 'Actions',
      // width: 250,
      flex: 0.5,
      renderCell: (params) => (
        <ActionColumn
          showView={false}
          showEdit
          showDelete
          //  handleView={() => handleView(params.row)}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];
  const rows = [];
  return (
    <Page title="User" sx={{ width: '100%' }}>
      {/* Render CommonActiveDeactiveBox if openActiveDeactive state is true */}
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description="Are you sure you want to "
          isOpen={openActiveDeactive}
          handleCancel={handleActiveDeactiveClick}
        />
      ) : null}
      {/* Render CommonDeleteBox if open state is true */}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Category"
          description="Are you sure you want to delete Category ?"
        />
      ) : null}

      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        {/* Render PagesTopBar */}
        <PagesTopBar
          Add
          title="Categories"
          subTitle="List of all Categories in your app."
          btnText="Create Category"
          link="/dashboard/create_category"
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 500,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[10, 20, 30]}
                paginationMode="server"
                loading={isloading}
                rows={category?.data ? category?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={category?.data_count}
                localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default Category;
