import { createSlice } from '@reduxjs/toolkit';
import {
  addSubcription,
  updateSubcription,
  getSubcriptions,
  getSubcription,
  deleteSubcription,
} from '../../actions/subscriptionAction';
import { actionTypes } from '../../actionTypes';

// redux state
const initialState = {
  subcription: undefined,
  error: '',
  isLoadingRequest: false,
};

// slice or reducer
const Subcription = createSlice({
  name: 'Subcription',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    casesubcription(builder, addSubcription);
    casesubcription(builder, updateSubcription);
    casesubcription(builder, getSubcriptions);
    casesubcription(builder, getSubcription);
    casesubcription(builder, deleteSubcription);
  },
});
export default Subcription.reducer;

const casesubcription = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_SUBSCRIPTION)) {
      state.subcription = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_SUBSCRIPTION)) {
      state.isLoadin = false;
      state.subcription = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_SUBSCRIPTION)) {
      state.subcription = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_SUBSCRIPTIONS)) {
      state.isLoading = false;
      state.subcription = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_SUBSCRIPTION)) {
      state.subcription = action.payload;
      state.isLoading = false;
    }
  });
};
