import Server from '../../api';

const forgotPassword = async(params) =>{
    const response = await Server.post('admin/verify-email', params)
    return response;
}

const resetPassword = async(params)=>{
    const response = await Server.post('admin/reset-password', params);
    return response;
}

const apis = {
    forgotPassword,
    resetPassword
}

export default apis;