import { createSlice } from '@reduxjs/toolkit';
import { downloadUserExcel, getUsersList, deleteUser } from '../../actions/userInfoActions';

// redux state
const initialState = {
  report: undefined,
  users: undefined, // logged in users details
  error: '', // any api error
  isLoading: false, // loading flag
};

// slice or reducer
const UserInfo = createSlice({
  name: 'user_information',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    // reducer when api call is in progress
    builder.addCase(getUsersList.pending, (state) => {
      // state updated in pending state
      state.isLoading = true;
    });
    // reducer when api call is fulfilled
    builder.addCase(getUsersList.fulfilled, (state, action) => {
      // state updated in fulfilled state

      state.isLoading = false;
      state.users = action.payload;
    });
    // reducer when api call is rejected
    builder.addCase(getUsersList.rejected, (state) => {
      // state updated in rejected state
      state.isLoading = false;
    });

    builder.addCase(deleteUser.pending, (state) => {
      // state updated in pending state
      state.isLoading = true;
    });
    // reducer when api call is fulfilled
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      // state updated in fulfilled state

      state.isLoading = false;
      state.users = action.payload;
    });
    // reducer when api call is rejected
    builder.addCase(deleteUser.rejected, (state) => {
      // state updated in rejected state
      state.isLoading = false;
    });

    builder.addCase(downloadUserExcel.pending, (state) => {
      // state updated in pending state
      state.isLoading = true;
    });
    // reducer when api call is fulfilled
    builder.addCase(downloadUserExcel.fulfilled, (state, action) => {
      // state updated in fulfilled state

      state.isLoading = false;
      state.report = action.payload;
    });
    // reducer when api call is rejected
    builder.addCase(downloadUserExcel.rejected, (state) => {
      // state updated in rejected state
      state.isLoading = false;
    });
  },
});

export default UserInfo.reducer;
