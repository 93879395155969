import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { Link } from 'react-router-dom';
// material
import { Stack, TextField, Card, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { HandleExceptionWithSecureCatch } from '../../../Redux-Store/actions/CombineCatch';
import apis from '../../apis/apis';
import AlertLong from '../../../components/comman/AlertLong';
import ShowMessage from '../../../Component/ShowMessage';
// ----------------------------------------------------------------------

const ChipSection = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 20,
  right: 20,
}));
export default function ForgetPassword(props) {
  const [alert, setShowAlert] = useState(false);
  const [alertResponse, setAlertResponse] = useState({});
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      apis
        .forgotPassword({
          email: value.email,
        })

        .then((response) => {
          console.log('gjgjgjhy', response);
          if (response?.data?.status === 200) {
            setIsShow(true);
            setError(false);
            console.log('response', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
            }, 1500);
          } else {
            console.log('else');
            setIsShow(true);
            setError(true);
            // setOpen(!open);
            // console.log('responsehgjhj', response);
            setErrorMessage(response.data.message);
            setTimeout(() => {
              setIsShow(false);
              setSubmitLoader(false);
              // navigate('/dashboard/app', { replace: true });
            }, 1500);
          }
        })
        .catch((error) => {
          console.log('error', error);
          setIsShow(true);
          setError(true);
          // setOpen(!open);
          // console.log('responsehgjhj', response);
          setErrorMessage('Email must be a valid email');
          setTimeout(() => {
            setIsShow(false);
            setSubmitLoader(false);
            // navigate('/dashboard/app', { replace: true });
          }, 1500);
        });
      // setTimeout(() => {}, 1000);
    },
  });
  const handleForgetPage = () => {
    props.handleForget();
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <>
      {/* {alert ? <AlertLong alertResponse={alertResponse} /> : null} */}

      <Typography variant="h4" style={{ fontSize: 20, marginTop: 20 }}>
        Forget Password
      </Typography>

      <Typography style={{ fontSize: 15, marginBottom: 15 }}>Enter your details below.</Typography>

      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              {...getFieldProps('email')}
              placeholder="Enter Email Address"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          {/* <button onClick={handleForgetPage}>Login</button> */}
          <Stack sx={{ mt: 2 }}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={submitLoader}
              sx={{
                '&:hover': {
                  bgcolor: 'green',
                },
              }}
              style={{ borderRadius: 29.5, height: 46 }}
            >
              Reset Password
            </LoadingButton>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link
              // component={RouterLink}
              component={Link}
              variant="subtitle2"
              to="#"
              underline="hover"
              onClick={handleForgetPage}
              style={{
                textDecoration: 'none',
                margin: '0 auto',
                fontSize: '15px',
                cursor: 'pointer',
                fontWeight: '600',
                color: 'green',
              }}
            >
              Login
            </Link>
          </Stack>
        </Form>
      </FormikProvider>
      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
    </>
  );
}
