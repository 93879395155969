import axios from 'axios';

// const isStage = window.location.hostname.includes('stage');
// const isStage = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.headers.token = token;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      setTimeout(() => {
        localStorage.clear();
      }, 1000);
      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    }
  }
);
export default axiosInstance;
