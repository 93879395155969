import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { addReviewer, updateReviewer } from '../../Redux-Store/actions/reviewerActions';
import ShowMessage from '../../Component/ShowMessage';

const CreateReviewer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewerFormData = useLocation();

  const reviewerData = reviewerFormData?.state?.reviewerData;
  console.log('reviewerData', reviewerData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special characters not allowed')
      .required('First Name is required')
      .min(2, 'First Name should not less than 2 characters')
      .max(30, 'First Name should not more than 30 characters'),

    lastname: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special characters not allowed')
      .required('Last Name  is required')
      .min(2, 'Last Name should not less than 2 characters')
      .max(30, 'Last Name should not more than 30 characters'),

    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
      .matches(/(\.com|\.in)$/, 'Email must end with ".com" or ".in"')
      .required('Email is required'),
    mobilenumber: Yup.string()
      .required('Phone Number is required')
      .test(
        'is-10-digits',
        'Phone number must be valid 10 digits',
        (value) => /^[1-9]{1}\d{9}$/.test(value) && !/^[0]{2,}/.test(value)
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: reviewerData ? reviewerData.firstname : '',
      lastname: reviewerData ? reviewerData.lastname : '',
      email: reviewerData ? reviewerData.email : '',
      mobilenumber: reviewerData ? reviewerData.mobile_number : '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = reviewerData
        ? {
            uuid: reviewerData.uuid,
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email,
            mobile_number: value.mobilenumber,
          }
        : {
            firstname: value.firstname,
            lastname: value.lastname,
            email: value.email,
            mobile_number: value.mobilenumber,
          };
      if (reviewerData?.uuid) {
        dispatch(updateReviewer(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update reviewer', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setSubmitLoader(false);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addReviewer(params))
          .unwrap()
          .then((response) => {
            console.log('response of add reviewer', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Typography variant="h6" fontWeight={'600'}>
          {reviewerData ? ' Update Reviewer' : ' Create Reviewer'}
        </Typography>
        <Typography fontSize={14}>Enter your Reviewer details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={4} sx={{ padding: '40px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="firstname"
                    label="First Name *"
                    {...getFieldProps('firstname')}
                    error={Boolean(touched.firstname && errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="lastname"
                    label="Last Name *"
                    {...getFieldProps('lastname')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.lastname && errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="email"
                    type="text"
                    label="Email *"
                    {...getFieldProps('email')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="mobilenumber"
                    type="text"
                    label="Phone Number *"
                    {...getFieldProps('mobilenumber')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                      inputProps: {
                        maxLength: 10,
                      },
                    }}
                    error={Boolean(touched.mobilenumber && errors.mobilenumber)}
                    helperText={touched.mobilenumber && errors.mobilenumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={submitLoader && true}
                    size="medium"
                    type="submit"
                    variant="contained"
                    sx={{ float: 'right', padding: '10px 20px', '&:hover': { bgcolor: 'green' } }}
                  >
                    {reviewerData ? 'Update Account' : ' Create Account'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateReviewer;
