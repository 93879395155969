import { createSlice } from '@reduxjs/toolkit';
import { dashboardStateAction } from '../../actions/dashboardActions';

const initialState = {
  dashboardState: undefined,
  error: undefined,
  isLoading: false,
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,

  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(dashboardStateAction.pending, (state) => {
      state.isLoadingRequest = true;
    });

    builder.addCase(dashboardStateAction.fulfilled, (state, action) => {
      state.dashboardState = action.payload;
      state.isLoadingRequest = false;
    });

    builder.addCase(dashboardStateAction.rejected, (state) => {
      state.isLoadingRequest = false;
      state.user = null;
    });
  },
});

export default dashboard.reducer;
