import React, { useState } from 'react';
import axios from 'axios';

const Location = () => {
  const [address, setAddress] = useState('123 Main Street, Anytown, USA');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=YOUR_API_KEY`
      );
      const { results } = response.data;
      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
      } else {
        // Handle case when no results are found
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter address"
          value={address}
          onChange={handleAddressChange}
        />
        <button type="submit">Get Coordinates</button>
      </form>
      <div>
        Latitude: {latitude}, Longitude: {longitude}
      </div>
    </div>
  );
};

export default Location;