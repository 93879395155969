// @mui
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, Link } from 'react-router-dom';

import { Container, Grid, Stack, Typography, Button } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  background: '#0b8238',
  height: '100%',

  alignItems: 'center',
  alignContent: 'center',
  alignSelf: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

// ----------------------------------------------------------------------

export default function SuceessPayment() {
  const mdUp = useResponsive('up', 'md');

  const navigate = useNavigate();

  return (
    <Page title="SuccessPayment" style={{ height: '100%' }}>
      <RootStyle>
        <Stack sx={{ p: '10px', gap: '20px' }}>
          <center>
            <Iconify icon="ic:twotone-check-circle" color={'white'} sx={{ height: 50, width: 50 }} />
          </center>

          <Typography fontSize={24} fontWeight={'700'} color={'white'}>
            Payment Successful !
          </Typography>
          <Typography fontSize={16} fontWeight={'400'} color={'rgba(255,255,255,0.8)'} sx={{ marginTop: '5px' }}>
            Congratulations !, Your payment is successfully done. Now you can login into the app / web to use the
            services.
          </Typography>
          <center>
            <Link
              to="https://play.google.com/store/apps/details?id=com.sunn.grahak.consumer.sungrahak_consumer&hl=en"
              style={{
                // marginTop: '20px',
                marginTop: '20px',
                background: 'white',
                width: 'max-content',
                border: '2px solid rgba(255,255,255,0.8)',
                color: '#0b8238',
                // width: '200px',
                borderRadius: '8px',
                textDecoration: 'none',
                padding: '8px 20px',
                fontWeight: '500',
                '&:hover': {
                  background: 'white',
                  opacity: 0.9,
                },
              }}
              component="button"
            >
              OPEN APP
            </Link>
          </center>
        </Stack>
      </RootStyle>
    </Page>
  );
}
