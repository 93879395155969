import { createAsyncThunk } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';
import { setStatusPending, setStatusSuccess, setStatusError } from '../../reducer/recruitment/statusSlice';

export const updateStatus = createAsyncThunk(
  actionTypes.UPDATE_STATUS,
  async ({ category, status }, { dispatch }) => {
    try {
      dispatch(setStatusPending());
      const response = await axiosInstance.post('/admin/update-master-status', { status,category});
      const data = response.data;
      dispatch(setStatusSuccess({ category, data }));
      return { category, data };
    } catch (error) {
      dispatch(setStatusError(error.message));
      throw error;
    }
  }
);

// export const updateStatus = (category, status) => async (dispatch) => {
//   try {
//     dispatch(setStatusPending());
//     const response = await fetch(`/admin/update-master-status`, {
//       method: 'POST',
//       body: JSON.stringify({ status }),
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
//     const data = await response.json();
//     dispatch(setStatusSuccess({ category, data }));
//   } catch (error) {
//     dispatch(setStatusError(error.message));
//   }
// };
