import { useState, useEffect } from 'react';
// material
import { Card, Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { green, orange, purple, red, teal } from '@mui/material/colors';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { getVendorTransactions } from '../../Redux-Store/actions/Transaction/transactionVendorActions';
import Page from '../../components/Page';
import ShowMessage from '../../Component/ShowMessage';

export default function VendorTransactions() {
  const dispatch = useDispatch();

  // const usersString = ;
  const Updateduuid = localStorage.getItem('userUuid');

  const isLogged = localStorage.getItem('isLogged');
  const role = localStorage.getItem('role');

  const { vendorTransactions, isloading } = useSelector((state) => ({
    vendorTransactions: state.TransactionVendor?.transactionsVendor?.data,
    isloading: state.TransactionVendor?.isLoading,
  }));
  console.log('vendorTransactions', vendorTransactions);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [serialNumber, setSerialNumber] = useState(0);

  const [isPaymentShow, setIsPaymentShow] = useState(false);
  const [isSuspended, setIsSuspended] = useState(false);

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };
  const pageState = {
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getVendorTransactions(pageState));
  }, [paginationModel]);

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  const columns = [
    {
      field: 'id',
      headerName: 'Sr no',
      width: 70,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: 'business_names',
      headerName: 'Business Name',
      width: 200,
      renderCell: (params) => {
        return params.row.Vendor.vendorBusinessDetailsModels.length > 0 ? (
          <Typography fontSize={'14px'}>{params.row.Vendor.vendorBusinessDetailsModels[0]?.business_name}</Typography>
        ) : (
          <Typography fontSize={'14px'}> --- </Typography>
        );
      },
    },
    {
      field: 'firstname',
      headerName: 'Vendor Name',
      width: 160,
      renderCell: (params) => {
        return <Typography fontSize={'14px'}>{params.row.Vendor.firstname}</Typography>;
      },
    },
    {
      field: 'mobile_number',
      headerName: 'Mobile Number',
      width: 180,
      renderCell: (params) => {
        return <Typography fontSize={'14px'}>+91 - {params.row.Vendor.mobile_number}</Typography>;
      },
    },
    {
      field: 'payment_status',
      headerName: 'Payment Status',
      width: 180,
      renderCell: (params) => {
        return (
          <Typography
            sx={{
              bgcolor: params.row.payment_status === 1 ? green[700] : orange[800],
              fontSize: '11px',
              fontWeight: '600',
              color: 'white',
              padding: '5px 12px',
              borderRadius: '6px',
            }}
          >
            {params.row.payment_status === 1 ? 'Payment Done' : 'Payment Pending'}
          </Typography>
        );
      },
    },
    {
      field: 'transaction_id',
      headerName: 'Transaction ID',
      width: 180,
      renderCell: (params) => {
        return params?.row?.transaction_id ? (
          <Typography
            sx={{
              bgcolor: green[50],
              border: '1px solid green',
              fontSize: '10px',
              padding: '5px 8px',
              borderRadius: '6px',
              fontWeight: '600',
              color: green[800],
            }}
          >
            {params?.row?.transaction_id}
          </Typography>
        ) : (
          <Typography>----</Typography>
        );
      },
    },
    // {
    //   field: 'order_id',
    //   headerName: 'Payment Via',
    //   width: 180,
    //   renderCell: (params) => {
    //     console.log('params89');
    //     return (
    //       params.row.consumerSubscriptionDetails[0].isactive === 1 &&
    //       (params.row.order_id ? (
    //         <Typography
    //           sx={{
    //             bgcolor: purple[50],
    //             border: '1px solid purple',
    //             fontSize: '10px',
    //             padding: '5px 8px',
    //             borderRadius: '6px',
    //             fontWeight: '600',
    //             color: purple[800],
    //           }}
    //         >
    //           Payment Gateway
    //         </Typography>
    //       ) : (
    //         <Typography
    //           sx={{
    //             bgcolor: teal[50],
    //             border: '1px solid teal',
    //             fontSize: '10px',
    //             padding: '5px 8px',
    //             borderRadius: '6px',
    //             fontWeight: '600',
    //             color: teal[800],
    //           }}
    //         >
    //           Payment Link
    //         </Typography>
    //       ))
    //     );
    //   },
    // },

    {
      field: 'plan_title',
      headerName: 'Plan Name',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography fontSize={'14px'} fontWeight={'600'}>
            {params.row.Vendor?.vendor_subscription_details.length > 0
              ? params.row.Vendor?.vendor_subscription_details[0].subscription_plan?.plan_title
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'plan_price',
      headerName: 'Amount',
      width: 100,
      renderCell: (params) => {
        return params?.row?.total_amount ? (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            &#8377; {params?.row?.total_amount}
          </Typography>
        ) : (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            ----
          </Typography>
        );
      },
    },
    // {
    //   field: 'subscription_type',
    //   headerName: 'Subscription Type',
    //   width: 250,

    //   renderCell: (params) => {
    //     return params?.row?.Vendor?.vendor_subscription_details[0]?.subscription_plan?.subscription_type;
    //   },
    // },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 130,
      renderCell: (params) => {
        return (
          <Typography color={green[900]} fontSize={'14px'} fontWeight={'600'}>
            {params.row.Vendor?.vendor_subscription_details.length > 0
              ? moment(params.row.Vendor?.vendor_subscription_details[0]?.start_date).format('DD MMMM YYYY')
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      width: 130,
      renderCell: (params) => {
        return (
          <Typography color={red[800]} fontSize={'14px'} fontWeight={'600'}>
            {params.row.Vendor?.vendor_subscription_details.length > 0
              ? moment(params.row.Vendor?.vendor_subscription_details[0]?.end_date).format('DD MMMM YYYY')
              : ''}
          </Typography>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      width: 250,
      renderCell: (params) => {
        return <Typography fontSize={'14px'}>{moment(params.row.updatedAt).format('DD-MM-YYYY hh:mm a')}</Typography>;
      },
    },
  ];
  const rows = [];

  return (
    <>
      <Page title="User" sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', mt: '20px' }} maxWidth="xl">
          <Grid container sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center' }}>
            <Grid md={3} xs={12} sm={12} item gap={2}>
              <Typography fontSize={'20px'} fontWeight={'600'} color={'#010203'}>
                {'Vendor Transactions'}
              </Typography>
              {/* <Typography fontSize={'14px'} fontWeight={'400'}>
                {'List of your all vendor transactions'}
              </Typography> */}
            </Grid>
          </Grid>
          {/* ------------------Data grid---------------------------- */}
          <Card sx={{ marginTop: '25px' }}>
            <Grid container>
              <Grid item lg={12} xs={12} sm={12}>
                <DataGrid
                  disableColumnMenu
                  style={{
                    padding: '10px',
                    height: 'max-content',
                    width: '100%',
                    borderRadius: '10px',
                    fontSize: '14px',
                  }}
                  onPaginationModelChange={getPeginationModelValue}
                  paginationModel={paginationModel}
                  pageSizeOptions={[10, 20, 30]}
                  loading={isloading}
                  rows={vendorTransactions?.data ? vendorTransactions?.data : rows}
                  columns={columns}
                  getRowId={(row) => row.id}
                  rowCount={vendorTransactions?.data_count}
                  paginationMode="server"
                  localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: {
                        variant: 'outlined',
                        size: 'small',
                        sx: {
                          bgcolor: '#ffffff',
                          // borderRadius: '8px',
                          // border: '1px solid rgba(0,0,0,0.1)',
                          pb: '0px',
                          '& fieldset': { border: 'none' },
                        },
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>

        {/* ---------------------Message Box---------------------- */}
        <ShowMessage
          onClose={() => setIsShowUpdate(false)}
          title={errorMessage}
          type={errorUpdate ? 'error' : 'success'}
          open={isShowUpdate}
        />
        <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />

        <ShowMessage onClose={() => setIsSuspended(false)} title={errorMessage} type="error" open={isSuspended} />

        {/* payment message */}
        <ShowMessage onClose={() => setIsPaymentShow(false)} title={errorMessage} type="success" open={isPaymentShow} />
      </Page>
    </>
  );
}
