import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

const addMaritalStatus = createAsyncThunk(actionTypes.ADD_MARITAL_STATUS, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-matrimony-marital-status', params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMaritalStatus = createAsyncThunk(actionTypes.UPDATE_MARITAL_STATUS, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-matrimony-marital-status', params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMaritalStatusActiveOrIncative = createAsyncThunk(
  actionTypes.UPDATE_MATRIMONY_LANGUAGE_STATUS,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/admin/update-master-status', params);
      return response;
    } catch (error) {
      return console.log(error);
    }
  }
);

const getMaritalStatus = createAsyncThunk(actionTypes.GET_MARITAL_STATUS, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-matrimony-marital-status?key=${params?.filterData}&page=${params?.page + 1}&limit=${
          params?.rowsPerPage
        }`
      : `/admin/get-matrimony-marital-status?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;

    const response = await axiosInstance.get(url);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const deleteMaritalStatus = createAsyncThunk(actionTypes.DELETE_MARITAL_STATUS, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`admin/delete-matrimony-marital-status?uuid=${params?.uuid}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});




const getMatrimonyCandidates = createAsyncThunk(actionTypes.GET_MATRIMONY_CANDIDATES, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-candidates?key=${params?.filterData}&page=${params?.page + 1}&limit=${
          params?.rowsPerPage
        }`
      : `/admin/get-recruitment-candidates?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const deleteMatrimonyCandidate = createAsyncThunk(actionTypes.DELETE_MATRIMONY_CADIDATES, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`admin/reviewer?uuid=${params?.uuid}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});



const addMatrimonyLanguage = createAsyncThunk(actionTypes.ADD_MATRIMONY_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-matrimony-language', params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMatrimonyLanguage = createAsyncThunk(actionTypes.UPDATE_MATRIMONY_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/update-matrimony-language', params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMatrimonyLanguageStatus = createAsyncThunk(
  actionTypes.UPDATE_MATRIMONY_LANGUAGE_STATUS,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/admin/update-master-status', params);
      return response;
    } catch (error) {
      return console.log(error);
    }
  }
);

const getMatrimonyLanguage = createAsyncThunk(actionTypes.GET_MATRIMONY_SINGLE_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/admin/get-matrimony-language-by-id?uuid=${params}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const getMatrimonyLanguages = createAsyncThunk(actionTypes.GET_MATRIMONY_LANGUAGES, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-matrimony-languages?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
      : `/admin//get-matrimony-languages?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const deleteMatrimonyLanguage = createAsyncThunk(actionTypes.DELETE_MATRIMONY_LANGUAGE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.delete(`admin/delete-matrimony-language?uuid=${params?.uuid}`);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const addMatrimonyMasterField = createAsyncThunk(actionTypes.ADD_MATRIMONY_MASTER_FIELD, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-matrimony-master', params);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

const updateMatrimonyMasterField = createAsyncThunk(
  actionTypes.UPDATE_MATRIMONY_MASTER_FIELD,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.patch('/admin/update-matrimony-master', params);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const updateMatrimonyMasterFieldStatus = createAsyncThunk(
  actionTypes.UPDATE_MATRIMONY_MASTER_FIELD_STATUS,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.post('/admin/update-master-status-matrimony', params);
      return response;
    } catch (error) {
      return console.log(error);
    }
  }
);

const getMatrimonyMasterField = createAsyncThunk(actionTypes.GET_MATRIMONY_MASTER_FIELD, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-matrimony-masters?key=${params?.filterData}&master_type=${params?.masterType}&page=${
          params?.page + 1
        }&limit=${params?.rowsPerPage}`
      : `/admin/get-matrimony-masters?master_type=${params?.masterType}&page=${params?.page + 1}&limit=${
          params?.rowsPerPage
        }`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const deleteMatrimonyMasterField = createAsyncThunk(
  actionTypes.DELETE_MATRIMONY_MASTER_FIELD,
  async (params, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(`admin/delete-matrimony-master?uuid=${params?.uuid}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

const getMatrimonyCast = createAsyncThunk(actionTypes.GET_MATRIMONY_CAST, async (params, thunkAPI) => {
  try {
    const url = `/admin/get-matrimony-masters?master_type=${params?.masterType}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export {
  addMaritalStatus,
  getMaritalStatus,
  deleteMaritalStatus,
  updateMaritalStatus,
  updateMaritalStatusActiveOrIncative,
  getMatrimonyCandidates,
  deleteMatrimonyCandidate,
  addMatrimonyLanguage,
  updateMatrimonyLanguage,
  updateMatrimonyLanguageStatus,
  getMatrimonyLanguage,
  getMatrimonyLanguages,
  deleteMatrimonyLanguage,
  addMatrimonyMasterField,
  updateMatrimonyMasterField,
  updateMatrimonyMasterFieldStatus,
  getMatrimonyMasterField,
  deleteMatrimonyMasterField,
  getMatrimonyCast,
};
