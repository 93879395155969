import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';

const CommonDeleteBox = (props) => {
  return (
    <Dialog
      open={props.openDialog}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: '10px', padding: '30px' },
      }}
    >
      <DialogTitle sx={{ fontWeight: '500', fontSize: '16px', p: 0, mb: 1 }}> Delete {props.title}</DialogTitle>
      <DialogContent sx={{ p: 0, mb: 3 }}>
        <DialogContentText id="alert-dialog-slide-description" sx={{ fontWeight: '500', fontSize: '14px', p: 0, m: 0 }}>
          {props.description}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{}}>
        <Button
          //   size="small"
          variant="contained"
          onClick={props.handleClose}
          disableElevation
          //   color="success"
          sx={{ fontSize: '12px', background: 'teal', '&:hover': { bgcolor: 'teal' } }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={props.submitLoader && true}
          onClick={props.handleDeleteYes}
          variant="contained"
          color="error"
          disableElevation
          sx={{ fontSize: '12px', '&:hover': { bgcolor: 'red' } }}
        >
          Yes, Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommonDeleteBox;
