import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // material
import { Card, Grid, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { deleteReviewer, getReviewers, updateReviewer } from '../../../Redux-Store/actions/reviewerActions';
import { getCandidates, deleteCandidate } from '../../../Redux-Store/actions/recruitment/candidateActions';
import Page from '../../../components/Page';
import ActionColumn from '../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../Component/PagesTopBar';
import SwitchBtn from '../../../Component/DataGridComponents/SwitchBtn';
import StatusColumn from '../../../Component/DataGridComponents/StatusColumn';
import { downloadUserExcel, getUsersList } from '../../../Redux-Store/actions/userInfoActions';
import ShowMessage from '../../../Component/ShowMessage';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../Component/ModelBox/CommonActiveDeactiveBox';
import JobCategoryButton from '../../../Component/TableComponent/JobCategoryButton';

const Candidates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { candidate, isloading } = useSelector((state) => ({
    candidate: state.candidates?.candidate?.data,
    isloading: state.candidates?.isLoading,
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [reviewerDelete, setReviewerDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [serialNumber, setSerialNumber] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setSerialNumber(e.page * e.pageSize);
    setPaginationModel(e);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getCandidates(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (uuid, slug, isactive) => {
    console.log('handleActive', uuid, slug, isactive);

    let params;
    if (slug === 1) {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    } else {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    }
    console.log('params in update', params);
    dispatch(updateReviewer(params))
      .unwrap()
      .then((response) => {
        console.log('response in update reviewer', response);
        if (response.status === 200) {
          // setSubmitLoader(false);
          setOpenActiveDeactive(false);
          setActivateDeactivateObject({});
          setIsShowUpdate(true);
          setErrorMessage(response.data.message);
          dispatch(getCandidates(paramsData));
        }
        setTimeout(() => {
          setIsShowUpdate(false);
          // navigate(-1);
        }, 1000);
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      })
      .catch((err) => {
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      });
  };
  const handleView = (rowData) => {
    navigate('/dashboard/recruitment/candidates/create_candidateDetails', {
      state: {
        candidateData: rowData,
      },
    });
  };
  const handleDelete = (e) => {
    setReviewerDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteCandidate({ uuid: reviewerDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete reviewer', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getCandidates(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object090909', object);
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      uuid: object?.uuid,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    console.log('activeDeactivateObject', activeDeactivateObject);
    handleActivate(activeDeactivateObject?.uuid, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  // const handleEdit = (rowData) => {
  //   navigate('/dashboard/recruitment/candidates/create_candidate', {
  //     state: {
  //       candidateData: rowData,
  //     },
  //   });
  // };

  const downloadXlsx = () => {
    dispatch(downloadUserExcel());
  };

  const handleSearch = (value) => {
    setFilterData(value);
    setSearchValue(value);
  };

  const columns = [
    {
      field: 'reviewer_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `first_name`, headerName: 'First Name', width: 150, type: 'string' },
    { field: `last_name`, headerName: 'Last Name', width: 150, type: 'string' },
    {
      field: `recruitmentJobCategoryModel`,
      headerName: 'Job Category',
      width: 250,
      renderCell: (params) => {
        return params.row.recruitmentJobCategoryModel ? (
          <JobCategoryButton
            data={params.row.recruitmentJobCategoryModel ? params.row.recruitmentJobCategoryModel.name || '' : ''}
          />
        ) : (
          ''
        );
      },
    },
    { field: `work_experience`, headerName: 'Experience', width: 150, type: 'string' },
    { field: `gender`, headerName: 'Gender', width: 150, type: 'string' },
    { field: `email_address`, headerName: 'Email', width: 250, type: 'string' },
    {
      field: `date_of_birth`,
      headerName: 'DOB',
      width: 150,
      renderCell: (params) => {
        return new Date(params?.row?.date_of_birth).toISOString().split('T')[0];
      },
    },
    {
      field: 'mobile_number',
      headerName: 'Contact Number',
      width: 150,
      type: 'string',
      renderCell: (params) => (
        <Typography fontWeight={'400'} fontSize={'14px'}>
          {`+91-${params.row.mobile_number}`}
        </Typography>
      ),
    },
    { field: 'address', headerName: 'Address', width: 150, type: 'string' },
    { field: 'state', headerName: 'State', width: 150, type: 'string' },
    { field: 'city', headerName: 'City', width: 150, type: 'string' },
    {
      field: `recruitmentEducation.name`,
      headerName: 'Education',
      width: 150,
      type: 'string',
      renderCell: (params) => (params.row.recruitmentEducation ? params.row.recruitmentEducation.name || '' : ''),
    },
    { field: `linked_in_profile_link`, headerName: 'Linkedin Profile', width: 150, type: 'string' },
    // {
    //   field: 'activate',
    //   headerName: 'Activate/Deactivate',
    //   width: 200,
    //   renderCell: (params) => (
    //     <SwitchBtn handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)} statusData={params.row} />
    //   ),
    // },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 150, // Adjusted width to accommodate additional button
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ActionColumn
            showView
            showEdit={false}
            showDelete
            handleView={() => handleView(params.row)}
            // handleEdit={() => handleEdit(params.row)}
            handleDelete={() => handleDelete(params.row)}
          />
          <div style={{ marginLeft: '10px' }}>
            {/* <button variant="outlined" onClick={() => handleView(params.row)}>View</button> Add View button */}
          </div>
        </div>
      ),
    },
  ];
  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description="Are you sure you want to "
          isOpen={openActiveDeactive}
          handleClose={handleActiveDeactiveClick}
          handleSubmit={handleActiveDeactiveSubmit}
          handleCancel={handleActiveDeactiveClick}
          activeDeactivateObject={activeDeactivateObject}
        />
      ) : // <ActiveDeactiveModel
      //   isOpen={openActiveDeactive}
      //   handleClose={handleActiveDeactiveClick}
      //   handleSubmit={handleActiveDeactiveSubmit}
      //   handleCancel={handleActiveDeactiveClick}
      //   activeDeactivateObject={activeDeactivateObject}
      // />
      null}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Candidate"
          description="Are you sure you want to delete Candidate ?"
        />
      ) : null}

      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          candidate
          // downloadXlsx={downloadXlsx}
          // excel
          title="Candidates"
          subTitle="List of all Candidates in your app."
          btnText="Add Candidate"
          link="/dashboard/recruitment/candidates/create_candidate"
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[10, 15, 20]}
                paginationMode="server"
                loading={isloading}
                rows={candidate?.data ? candidate?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={candidate?.data_count}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default Candidates;
