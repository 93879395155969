import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from '../../api/withJWTServer';
import { actionTypes } from '../actionTypes';

// -------------------Get Users Action------------------------
export const getTransactions = createAsyncThunk(actionTypes.GET_TRANSACTIONS, async (params, thunkAPI) => {
  console.log('params in vendorlist', params);
  try {
    console.log('params in userlidt', params);
    const url = `admin/get-subscription-payments?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    console.log('response transactions', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
