// @mui
import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
}));

export default function BackButton({ currentPage }) {
  const navigate = useNavigate();
  const handleBack = () => {
    if (currentPage === 'createLanguage') {
      navigate('/dashboard/recruitment/master', { state: { tab: '2' } });
    } else if (currentPage === 'createJobCategory') {
      navigate('/dashboard/recruitment/master', { state: { tab: '1' } });
    } else if (currentPage === 'createSkill') {
      navigate('/dashboard/recruitment/master', { state: { tab: '3' } });
    } else if (currentPage === 'createEducation') {
      navigate('/dashboard/recruitment/master', { state: { tab: '4' } });
    } else if (currentPage === 'createJobType') {
      navigate('/dashboard/recruitment/master', { state: { tab: '5' } });
    } else {
      navigate(-1);
    }
  };

  return (
    <RootStyle>
      <Button
        // variant="contained"
        // size="small"
        onClick={handleBack}
        sx={{
          m: 0,
          p: 0,
          mb: '3%',
          minWidth: 0,
          height: '40px',
          color: '#212B36',
          // bgcolor: '#0B8238',
          fontSize: '12px',
          padding: '0px 15px !important',
          // '&:hover': {
          // background: '#0B8238',
          // opacity: 0.9,
          // },
        }}
        // disableElevation
        startIcon={<ArrowBack />}
      >
        Back
      </Button>
    </RootStyle>
  );
}
