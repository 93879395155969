import React from 'react';
import Modal from '@mui/material/Modal';
import { Button, Box, Grid, Container, Typography, TextField, ListItemIcon, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const WithHoldModelBox = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '30px',
    borderRadius: '10px',
  };
  return (
    <div>
      <Modal
        open={props.openModal}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" sx={{ fontWeight: '600', fontSize: '16px', p: 0, mb: 1 }}>
            Are you sure you want to with-hold vendor's application?
          </Typography>
          <Typography id="modal-modal-title" sx={{ fontWeight: '500', fontSize: '14px', p: 0, mb: 1 }}>
            Enter reason for With-Hold
          </Typography>
          <Box
            sx={{
              width: 500,
              maxWidth: '100%',
              marginTop: '20px',
              marginBottom: '35px',
            }}
          >
            <TextField fullWidth label="Remark" id="fullWidth" onChange={props.handleReason} />

            <span style={{ color: 'red' }}>{props.reason && props.reason}</span>
          </Box>
          <Button
            size="small"
            type="submit"
            variant="contained"
            style={{ float: 'right' }}
            sx={{ fontSize: '12px', background: 'teal', padding: '8px 20px', '&:hover': { bgcolor: 'teal' } }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={props.submitLoader && true}
            size="small"
            type="submit"
            variant="contained"
            color="error"
            style={{ float: 'right', marginRight: '10px' }}
            sx={{ padding: '8px 20px', '&:hover': { bgcolor: 'red' } }}
            onClick={props.handleRejectReason}
          >
            With-Hold
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
};

export default WithHoldModelBox;
