import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography, Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LoadingButton } from '@mui/lab';
// import axiosInstance from '../../api/withJWTServer';
import { getSubcriptions } from '../../Redux-Store/actions/subscriptionAction';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '.css-1o3uljr-MuiTypography-root-MuiDialogTitle-root': {
    // padding: '27px',
    // border:"1px solid red !important",
    width: '500px !important',
    borderRadius: '20px',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ width: '100%', height: '50%' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PaymentModel(props) {
  console.log('propsin payment', props);
  const dispatch = useDispatch();
  const [submitLoader, setSubmitLoader] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  console.log('subscriptionData', subscriptionData);
  const [selectedId, setSelectedID] = useState(props?.currentPlan);
  const [selectedAmount, setSelectedAmount] = useState('');

  // useEffect(() => {
  //   dispatch(getSubcriptions())
  //     .unwrap()
  //     .then((response) => {
  //       if(props.type === "vendor",)
  //       setSubscriptionData(response.data.data);
  //       setSelectedID(props?.currentPlan);
  //       // setSelectedAmount(props?.currentPlan.plan_price);
  //       response.data.data?.map((value, index) => {
  //         if (value.uuid === props?.currentPlan) {
  //           setSelectedAmount(value.plan_price);
  //         }
  //         return null;
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);
  useEffect(() => {
    dispatch(getSubcriptions())
      .unwrap()
      .then((response) => {
        console.log('134234', response.data.data);
        if (props.type === 'vendor') {
          const filteredData = response.data.data.filter(
            (item) => item.subscription_type === 'vendor_recruitment_subscription'
          );
          console.log('filteredData', filteredData);
          setSubscriptionData(filteredData);
          setSelectedID(props?.currentPlan);
          filteredData.forEach((value) => {
            if (value.uuid === props?.currentPlan) {
              setSelectedAmount(value.plan_price);
            }
          });
        } else if (props.type === 'matrimony') {
          // Filter data for matrimony type
          const filteredData = response.data.data.filter(
            (item) => item.subscription_type === 'consumer_matrimony_subscription'
          );
          setSubscriptionData(filteredData);
          setSelectedID(props?.currentPlan);
          filteredData.forEach((value) => {
            if (value.uuid === props?.currentPlan) {
              setSelectedAmount(value.plan_price);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePlanChange = (selectedPlanId, price) => {
    // props.handlePlanChange(selectedPlanId);

    setSelectedID(selectedPlanId);
    setSelectedAmount(price);
  };

  const handleSubmit = () => {
    props.handlePaymentSubmit(selectedId, selectedAmount);
  };

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={props.isPaymentOpen}
        onClose={props.handlePaymentCancel}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.handlePaymentCancel}>
          <Typography style={{ fontSize: '22px', fontWeight: '700' }}>Please select payment plans </Typography>
        </BootstrapDialogTitle>

        <Box sx={{ padding: '10px', height: '500px', width: '500px', overflowY: 'scroll' }}>
          {subscriptionData?.map((item) => {
            const isSelected = selectedId === item.uuid;
            return (
              <>
                <div key={item.id} style={{ padding: '10px' }}>
                  <input
                    type="radio"
                    id={item.id}
                    name="plans"
                    value={item.uuid}
                    checked={isSelected}
                    onChange={(e) => handlePlanChange(e.target.value, item.plan_price)}
                    style={{ marginRight: '10px', fontSize: '40px', transform: 'scale(1.5)' }}
                  />
                  <label htmlFor={item.id} style={{ fontWeight: 700 }}>
                    {item.plan_title} - &#8377; {item.plan_price} ({item.plan_type})
                  </label>

                  <div style={{ marginLeft: '25px', mt: '5px' }}>
                    <p>{item.plan_description}</p>
                  </div>
                </div>
                <Divider />
              </>
            );
          })}
        </Box>

        <span style={{ padding: '10px', textAlign: 'end' }}>
          <Grid item xs={12} sx={{ p: 3 }} justifyContent={'flex-end'}>
            <LoadingButton
              loading={submitLoader && true}
              size="small"
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              variant="contained"
              style={{
                float: 'center',
                margin: 10,
                background: 'teal',
                width: '86px',
                height: '34px',
                borderRadius: '6px',
              }}
              disabled={!selectedId}
            >
              Send Link
            </LoadingButton>
            <LoadingButton
              size="small"
              type="submit"
              color="error"
              // sx={{ background: 'red[600] }}
              onClick={props.handlePaymentCancel}
              variant="contained"
              style={{ float: 'center', margin: 10, width: '86px', height: '34px', borderRadius: '6px' }}
            >
              Cancel
            </LoadingButton>
          </Grid>
        </span>
      </BootstrapDialog>
    </div>
  );
}
