import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LoadingButton } from '@mui/lab';
import { Typography, Dialog, DialogTitle, Grid } from '@mui/material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ width: '100%', height: '50%' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CommonDeleteModel(props) {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { isOpen } = props;
  const handleClose = () => {
    props.handleClose();
  };
  const handleSubmit = () => {
    setSubmitLoader(true);
    setTimeout(() => {
      props.handleSubmit();
      setSubmitLoader(false);
    }, 1000);
  };
  const handleCancel = () => {
    props.handleCancel();
  };
  return (
    <div style={{ padding: '10px' }}>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={isOpen} onClose={handleClose}>
        <BootstrapDialogTitle id="customized-dialog-title">
          {/* Are you sure you want to delete? */}
          Delete {props?.title}
        </BootstrapDialogTitle>

        <Typography sx={{ fontSize: '13px' }}>Are you sure you want to delete?</Typography>

        <Grid item xs={6} sx={{ p: 3 }}>
          <LoadingButton
            size="small"
            type="submit"
            onClick={handleCancel}
            variant="contained"
            style={{ float: 'right', margin: 10 }}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            loading={submitLoader && true}
            size="small"
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            style={{ float: 'right', margin: 10, background: 'red' }}
          >
            Delete
          </LoadingButton>
        </Grid>
      </BootstrapDialog>
    </div>
  );
}
