import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // material
import { Card, Grid, Container, Box, Chip, Button, Typography } from '@mui/material';
import { green, red, teal } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { deleteReviewer, getReviewers, updateReviewer } from '../../../Redux-Store/actions/reviewerActions';
import {
  getMatrimonyCandidates,
  deleteMatrimonyCandidate,
  getMatrimonySingleCandidate,
} from '../../../Redux-Store/actions/Matrimony/MatrimonyCandidatesAction';
import Page from '../../../components/Page';
import ActionColumn from '../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../Component/PagesTopBar';

import { downloadUserExcel, getUsersList } from '../../../Redux-Store/actions/userInfoActions';
import ShowMessage from '../../../Component/ShowMessage';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../Component/ModelBox/CommonActiveDeactiveBox';
import JobCategoryButton from '../../../Component/TableComponent/JobCategoryButton';
import Status from '../../../components/Status';
// import { createPayment } from '../../../api';
import axiosInstance from '../../../api/withJWTServer';
import StatusColumn from '../../../Component/DataGridComponents/StatusColumn';
import PaymentModel from '../../../Component/ModelBox/PaymentModel';
// import Server from '../../../api';

const MatrimonyCandidates = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { matrimonyCandidates, isloading } = useSelector((state) => ({
    matrimonyCandidates: state.matrimonyCandidtes.matrimonyCandidate?.data,
    isloading: state.matrimonyCandidtes?.isLoading,
  }));

  console.log('matrimonyCandidates', matrimonyCandidates);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [candidateDelete, setCandidateDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [serialNumber, setSerialNumber] = useState(0);
  // const [paymentDetails, setPaymentDetails] = useState();
  const [isPaymentShow, setIsPaymentShow] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentObject, setPaymentObject] = useState();
  console.log('paymentObject', paymentObject);
  const [selectedPlan, setSelectedPlan] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const [currentAmount, setCurrentAmount] = useState();
  const [DefaultPlanId, setDefaultPlanId] = useState(null);

  // console.log('paymentDetails', paymentDetails);

  const getPeginationModelValue = (e) => {
    setPaginationModel(e);
    setSerialNumber(e.page * e.pageSize);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getMatrimonyCandidates(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (uuid, slug, isactive) => {
    let params;
    if (slug === 1) {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    } else {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    }
    dispatch(updateReviewer(params));
    // .unwrap()
    // .then((response) => {
    //   if (response.status === 200) {
    //     // setSubmitLoader(false);
    //     setOpenActiveDeactive(false);
    //     setActivateDeactivateObject({});
    //     setIsShowUpdate(true);
    //     setErrorMessage(response.data.message);
    //     // dispatch(getCandidates(paramsData));
    //   }
    //   setTimeout(() => {
    //     setIsShowUpdate(false);
    //     // navigate(-1);
    //   }, 1000);
    //   // setOpenSuspendModel(false);
    //   setActivateDeactivateObject({});
    // })
    // .catch((err) => {
    //   // setOpenSuspendModel(false);
    //   setActivateDeactivateObject({});
    // });
  };
  const handleView = (rowData) => {
    console.log('rowData.id', rowData);
    navigate(`/dashboard/matrimony/candidates/view-details/${rowData.id}`, {
      state: {
        candidatesId: rowData?.uuid,
      },
    });
  };
  const handleDelete = (e) => {
    setCandidateDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setReviewerDelete(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteMatrimonyCandidate({ uuid: candidateDelete }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage(response.data.message);
          dispatch(getMatrimonyCandidates(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      uuid: object?.uuid,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    handleActivate(activeDeactivateObject?.uuid, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  const handleEdit = (rowData) => {
    navigate('/dashboard/create_reviewer', {
      state: {
        candidateData: rowData,
      },
    });
  };

  const downloadXlsx = () => {
    dispatch(downloadUserExcel());
  };

  const handleSearch = (e) => {
    setFilterData(e);
    setSearchValue(e);
  };
  // --------Payment events-----------------
  const handleSendPaymentLink = (rowData) => {
    console.log('Data', rowData);
    if (rowData.isactive === 1) {
      setPaymentOpen(true);
      // setPaymentObject(rowData);
      dispatch(getMatrimonySingleCandidate(rowData.uuid))
        .unwrap()
        .then((response) => {
          console.log('54345345', response.data.status);
          if (response.data.status === 200) {
            // handlePaymentSubmit(response.data.data);
            setPaymentObject(response.data.data);
          }
        });
    } else {
      // setIsSuspended(true);
      // setErrorMessage('Account is suspeded please unsuspend the account then proceed.');
    }
  };
  const currentDate = moment();
  const handlePaymentSubmit = (planID, planPrice) => {
    console.log('planID', planID, planPrice);

    const paramsData = {
      total_amount: planPrice,
      subscription_plan_uuid: planID,
      user_type: 'MATRIMONY_CANDIDATE',
      candidate_uuid: paymentObject[0]?.uuid,
      start_date: currentDate.format('YYYY-MM-DD'),
      end_date: currentDate.add(364, 'days').format('YYYY-MM-DD'),
      uuid: paymentObject[0]?.subscription_details.uuid,
    };

    console.log('paramsData', paramsData);
    axiosInstance
      .post('/admin/create-payment', paramsData)
      .then((res) => {
        console.log('response of payment', res);
        if (res.status === 200) {
          setIsPaymentShow(true);
          setError(false);
          setErrorMessage('Payment link send successfully !');
          setTimeout(() => {
            setIsPaymentShow(false);
          }, 1500);
          setPaymentOpen(false);
        } else {
          // setIsPaymentShow(true);
          setError(false);
          setErrorMessage(res.data.message);
        }
      })
      .catch((error) => {
        setPaymentOpen(false);
        console.log('response of error', error);
      });
  };

  const handlePaymentCancel = () => {
    setPaymentOpen(false);
    setPaymentObject({});
  };

  const handlePlanChange = (planId) => {
    setSelectedPlan(planId);
  };

  const handleDefaultPlanId = (defaultPlanId) => {
    setDefaultPlanId(defaultPlanId);
  };

  const columns = [
    {
      field: 'candidates_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `first_name`, headerName: 'First Name', width: 150, type: 'string' },
    { field: `last_name`, headerName: 'Last Name', width: 150, type: 'string' },

    {
      field: `gender`,
      headerName: 'Gender',
      width: 150,
      type: 'string',
      renderCell: (params) =>
        params?.row?.gender === 'Female' ? (
          <Chip
            label="Female"
            style={{
              background: teal[800],
              color: 'white',
              fontSize: '12px',
              marginLeft: '10px',
              borderRadius: '6px',
              fontWeight: '600',
            }}
          />
        ) : (
          <Chip
            label="Male"
            style={{
              background: red[600],
              color: 'white',
              fontSize: '12px',
              marginLeft: '10px',
              borderRadius: '6px',
              fontWeight: '600',
            }}
          />
        ),
    },
    { field: `email_address`, headerName: 'Email', width: 250, type: 'string' },
    {
      field: `date_of_birth`,
      headerName: 'DOB',
      width: 150,
      renderCell: (params) => {
        return new Date(params?.row?.date_of_birth).toISOString().split('T')[0];
      },
    },
    {
      field: 'mobile_number',
      headerName: 'Contact Number',
      width: 150,
      type: 'string',
      renderCell: (params) => (
        <Typography fontWeight={'400'} fontSize={'14px'}>
          {`+91-${params.row.mobile_number}`}
        </Typography>
      ),
    },
    { field: 'address', headerName: 'Address', width: 150, type: 'string' },
    { field: 'state', headerName: 'State', width: 150, type: 'string' },
    { field: 'city', headerName: 'City', width: 150, type: 'string' },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params) => {
        return params?.row?.isactive ? <StatusColumn params={params.row} /> : null;
      },
    },
    {
      field: 'col5',
      headerName: 'Payment Link',
      width: 180,
      renderCell: (params) => {
        console.log('paramsinlink', params.row);
        if (
          params.row.subscription_details.isactive === 0 &&
          params.row.subscription_details.subscription_status === null
        ) {
          return (
            <Button
              onClick={() => handleSendPaymentLink(params.row)}
              variant="outlined"
              style={{ padding: '3px 10px', fontSize: '12px' }}
              sx={{
                bgcolor: 'white',
                boxShadow: 'none',
                borderRadius: '6px',
                border: '1px solid green',
                '&:hover': { bgcolor: 'green', border: '1px solid green', color: 'white' },
              }}
            >
              Send Payment Link
            </Button>
          );
        }
        if (
          params.row.subscription_details.isactive === 1 &&
          params.row.subscription_details.subscription_status === 'paid'
        ) {
          return (
            <Chip
              label="Payment Done"
              style={{
                background: 'teal',
                height: '28px',
                borderRadius: '4px',
                border: '1px solid teal',
                color: '#fff',
                fontSize: '12px',
                fontWeight: 700,
              }}
            />
          );
        }

        return '---';
      },
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ActionColumn
            showView
            showEdit={false}
            showDelete
            handleView={() => handleView(params.row)}
            // handleEdit={() => handleEdit(params.row)}
            handleDelete={() => handleDelete(params.row)}
          />
        </div>
      ),
    },
  ];
  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description="Are you sure you want to "
          isOpen={openActiveDeactive}
          handleClose={handleActiveDeactiveClick}
          handleSubmit={handleActiveDeactiveSubmit}
          handleCancel={handleActiveDeactiveClick}
          activeDeactivateObject={activeDeactivateObject}
        />
      ) : // <ActiveDeactiveModel
      //   isOpen={openActiveDeactive}
      //   handleClose={handleActiveDeactiveClick}
      //   handleSubmit={handleActiveDeactiveSubmit}
      //   handleCancel={handleActiveDeactiveClick}
      //   activeDeactivateObject={activeDeactivateObject}
      // />
      null}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Matrimiony Candidate"
          description="Are you sure you want to delete Candidate ?"
        />
      ) : null}
      {paymentOpen ? (
        <PaymentModel
          handlePaymentSubmit={handlePaymentSubmit}
          DefaultPlanId={handleDefaultPlanId}
          currentPlan={currentPlan}
          currentAmount={currentAmount}
          selectedPlan={selectedPlan}
          handlePlanChange={handlePlanChange}
          isPaymentOpen={paymentOpen}
          paymentObject={paymentObject}
          handlePaymentCancel={handlePaymentCancel}
          type="matrimony"
        />
      ) : null}

      <Container id="candidate" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          candidate
          // downloadXlsx={downloadXlsx}
          // excel
          title="Candidates"
          subTitle="List of all Candidates in your app."
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[10, 15, 20]}
                paginationMode="server"
                loading={isloading}
                rows={matrimonyCandidates?.data ? matrimonyCandidates?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={matrimonyCandidates?.data_count}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
      {/* payment message */}
      <ShowMessage
        onClose={() => setIsPaymentShow(false)}
        title={errorMessage}
        type={!error ? 'success' : 'error'}
        open={isPaymentShow}
      />
    </Page>
  );
};

export default MatrimonyCandidates;
