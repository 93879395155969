import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';

const CommonActiveDeactiveBox = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: '10px', padding: '30px' },
      }}
    >
      <DialogTitle sx={{ fontWeight: '500', fontSize: '16px', p: 0, mb: 1 }}>
        {' '}
        {props.suspend ? 'Are you sure you want to Suspend ?' : ' Active/InActive'}{' '}
      </DialogTitle>
      <DialogContent sx={{ p: 0, mb: 5 }}>
  <DialogContentText id="alert-dialog-slide-description" sx={{ fontWeight: '500', fontSize: '14px', p: 0, m: 0 }}>
    {props.description} {`${props.activeDeactivateObject.isactive === 'Inactive' ? 'Activate' : 'Inactive'}`} {props.categoryName}?
  </DialogContentText>
</DialogContent>

      <DialogActions sx={{}}>
        <Button
          //   size="small"
          variant="contained"
          onClick={props.handleClose}
          disableElevation
          //   color="success"
          sx={{ fontSize: '12px', background: 'teal', '&:hover': { bgcolor: 'teal' } }}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={props.submitLoader && true}
          onClick={props.handleSubmit}
          variant="contained"
          color="error"
          disableElevation
          sx={{ fontSize: '12px', '&:hover': { bgcolor: 'red' } }}
        >
          {props.suspend ? 'Suspend' : 'Yes'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommonActiveDeactiveBox;
