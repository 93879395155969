import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Grid, Menu, MenuItem, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Page from '../../../components/Page';
import SendRequest from './SendRequest';
import ReceivedRequest from './ReceivedRequest';
import Rectangle from '../../../Assets/Rectangle.png';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

export default function CandidatesRequests() {
  const navigate = useNavigate();
  const data = useLocation();
  const RecivedId = data?.state?.candidatesDataId;
  const RecivedName = data?.state?.candidateName;

  console.log('data', data, RecivedId, RecivedName);

  const recivedTabValue = data.state.value.toString();
  localStorage.setItem('tabValueRequest', recivedTabValue);
  console.log('recivedTabValue', recivedTabValue);
  const [value, setValue] = React.useState(recivedTabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tabValueRequest', newValue);
  };

  const getTabValue = () => {
    const persistValue = localStorage.getItem('tabValueRequest');
    console.log('persistValue', persistValue);
    if (persistValue !== null) {
      setValue(persistValue);
    }
  };
  useEffect(() => {
    getTabValue();
  }, []);

  const masterDataMenuItem = [
    {
      id: '1',
      name: 'Send Requests',
      //   navigationURL: '/dashboard/matrimony/master/create-marital-status',
    },
    {
      id: '2',
      name: 'Received Requests',
      //   navigationURL: '/dashboard/matrimony/master/create-education',
    },
  ];

  return (
    <RootStyle>
      <Page title="Master(Recruitment)" sx={{ width: '100%' }}>
        <Grid container md={12} sx={{ px: 4 }}>
          <Grid container sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center', gap: '20px' }}>
            <Box>
              <Typography fontWeight={'600'} fontSize={25}>
                {RecivedName}
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Request details of candidtes
              </Typography>
            </Box>
            {/* <img
              src={Rectangle}
              alt="Rectangle"
              style={{
                width: '50px',
                height: '50px',
                gap: '0px',
                borderRadius: '50%',
              }}
            /> */}
          </Grid>

          <Grid md={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  sx={{ mt: '-18px' }}
                  //   variant="scrollable"
                  //   scrollButtons
                  //   aria-label="visible arrows tabs example"
                  //   sx={{
                  //     [`& .${tabsClasses.scrollButtons}`]: {
                  //       '&.Mui-disabled': { opacity: 0.3 },
                  //     },
                  //   }}
                >
                  {masterDataMenuItem.map((value, index) => (
                    <Tab label={value.name} value={value.id} />
                  ))}
                </TabList>
              </Box>

              <Grid item md={12}>
                <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                  <SendRequest RecivedId={RecivedId} />
                </TabPanel>
                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                  <ReceivedRequest RecivedId={RecivedId} />
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </Grid>
      </Page>
    </RootStyle>
  );
}
