import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../../actionTypes';
import axiosInstance from '../../../api/withJWTServer';

export const addEducation = createAsyncThunk(actionTypes.ADD_EDUCATION, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/create-recruitment-education', params);
    console.log('response of add education', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateEducationStatus = createAsyncThunk(actionTypes.UPDATE_EDUCATION_STATUS, async (params, thunkAPI) => {
  try {
    console.log('paramsUpdate', params);
    const response = await axiosInstance.post('/admin/update-master-status', params);
    console.log('response of update education', response);
    return response;
  } catch (error) {
    return console.log(error);
  }
});

// export const updateEducationStatus = createAsyncThunk(
//   actionTypes.UPDATE_EDUCATION_STATUS,
//   async ({ category, status }, { rejectWithValue }) => {
//     try {
//       const response = await axiosInstance.post('/admin/update-master-status', { category, status });
//       return { category, status: response.data.status };
//     } catch (error) {
//       return rejectWithValue({ category, error: error.message });
//     }
//   }
// );

export const updateEducation = createAsyncThunk(actionTypes.UPDATE_EDUCATION, async (params, thunkAPI) => {
  try {
    console.log('paramsUpdate', params);
    const response = await axiosInstance.patch('/admin/update-recruitment-education', params);
    console.log('response of update education', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getEducation = createAsyncThunk(actionTypes.GET_SINGLE_EDUCATION, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post(`/admin/get-recruitment-education-by-id?id=${params}`);
    console.log('response of get education', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getEducations = createAsyncThunk(actionTypes.GET_EDUCATIONS, async (params, thunkAPI) => {
  try {
    const url = params?.filterData
      ? `admin/get-recruitment-educations?key=${params?.filterData}&page=${params?.page + 1}&limit=${
          params?.rowsPerPage
        }`
      : `/admin/get-recruitment-educations?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteEducation = createAsyncThunk(actionTypes.DELETE_EDUCATION, async (params, thunkAPI) => {
  try {
    console.log('paramsinroll', params);
    const response = await axiosInstance.delete(`admin/delete-recruitment-education?uuid=${params?.uuid}`);
    console.log('response of delet education', response.data);
    return response;
  } catch (e) {
    // return thunkAPI.rejectWithValue(e);
  }
});
