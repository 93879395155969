import { configureStore } from '@reduxjs/toolkit';
import authentication from './reducer/users/authentication';
import dashboard from './reducer/dashboard/dashboard';
import userInfo from './reducer/usersInfo/userInfo';
import vendorInfo from './reducer/Vendors/Vendor';
import reviewer from './reducer/reviewer/reviewer';
import category from './reducer/Category/category';
import subscription from './reducer/Subscription/Subscription';
import profile from './reducer/profile/profile';
import vendorJob from './reducer/recruitment/vendorJob';
import candidates from './reducer/recruitment/candidates';
import jobCategories from './reducer/recruitment/jobCategories';
import eduction from './reducer/recruitment/education';
import skills from './reducer/recruitment/skills';
// import salaryType from './reducer/recruitment/JobType';
import jobType from './reducer/recruitment/JobType';
import languages from './reducer/recruitment/languages';
import settingsReducer from './reducer/settings/settings';
import statusReducer from './reducer/recruitment/statusSlice';
import transactions from './reducer/transactions/transactions';
import matrimony from './reducer/matrimony/Matrimony';
import matrimonyCandidtes from './reducer/matrimony/MatrimonyCandidates';
import MatrimonySendRequests from './reducer/matrimony/MatrimonySendRequests';
import MatrimonyReceiveRequests from './reducer/matrimony/MatrimonyReceiveRequests';
import TransactionMatrimony from './reducer/Transaction/transactionMatrimony';
import TransactionVendor from './reducer/Transaction/transactionVendor';

// store configuration

export const Store = configureStore({
  reducer: {
    authentication,
    dashboard,
    userInfo,
    vendorInfo,
    reviewer,
    category,
    subscription,
    profile,
    vendorJob,
    candidates,
    jobCategories,
    eduction,
    skills,
    // salaryType,
    jobType,
    languages,
    statusReducer,
    settings: settingsReducer,
    transactions,
    matrimony,
    matrimonyCandidtes,
    MatrimonySendRequests,
    MatrimonyReceiveRequests,
    TransactionVendor,
    TransactionMatrimony,
  },
  // middleware for async api calls

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// //export stores state and dispatch
// export type RootState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
