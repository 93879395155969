import { CheckRounded, FactCheckRounded } from '@mui/icons-material';
import { Alert, AlertColor, Snackbar, Typography } from '@mui/material';
import { green, red, teal } from '@mui/material/colors';
import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const ShowMessage = (props) => {
  const handleClose = (event, reason) => {
    props.onClose();
  };

  return (
    <Snackbar open={props.open} onClose={handleClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <Alert
        onClose={handleClose}
        severity={props.type}
        variant="filled"
        elevation={5}
        icon={
          props.type === 'success' ? (
            <CheckCircleIcon sx={{ fontSize: '20px' }} />
          ) : (
            <ErrorIcon sx={{ fontSize: '20px' }} />
          )
        }
        sx={{
          width: '100%',
          bgcolor: props.type === 'success' ? green[700] : red[700],
          color: 'white',
          borderRadius: '100px',
          fontSize: '14px',
        }}
      >
        {props.title}
      </Alert>
    </Snackbar>
  );
};

export default ShowMessage;
