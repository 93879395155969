import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

export const updateProfile = createAsyncThunk(actionTypes.UPDATE_PROFILE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('admin/update-profile', params);
    console.log('response of update profile', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getProfile = createAsyncThunk(actionTypes.GET_PROFILE, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.get('admin/get-profile');
    console.log('response of get profile', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
