import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Box,
  // Button
} from '@mui/material';

import { Link as ButtonLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import DateRangePickerMui from '../components/DateRangePickerMui';

const MasterTopBar = (props) => {
  const [searchValue, setSearchValue] = useState('');

  let timer = null;
  const handleSearchValueChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      props.handleSearch(value.trim());
    }, 1000);
  };

  const clearInput = () => {
    props.clearInput();
    props.handleSearch('');
    setSearchValue('');
  };

  return (
    <Grid container sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center' }}>
      <Grid lg={8} xs={12} sm={12} item gap={2}>
        <Typography fontSize={'18px'} fontWeight={'600'}>
          {props.title}
        </Typography>
        {/* <Typography fontSize={'12px'} fontWeight={'600'}>
          {props.subTitle}
        </Typography> */}
      </Grid>

      <Grid lg={4} xs={12} sm={12} item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box>
          <TextField
            // autoComplete="off"
            id="searchField"
            name="searchField"
            size="small"
            placeholder="Search By Name"
            // variant="standard"
            value={searchValue}
            autoComplete="search"
            // label="Search"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '40px !important',
                background: 'white',
                width: '100%',
              },
            }}
            onChange={handleSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={clearInput} edge="end">
                    <CancelIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default MasterTopBar;
