import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Card, Grid, Container, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {
  deleteVendorJob,
  downloadVendorJobExcel,
  getVendorJobs,
  updateVendorJob,
} from '../../../Redux-Store/actions/recruitment/vendorJobActions';
import Page from '../../../components/Page';
import ActionColumn from '../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../Component/PagesTopBar';
import SwitchBtn from '../../../Component/DataGridComponents/SwitchBtn';
import { downloadUserExcel, getUsersList } from '../../../Redux-Store/actions/userInfoActions';
import StatusColumn2 from '../../../Component/DataGridComponents/StatusColomn2';
import ShowMessage from '../../../Component/ShowMessage';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../Component/ModelBox/CommonActiveDeactiveBox';
import JobCategoryButton from '../../../Component/TableComponent/JobCategoryButton';

const VendorJobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { vendorJob, isloading } = useSelector((state) => ({
    vendorJob: state.vendorJob?.vendorJob?.data,
    isloading: state.vendorJob?.isLoading,
  }));

  console.log('reviewers', vendorJob);
  console.log('isloading', isloading);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [vendorDelete, setVendorDelete] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [serialNumber, setSerialNumber] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const getPeginationModelValue = (e) => {
    console.log('getPeginationModelValue', e);
    setPaginationModel(e);
    setSerialNumber(e.page * e.pageSize);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
  };

  useEffect(() => {
    dispatch(getVendorJobs(paramsData));
  }, [filterData, paginationModel]);

  const handleActivate = (uuid, slug, isactive) => {
    console.log('handleActive', uuid, slug, isactive);

    let params;
    if (slug === 1) {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    } else {
      params = {
        uuid: uuid.toString(),
        isactive,
        status: isactive ? 'Active' : 'Inactive',
      };
    }
    console.log('params in update', params);
    dispatch(updateVendorJob(params))
      .unwrap()
      .then((response) => {
        console.log('response in update reviewer', response);
        if (response.status === 200) {
          // setSubmitLoader(false);
          setOpenActiveDeactive(false);
          setActivateDeactivateObject({});
          setIsShowUpdate(true);
          setErrorMessage(response.data.message);
          dispatch(getVendorJobs(paramsData));
        }
        setTimeout(() => {
          setIsShowUpdate(false);
          // navigate(-1);
        }, 1000);
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      })
      .catch((err) => {
        // setOpenSuspendModel(false);
        setActivateDeactivateObject({});
      });
  };

  const handleDelete = (e) => {
    setVendorDelete(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setVendorDelete(e.uuid);
    setOpen(!open);
  };

  const handleView = (rowData) => {
    navigate('/dashboard/recruitment/vendorjob/vendorjob_details', {
      state: {
        vendorJobData: rowData,
      },
    });
  };

  const handleSubmit = () => {
    dispatch(deleteVendorJob({ uuid: vendorDelete }))
      .unwrap()
      .then((response) => {
        console.log('response in delete vendor', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          dispatch(getVendorJobs(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const handleActiveDeactiveClick = (object) => {
    console.log('object090909', object);
    setActivateDeactivateObject({
      // uuid: object?.uuid,
      // status: object?.status,
      // isactive: object?.isactive === 1 ? 0 : 1,
      uuid: object?.uuid,
      isactive: object?.isactive ? 'active' : 'Inactive',
      status: object?.isactive === 1 ? 0 : 1,
    });
    setOpenActiveDeactive(!openActiveDeactive);
  };

  const handleActiveDeactiveSubmit = () => {
    console.log('activeDeactivateObject', activeDeactivateObject);
    handleActivate(activeDeactivateObject?.uuid, activeDeactivateObject?.isactive, activeDeactivateObject?.status);
  };

  // const handleEdit = (rowData) => {
  //   navigate('/dashboard/recruitment/vendorjob/create_vendorjob', {
  //     state: {
  //       vendorData: rowData,
  //     },
  //   });
  // };

  const downloadXlsx = () => {
    dispatch(downloadVendorJobExcel());
  };

  const handleSearch = (value) => {
    setFilterData(value);
    setSearchValue(value);
  };

  // const columns = [
  //   {
  //     field: 'vendor_id',
  //     headerName: 'Sr no',
  //     width: 100,
  //     renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
  //   },
  //   { field: `job_title`, headerName: 'Job Title', width: 150, type: 'string' },
  //   { field: 'job_description', headerName: ' Description', width: 200, type: 'string' },
  //   // { field: 'job_type', headerName: ' Description', width: 300, type: 'string' },
  //   { field: 'company_name', headerName: ' Company Name', width: 150, type: 'string' },
  //   { field: 'job_min_experience', headerName: ' Minimum Experiance', width: 150, type: 'string' },
  //   { field: 'job_max_experience', headerName: ' Maximun Experiance', width: 150, type: 'string' },
  //   { field: 'recruitment_education_uuid', headerName: 'Recruitment Education ID', width: 150, type: 'string' },
  //   { field: 'no_of_openings', headerName: 'Jobs Open ', width: 150, type: 'string' },
  //   { field: 'state', headerName: 'State', width: 150, type: 'string' },
  //   { field: 'city', headerName: 'City', width: 150, type: 'string' },
  //   { field: 'job_min_salary', headerName: ' Min Salary ', width: 150, type: 'string' },
  //   { field: 'job_max_salary', headerName: ' Max Salary ', width: 150, type: 'string' },
  //   {
  //     field: 'job_Status',
  //     headerName: 'Job Status',
  //     width: 200,
  //     renderCell: (params) => <StatusColumn2 params={params.row} />,
  //   },
  //   {
  //     field: `Vendor.firstname`,
  //     headerName: 'Vendor Name',
  //     width: 150,
  //     type: 'string',
  //     renderCell: (params) => (params.row.Vendor ? params.row.Vendor.firstname || '' : ''),
  //   },
  //   {
  //     field: `recruitmentJobCategoryModel`,
  //     headerName: 'Job Category',
  //     width: 150,
  //     renderCell: (params) => {
  //       return (
  //         <JobCategoryButton
  //           data={params.row.recruitmentJobCategoryModel ? params.row.recruitmentJobCategoryModel.name || '' : ''}
  //         />
  //       );
  //     },
  //   },
  //   // {
  //   //   field: 'activate',
  //   //   headerName: 'Activate/Deactivate',
  //   //   width: 200,
  //   //   renderCell: (params) => (
  //   //     <SwitchBtn handleActiveDeactiveClick={(e) => handleActiveDeactiveClick(params.row)} statusData={params.row} />
  //   //   ),
  //   // },
  //   {
  //     field: 'col6',
  //     headerName: 'Actions',
  //     width: 150,
  //     renderCell: (params) => (
  //       <ActionColumn
  //         Candidates
  //         handleView={() => handleView(params.row)}
  //         // handleEdit={() => handleEdit(params.row)}
  //         handleDelete={() => handleDelete(params.row)}
  //       />
  //     ),
  //   },
  // ];

  const columns = [
    {
      field: 'vendor_id',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    { field: `job_title`, headerName: 'Job Title', width: 150, type: 'string' },
    { field: 'job_description', headerName: ' Description', width: 200, type: 'string' },
    { field: 'company_name', headerName: ' Company Name', width: 150, type: 'string' },
    {
      field: 'job_min_experience',
      headerName: 'Min Experience',
      width: 150,
      renderCell: (params) => {
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.job_min_experience} years
          </Typography>
        );
      },
    },

    {
      field: 'job_max_experience',
      headerName: ' Max Experience',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.job_max_experience} years
          </Typography>
        );
      },
    },
    {
      field: 'recruitment_education_uuid',
      headerName: 'Recruitment Education ',
      width: 250,
      renderCell: (params) => {
        console.log('params in edu', params);
        return params.row.recruitmentEducation ? params.row.recruitmentEducation.name : '';
      },
    },
    {
      field: 'no_of_openings',
      headerName: 'Jobs Opening ',
      width: 150,
      renderCell: (params) => {
        console.log('params in edu', params);
        return (
          <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
            {params.row.no_of_openings ? params.row.no_of_openings : ''}
          </Typography>
        );
      },
    },
    { field: 'state', headerName: 'State', width: 150, type: 'string' },
    { field: 'city', headerName: 'City', width: 150, type: 'string' },
    {
      field: 'job_min_salary',
      headerName: ' Min Salary ',
      width: 150,
      renderCell: (params) => (
        <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
          &#8377; {params?.row?.job_min_salary}
        </Typography>
      ),
    },
    {
      field: 'job_max_salary',
      headerName: ' Max Salary ',
      width: 150,
      renderCell: (params) => (
        <Typography fontSize={'14px'} fontWeight={'600'} color={'green'}>
          &#8377; {params?.row?.job_max_salary}
        </Typography>
      ),
    },
    {
      field: 'job_Status',
      headerName: 'Job Status',
      width: 150,
      renderCell: (params) => <StatusColumn2 params={params.row} />,
    },
    {
      field: 'job_type',
      headerName: 'Job Type',
      width: 200,
    },

    {
      field: `recruitmentJobCategoryModel`,
      headerName: 'Job Category',
      width: 250,
      renderCell: (params) => {
        return (
          <JobCategoryButton
            data={params.row.recruitmentJobCategoryModel ? params.row.recruitmentJobCategoryModel.name || '' : ''}
          />
        );
      },
    },
    {
      field: `createdAt`,
      headerName: 'Created On',
      width: 250,
      type: 'string',

      renderCell: (params) => {
        return moment(params?.row?.createdAt).format('DD/MM/YYYY hh:MM A');
      },
    },
    {
      field: 'col6',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <ActionColumn
          showView
          showEdit={false}
          showDelete
          handleView={() => handleView(params.row)}
          // handleEdit={() => handleEdit(params.row)}
          handleDelete={() => handleDelete(params.row)}
        />
      ),
    },
  ];

  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {openActiveDeactive ? (
        <CommonActiveDeactiveBox
          description="Are you sure you want to "
          isOpen={openActiveDeactive}
          handleClose={handleActiveDeactiveClick}
          handleSubmit={handleActiveDeactiveSubmit}
          handleCancel={handleActiveDeactiveClick}
          activeDeactivateObject={activeDeactivateObject}
        />
      ) : // <ActiveDeactiveModel
      //   isOpen={openActiveDeactive}
      //   handleClose={handleActiveDeactiveClick}
      //   handleSubmit={handleActiveDeactiveSubmit}
      //   handleCancel={handleActiveDeactiveClick}
      //   activeDeactivateObject={activeDeactivateObject}
      // />
      null}
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Vendor Job"
          description="Are you sure you want to delete Vendor Job ?"
        />
      ) : null}

      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          candidate
          // downloadXlsx={downloadXlsx}
          // excel
          title="Vendor Jobs"
          subTitle="List of all Vendor Jobs in your app."
          btnText="Add Vendor job"
          link="/dashboard/recruitment/vendorjob/create_vendorjob"
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        />

        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[10, 15, 20]}
                paginationMode="server"
                loading={isloading}
                rows={vendorJob?.data ? vendorJob?.data : rows}
                columns={columns}
                getRowId={(row) => row.id}
                rowCount={vendorJob?.data_count}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default VendorJobs;
