import React from 'react';
import Label from './Label';

const Status = (props) => {
  console.log('Statusabc', props);
  let content;

  if (props.status === 'Active') {
    content = (
      <Label
        sx={{
          backgroundColor: '#d1e7d9',
          color: '#035421',
          width: '111px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #035421',
          textTransform: 'uppercase',
        }}
      >
        Approved
      </Label>
    );
  } else if (props.status === 'Application Initiated') {
    content = (
      <Label
        sx={{
          backgroundColor: 'dbdaec',
          color: '#0B1782',
          width: '177px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #0B1782',
          textTransform: 'uppercase',
        }}
      >
        {props.status}
      </Label>
    );
  } else if (props.status === 'Under Process') {
    content = (
      <Label
        sx={{
          backgroundColor: 'rgba(248, 92, 30, 0.15)',
          color: '#E17A15',
          width: '121px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #D98634',
          textTransform: 'uppercase',
        }}
      >
        {props.status}
      </Label>
    );
  } else if (props.status === 'Renewal') {
    content = (
      <Label
        sx={{
          backgroundColor: '#f4daf8',
          color: '#B507D1',
          width: '111px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #B507D1',
          textTransform: 'uppercase',
        }}
      >
        {props.status}
      </Label>
    );
  } else if (props.status === 'Submit for Renewal') {
    content = <Label>{props.status}</Label>;
  } else if (props.status === 'Resubmit') {
    content = <Label variant="ghost">{props.status}</Label>;
  } else if (props.status === 'With-Hold') {
    content = (
      <Label
        variant="ghost"
        sx={{
          backgroundColor: 'rgba(255,0,0,0.15)',
          color: '#D0250E',
          width: '111px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #CC0D0D',
          textTransform: 'uppercase',
        }}
      >
        With-Hold
      </Label>
    );
  } else if (props.status === 'Delete') {
    content = (
      <Label
        variant="ghost"
        sx={{
          backgroundColor: '#f6d3cf',
          color: '#D0250E',
          width: '111px',
          height: '30px',
          borderRadious: '6px',
          border: '1.5px solid #CC0D0D',
          textTransform: 'uppercase',
        }}
      >
        Delete
      </Label>
    );
  } else {
    content = (
      <Label variant="ghost" color="error">
        {props.status}
      </Label>
    );
  }
  return <>{content}</>;
};

export default Status;
