import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Grid, Container, Typography, Divider } from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import moment from 'moment';
import PagesTopBar from '../../../Component/PagesTopBar';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import ShowMessage from '../../../Component/ShowMessage';
import StatusColumn2 from '../../../Component/DataGridComponents/StatusColomn2';
import { deleteVendorJob, getVendorJob } from '../../../Redux-Store/actions/recruitment/vendorJobActions';
import JobCategoryButton from '../../../Component/TableComponent/JobCategoryButton';

const VendorJobDetails = () => {
  const formik = useFormik({});
  const navigate = useNavigate();
  const { errors, touched, getFieldProps } = formik;
  const dispatch = useDispatch();
  const vendorJobFormData = useLocation();
  const vendorJobData = vendorJobFormData?.state?.vendorJobData;

  const [candidateId, setCandidateId] = useState();
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { vendorJobDetails, isloading } = useSelector((state) => ({
    vendorJobDetails: state.vendorJob?.vendorJobDetails?.data,
    isloading: state.vendorJob?.isLoading,
  }));
  console.log('vendorJobDetails', vendorJobDetails);
  useEffect(() => {
    dispatch(getVendorJob(vendorJobData?.uuid));
  }, []);

  console.log('SINGLE CANDIDATE DATA', vendorJobDetails?.data);
  const columns6 = [
    { field: 'Owner_name', headerName: 'Owner Name' },
    { field: 'Contact_number', headerName: 'Contact Number' },
    { field: 'email', headerName: 'Email' },
  ];
  // 1st  row
  const rows6 = [
    {
      id: 1,
      Owner_name: vendorJobDetails?.data?.Vendor?.firstname,
      Contact_number: vendorJobDetails?.data?.Vendor?.mobile_number,
      email: vendorJobDetails?.data?.Vendor?.email,
      // no_of_openings: vendorJobDetails?.data?.no_of_openings,
    },
  ];
  // 1st  column
  const columns = [
    { field: 'company_name', headerName: 'Company Name' },
    { field: 'education', headerName: 'Education' },
    { field: 'job_type', headerName: 'Job Type' },
    { field: 'no_of_openings', headerName: 'No of Openings' },
  ];
  // 1st  row
  const rows = [
    {
      id: 1,
      company_name: vendorJobDetails?.data?.company_name,
      education: vendorJobDetails?.data?.recruitmentEducation?.name,
      job_type: vendorJobDetails?.data?.job_type,
      no_of_openings: vendorJobDetails?.data?.no_of_openings,
    },
  ];

  // 2nd column
  const columns1 = [
    { field: 'job_min_experience', headerName: 'Minimum Experience' },
    { field: 'job_max_experience', headerName: 'Maximum Experience' },
    { field: 'job_min_salary', headerName: 'Minimum Salary' },
    { field: 'job_max_salary', headerName: 'Maximum Salary' },
  ];
  // 2nd row
  const rows1 = [
    {
      id: 2,
      job_min_experience: (
        <Typography fontSize="14px" fontWeight="600" color="green">
          {vendorJobDetails?.data?.job_min_experience} Years
        </Typography>
      ),
      job_max_experience: (
        <Typography fontSize="14px" fontWeight="600" color="green">
          {vendorJobDetails?.data?.job_max_experience} Years
        </Typography>
      ),
      job_min_salary: (
        <Typography fontSize="14px" fontWeight="600" color="green">
          ₹ {vendorJobDetails?.data?.job_min_salary}
        </Typography>
      ),
      job_max_salary: (
        <Typography fontSize="14px" fontWeight="600" color="green">
          ₹ {vendorJobDetails?.data?.job_max_salary}
        </Typography>
      ),
    },
  ];

  // 3rd column
  const columns2 = [
    { field: 'Job_status', headerName: 'Job Status' },
    { field: 'city', headerName: 'City' },
    { field: 'State', headerName: 'State' },
    { field: 'Country', headerName: 'Country' },
    // { field: 'CreatedOn', headerName: 'Created On' },
  ];
  // 3rd row
  const rows2 = [
    {
      id: 2,
      Job_status: vendorJobDetails?.data ? <StatusColumn2 params={vendorJobDetails?.data} /> : '---',
      city: vendorJobDetails?.data?.city,
      State: vendorJobDetails?.data?.state,
      Country: 'India',
      // Created_On: vendorJobDetails?.data?.createdAt
      //   ? new Date(vendorJobDetails?.data?.createdAt).toISOString().split('T')[0]
      //   : 'NA',
    },
  ];
  // 4th column
  const columns4 = [{ field: 'CreatedAt', headerName: 'Created At' }];
  // 3rd row
  const rows4 = [
    {
      id: 4,

      CreatedAt: vendorJobDetails?.data?.createdAt
        ? moment(vendorJobDetails?.data?.createdAt).format('DD/MM/YYYY hh:MM A')
        : 'NA',
    },
  ];
  const handleDelete = (e) => {
    console.log('Em', e.uuid);
    setCandidateId(e.uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setCandidateId(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteVendorJob({ uuid: vendorJobData?.uuid }))
      .unwrap()
      // .then((response) => {
      //   if (response.status === 200) {
      //     setIsShow(true);
      //     setErrorMessage('Vendor Job deleted successfully');
      //     setOpen(!open);
      //   }
      //   setTimeout(() => {
      //     setIsShow(false);
      //     navigate(-1);
      //   }, 1500);
      // })
      // .catch((error) => {
      //   console.log('error');
      // });
      .then((response) => {
        console.log('response in delete vendor', response);
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          console.log('response', response);
          setErrorMessage(response.data.message);
          // dispatch(getVendorJobs(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  return (
    <div>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Vendor Job"
          description="Are you sure you want to delete Job ?"
        />
      ) : null}
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          title=" Vendor Job Details"
          subTitle="Showing details of Vendor Job"
          btnText="Delete Vendor job"
          Delete
          handleDelete={handleDelete}
          hideSearch
        />
        {/* <Divider sx={{ mt: '10px' }} /> */}
        <Card sx={{ padding: '20px' }}>
          <FormikProvider value={formik}>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '28px',
                letterSpacing: '0.6500000953674316px',
                textAlign: 'left',
              }}
            >
              Basic Details
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '24px',
                textAlign: 'left',
              }}
            >
              It is showing vendor job details
            </Typography>
            {/* <img
              src={Line1}
              alt="line"
              style={{
                width: '660.01px',
                height: '0px',
                top: '297px',
                left: '415.99px',
                gap: '0px',
                border: '1px solid #7979791F', // border format: width style color
                // opacity: '0px',
              }}
            /> */}
            <Divider sx={{ mt: '10px' }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '200px' }}>
              <div>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '30px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                    marginTop: '20px',
                  }}
                >
                  {vendorJobDetails?.data?.job_title}
                </Typography>
              </div>
            </div>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                marginTop: '10px',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.6500000953674316px',
                textAlign: 'left',
              }}
            >
              Job Description
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '22.5px',
                textAlign: 'left',
              }}
            >
              {vendorJobDetails?.data?.job_description}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '0.6500000953674316px',
                textAlign: 'left',
                marginTop: '20px',
              }}
            >
              Job Category
            </Typography>
            {/* <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.6500000953674316px',
                textAlign: 'center',
                backgroundColor: '#d1e7d9',
                color: '#035421',
                borderRadius: '6px',
                border: '1.5px solid #035421',
                width: '153px',
              }}
            >
              {vendorJobDetails?.data?.recruitmentJobCategoryModel?.name}
            </Typography> */}
            <JobCategoryButton data={vendorJobDetails?.data?.recruitmentJobCategoryModel?.name} />
            <Divider sx={{ mt: '10px' }} />
            {/* owner details */}
            <Grid container spacing={2} style={{ marginTop: '20PX' }}>
              {rows6.map((row) => (
                <Grid item key={row.id} xs={12}>
                  <Grid container spacing={2}>
                    {columns6.map((column) => (
                      <Grid item xs={3} key={column.field}>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                          {column.headerName}:
                        </Typography>
                        <Typography variant="body1" component="div">
                          {row[column.field]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ mt: '10px' }} />
            {/* Row 1 */}
            <Grid container spacing={2} style={{ marginTop: '20PX' }}>
              {rows.map((row) => (
                <Grid item key={row.id} xs={12}>
                  <Grid container spacing={2}>
                    {columns.map((column) => (
                      <Grid item xs key={column.field}>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                          {column.headerName}:
                        </Typography>
                        <Typography variant="body1" component="div">
                          {row[column.field]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* Row 2 */}
            <Grid container spacing={2} style={{ marginTop: '20PX' }}>
              {rows1.map((row) => (
                <Grid item key={row.id} xs={12}>
                  <Grid container spacing={2}>
                    {columns1.map((column) => (
                      <Grid item xs key={column.field}>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                          {column.headerName}:
                        </Typography>
                        <Typography variant="body1" component="div">
                          {row[column.field]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {/* Row 3 */}
            <Grid container spacing={2} style={{ marginTop: '20PX' }}>
              {rows2.map((row) => (
                <Grid item key={row.id} xs={12}>
                  <Grid container spacing={2}>
                    {columns2.map((column) => (
                      <Grid item xs key={column.field} lg={3} sm={3}>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                          {column.headerName}:
                        </Typography>
                        <Typography variant="body1" component="div">
                          {row[column.field]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {/* Row 4 */}
            <Grid container spacing={2} style={{ marginTop: '20PX' }}>
              {rows4.map((row) => (
                <Grid item key={row.id} xs={12}>
                  <Grid container spacing={2}>
                    {columns4.map((column) => (
                      <Grid item xs key={column.field}>
                        <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                          {column.headerName}:
                        </Typography>
                        <Typography variant="body1" component="div">
                          {row[column.field]}
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </FormikProvider>
        </Card>
      </Container>
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </div>
  );
};

export default VendorJobDetails;
