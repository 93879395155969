import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Button, Divider, Grid, Menu, MenuItem, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';

import TabPanel from '@mui/lab/TabPanel';
import Page from '../../../components/Page';

import MatrimonyMasterEducation from '../education/MatrimonyMasterEducation';
import MaritalStatus from '../maritalstatus/MaritalStatus';
import MatrimonyMasterOccupation from '../occupation/MatrimonyMasterOccupation';
import MatrimonyMasterSubcast from '../subcast/MatrimonyMasterSubcast';
import MatrimonyMasterFamilyValue from '../family-value/MatrimonyMasterFamilyValue';
import MatrimonyMasterFamilyType from '../family-type/MatrimonyMasterFamilyType';
import MatrimonyMasterAnnualIncome from '../annual-income/MatrimonyMasterAnnualIncome';
import MatrimonyMasterDrinkingHabits from '../drinking-habits/MatrimonyMasterDrinkingHabits';
import MatrimonyMasterSmokingHabits from '../smoking-habits/MatrimonyMasterSmokingHabits';
import MatrimonyMasterEmploymentType from '../employment-type/MatrimonyMasterEmploymentType';
import MatrimonyMasterLanguages from '../languages/MatrimonyMasterLanguages';
import MatrimonyMasterEatingHabits from '../eating-habits/MatrimonyMasterEatingHabits';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

export default function MatrimonyMaster() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log('ststeinm', state);
  const [value, setValue] = React.useState('1');
  // localStorage.setItem('tabValueMtrimony', value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('tabValueMtrimony', newValue);
  };

  const saveTabValue = (newValue) => {
    localStorage.setItem('tabValueMtrimony', newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState();
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // ------------Handle add---------------
  const handleAddMasterData = (tabIndex, navigationURL) => {
    console.log('HANDLE ADD CALLED');
    try {
      setAnchorEl(null);
      saveTabValue(tabIndex);
      navigate(navigationURL);
    } catch (error) {
      console.error('Error navigating:', error);
    }
  };

  const getTabValue = () => {
    const persistValue = localStorage.getItem('tabValueMtrimony');
    console.log('persistValue', persistValue);
    if (persistValue !== null) {
      setValue(persistValue);
    }
  };

  useEffect(() => {
    getTabValue();
    if (state && state.tab) {
      setValue(state.tab);
    }
  }, [state]);

  const masterDataMenuItem = [
    {
      id: '1',
      name: 'Marital Status',
      navigationURL: '/dashboard/matrimony/master/create-marital-status',
    },
    {
      id: '2',
      name: 'Education',
      navigationURL: '/dashboard/matrimony/master/create-education',
    },
    {
      id: '3',
      name: 'Occupation',
      navigationURL: '/dashboard/matrimony/master/create-occupation',
    },
    {
      id: '4',
      name: 'Languages',
      navigationURL: '/dashboard/matrimony/master/create-languages',
    },
    {
      id: '5',
      name: 'Sub Caste',
      navigationURL: '/dashboard/matrimony/master/create-subcast',
    },
    {
      id: '6',
      name: 'Family Value',
      navigationURL: '/dashboard/matrimony/master/create-family-value',
    },
    {
      id: '7',
      name: 'Family Type',
      navigationURL: '/dashboard/matrimony/master/create-family-types',
    },
    {
      id: '8',
      name: 'Annual Income',
      navigationURL: '/dashboard/matrimony/master/create-annual-income',
    },
    {
      id: '9',
      name: 'Drinking Habits',
      navigationURL: '/dashboard/matrimony/master/create-drinking-habits',
    },
    {
      id: '10',
      name: 'Smoking Habits',
      navigationURL: '/dashboard/matrimony/master/create-smoking-habits',
    },
    {
      id: '11',
      name: 'Diet Type',
      navigationURL: '/dashboard/matrimony/master/create-eating-habits',
    },
    {
      id: '12',
      name: 'Employment Type',
      navigationURL: '/dashboard/matrimony/master/create-employment-type',
    },
  ];

  return (
    <RootStyle>
      <Page title="Master(Recruitment)" sx={{ width: '100%' }}>
        <Grid container md={12} sx={{ px: 4 }}>
          <Grid
            container
            sx={{ marginBottom: '15px', display: 'flex', alignItem: 'center', justifyContent: 'space-between' }}
          >
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={25}>
                Master <span style={{ fontSize: '20px' }}> (Matrimony)</span>
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Master data of Matrimony.
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="error"
                size="small"
                disableElevation
                endIcon={<ArrowDropDownCircleOutlinedIcon />}
                sx={{
                  padding: '10px 15px',
                  bgcolor: '#0B8238',
                  fontSize: '14px',
                  '&:hover': {
                    background: '#0B8238',
                    opacity: 0.9,
                  },
                }}
                id="basic-menu-button"
                aria-controls={openMenu ? 'basic-menu-popup' : undefined}
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleClick}
              >
                Actions
              </Button>
            </Grid>
          </Grid>

          <Menu id="basic-menu-popup" open={openMenu} onClose={handleCloseMenu} anchorEl={anchorEl}>
            {masterDataMenuItem?.map((value, index) => {
              return (
                <>
                  {index !== 0 ? <Divider /> : null}
                  <MenuItem
                    onClick={() => handleAddMasterData(value.id, value.navigationURL)}
                    sx={{ fontSize: '13px' }}
                  >
                    Add {value.name}
                  </MenuItem>
                </>
              );
            })}
          </Menu>

          <Grid md={12}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  // sx={{ mt: '-18px' }}
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                >
                  {masterDataMenuItem.map((value, index) => (
                    <Tab label={value.name} value={value.id} />
                  ))}
                </TabList>
              </Box>

              <Grid item md={12}>
                <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                  <MaritalStatus />
                </TabPanel>
                <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterEducation />
                </TabPanel>
                <TabPanel value="3" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterOccupation />
                </TabPanel>
                <TabPanel value="4" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterLanguages />
                </TabPanel>
                <TabPanel value="5" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterSubcast />
                </TabPanel>
                <TabPanel value="6" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterFamilyValue />
                </TabPanel>
                <TabPanel value="7" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterFamilyType />
                </TabPanel>
                <TabPanel value="8" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterAnnualIncome />
                </TabPanel>
                <TabPanel value="9" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterDrinkingHabits />
                </TabPanel>
                <TabPanel value="10" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterSmokingHabits />
                </TabPanel>
                <TabPanel value="11" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterEatingHabits />
                </TabPanel>
                <TabPanel value="12" sx={{ m: 0, p: 0 }}>
                  <MatrimonyMasterEmploymentType />
                </TabPanel>
              </Grid>
            </TabContext>
          </Grid>
        </Grid>
      </Page>
    </RootStyle>
  );
}
