import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Line1 from '../../../Assets/Line1.jpg';
import PagesTopBar from '../../../Component/PagesTopBar';
import Linkedin from '../../../Assets/Linkedin.png';
import Rectangle from '../../../Assets/Rectangle.png';

import {
  deleteCandidate,
  downloadResume,
  getCandidate,
} from '../../../Redux-Store/actions/recruitment/candidateActions';
import { fDate, fDateTime } from '../../../utils/formatTime';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import ShowMessage from '../../../Component/ShowMessage';

const CreateCandidateDetails = () => {
  const formik = useFormik({});
  const navigate = useNavigate();
  const { errors, touched, getFieldProps } = formik;
  const dispatch = useDispatch();
  const candidateFormData = useLocation();
  const candidateData = candidateFormData?.state?.candidateData;

  const [candidateId, setCandidateId] = useState();
  console.log('candidateData', candidateData);
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { singleCandidateData, isloading } = useSelector((state) => ({
    singleCandidateData: state.candidates?.singleCandidateData?.data,
    isloading: state.candidates?.isLoading,
  }));

  console.log('reLoading', isloading);

  useEffect(() => {
    dispatch(getCandidate(candidateData?.uuid));
  }, []);

  console.log('SINGLE CANDIDATE DATA', singleCandidateData);
  const columns = [
    { field: 'mobile_number', headerName: 'Phone' },
    { field: 'email', headerName: 'Email' },
    { field: 'date_of_birth', headerName: 'DOB' },
    { field: 'gender', headerName: 'Gender' },
  ];
  const rows = [
    {
      id: 1,
      mobile_number: singleCandidateData?.data?.mobile_number,
      email: singleCandidateData?.data?.email_address,
      gender: singleCandidateData?.data?.gender,
      date_of_birth: singleCandidateData?.data?.date_of_birth ? fDate(singleCandidateData?.data?.date_of_birth) : 'NA',
    },
  ];
  const columns1 = [{ field: 'address', headerName: 'Address' }];
  const rows1 = [
    {
      id: 1,
      address: singleCandidateData?.data?.address,
    },
  ];
  const columns2 = [
    { field: 'Work_Experience', headerName: 'Work Experience' },
    { field: 'State', headerName: 'State' },
    { field: 'Country', headerName: 'Country' },
    { field: 'Created_On', headerName: 'Created On' },
  ];
  const rows2 = [
    {
      id: 1,
      Work_Experience: singleCandidateData?.data?.work_experience,
      State: singleCandidateData?.data?.state,
      Country: 'India',
      Created_On: singleCandidateData?.data?.createdAt ? fDateTime(singleCandidateData?.data?.createdAt) : 'NA',
    },
  ];
  const styles = {
    card: {
      // width: 656,
      height: 160,
      // margin: '10px',
      marginTop: '20px',
      padding: '20px',
    },
    languageContainer: {
      display: 'flex',
      gap: '20px',
      marginTop: 10,
    },
    language: {
      padding: '5px 10px',
      borderRadius: '15px',
      backgroundColor: '#e8e4e4',
    },
  };
  const styles1 = {
    card: {
      // width: 656,
      height: 230,
      // margin: '10px',
      marginTop: '20px',
      padding: '20px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
    },
  };

  const handleDownloadResume = () => {
    if (candidateData.resume_link !== '') {
      const link = document.createElement('a');
      link.href = candidateData.resume_link;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setErrorMessage('Resume not found');
      setIsShow(true);
      setTimeout(() => {
        setIsShow(false);
        // navigate(-1);
      }, 1500);
    }

    // dispatch(downloadResume('388b71e1-f029-4aa3-98b3-7f79052e8bf8'));
  };

  const handleOpenLinkedin = () => {
    if (candidateData.linked_in_profile_link !== '') {
      const link = document.createElement('a');
      link.href = candidateData.linked_in_profile_link;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setErrorMessage('Link not found');
      setIsShow(true);
      setTimeout(() => {
        setIsShow(false);
        // navigate(-1);
      }, 1500);
    }
  };

  const handleDelete = (e) => {
    // console.log('eeee6567', e);
    // setCandidateId(e.target.value);
    setOpen(!open);
  };
  const handleDeleteCancle = () => {
    // setCandidateId(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteCandidate({ uuid: singleCandidateData?.data?.uuid }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setErrorMessage('candidate deleted successfully');
          setOpen(!open);
        }
        setTimeout(() => {
          setIsShow(false);
          navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  return (
    <div>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Candidate"
          description="Are you sure you want to delete Candidate ?"
        />
      ) : null}
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <PagesTopBar
          title=" Candidate Details"
          subTitle="Showing details of candidate"
          btnText="Delete Profile"
          Delete
          handleDelete={handleDelete}
          hideSearch
        />

        {isloading ? (
          <Box sx={{ height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Card sx={{ padding: '20px' }}>
              <FormikProvider value={formik}>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                  }}
                >
                  Basic Details
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    textAlign: 'left',
                  }}
                >
                  It is showing your candidate details
                </Typography>
                <img
                  src={Line1}
                  alt="line"
                  style={{
                    width: '660.01px',
                    height: '0px',
                    top: '297px',
                    left: '415.99px',
                    gap: '0px',
                    border: '1px solid #7979791F', // border format: width style color
                    // opacity: '0px',
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '200px' }}>
                  <div>
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '30px',
                        fontWeight: 700,
                        lineHeight: '24px',
                        letterSpacing: '0.6500000953674316px',
                        textAlign: 'left',
                        marginTop: '20px',
                      }}
                    >
                      {singleCandidateData?.data?.first_name} {singleCandidateData?.data?.last_name}
                    </Typography>
                  </div>
                  <div>
                    <img
                      src={
                        singleCandidateData?.data?.profile_photo_link
                          ? singleCandidateData?.data?.profile_photo_link
                          : Rectangle
                      }
                      alt="Rectangle"
                      style={{
                        width: '100px',
                        height: '100px',
                        gap: '0px',
                        borderRadius: '50%',
                        // borderRadius: '47.5px 0px 0px 0px',
                      }}
                    />
                  </div>
                </div>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                  }}
                >
                  About Me
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '15px',
                    fontWeight: 500,
                    lineHeight: '22.5px',
                    textAlign: 'left',
                  }}
                >
                  {singleCandidateData?.data?.profile_description}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.6500000953674316px',
                    textAlign: 'left',
                    marginTop: '20px',
                  }}
                >
                  Job Category
                </Typography>
                <Typography
                  sx={{
                    width: 'max-content',
                    borderRadius: '6px',
                    padding: '5px 10px',
                    bgcolor: '#0B8238',
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '400',
                    '&:hover': {
                      background: '#0B8238',
                      opacity: 0.9,
                    },
                  }}
                >
                  {singleCandidateData?.data?.recruitmentJobCategoryModel?.name}
                </Typography>
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows1.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns1.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '20PX' }}>
                  {rows2.map((row) => (
                    <Grid item key={row.id} xs={12}>
                      <Grid container spacing={2}>
                        {columns2.map((column) => (
                          <Grid item xs key={column.field}>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 700 }}>
                              {column.headerName}:
                            </Typography>
                            <Typography variant="body1" component="div">
                              {row[column.field]}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </FormikProvider>
            </Card>

            <Grid container spacing={2}>
              <Grid lg={6} xs={12} sm={12} item>
                <Card sx={styles.card}>
                  <h3>Languages Known</h3>
                  <p>The list of languages known by the candidate</p>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <div style={styles.languageContainer}>
                    {singleCandidateData?.data?.recruitmentCandidateLanguageModels?.map((value, index) => {
                      return <p style={{ ...styles.language }}>{value?.recruitmentLanguagesModel?.name}</p>;
                    })}
                  </div>
                </Card>
              </Grid>
              <Grid item lg={6} xs={12} sm={12}>
                <Card sx={styles.card}>
                  <h3>Skills</h3>
                  <p>The list of skills that candidate have</p>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <div style={styles.languageContainer}>
                    {singleCandidateData?.data?.recruitmentCandidateSkillsModels?.map((value, index) => {
                      return <p style={{ ...styles.language }}>{value?.recruitmentJobSkillsModel?.name}</p>;
                    })}
                  </div>
                </Card>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid lg={6} xs={12} sm={12} item>
                <Card sx={styles1.card}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>Linkedin Profile</h3>
                    <img
                      src={Linkedin}
                      alt="Linkedin"
                      style={{
                        width: '40px',
                        height: '36px',
                        top: '1049px',
                        left: '827px',
                      }}
                    />
                  </div>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />

                  <p style={{ marginTop: '20px', marginBottom: '10px' }}>
                    You can visit candidate linked in profile from here tab to below button to open linked in profile of
                    candidate.{' '}
                  </p>
                  {candidateData.linked_in_profile_link && candidateData.linked_in_profile_link.includes('https://') ? (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        textAlign: 'center',
                        backgroundColor: '#02742D',
                        borderRadius: '10px',
                        border: '1.4px solid #0B8238',
                        width: 'max-content',
                        color: '#FFFFFF',
                        p: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleOpenLinkedin()}
                    >
                      Open LinkedIn Profile
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 600,
                        textAlign: 'center',
                        backgroundColor: '#CCCCCC',
                        borderRadius: '10px',
                        border: '1.4px solid #AAAAAA',
                        width: 'max-content',
                        color: '#666666',
                        p: '10px',
                      }}
                    >
                      LinkedIn Profile Unavailable
                    </Typography>
                  )}
                </Card>
              </Grid>
              <Grid item lg={6} xs={12} sm={12}>
                <Card sx={styles1.card}>
                  <h3>Download Resume</h3>
                  <img
                    src={Line1}
                    alt="line"
                    style={{
                      width: '660.01px',
                      height: '0px',
                      top: '297px',
                      left: '415.99px',
                      gap: '0px',
                      border: '1px solid #7979791F', // border format: width style color
                      opacity: '0px',
                    }}
                  />
                  <p style={{ marginTop: '20px', marginBottom: '10px' }}>
                    You can download candidate resume just tap on below download button it will download.
                  </p>
                  <Typography
                    sx={{
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 600,
                      textAlign: 'center',
                      backgroundColor: '#02742D',
                      borderRadius: '10px',
                      border: '1.4px solid #0B8238',
                      width: '181px',
                      // height: '39px',
                      color: '#FFFFFF',
                      p: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleDownloadResume()}
                  >
                    Download Resume
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </>
        )}
      </Container>

      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="error" open={isShow} />
    </div>
  );
};

export default CreateCandidateDetails;
