import React, { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { addReviewer, updateReviewer } from '../../../Redux-Store/actions/reviewerActions';
import ShowMessage from '../../../Component/ShowMessage';

const CreateCandidate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const candidateFormData = useLocation();
  const candidateData = candidateFormData?.state?.candidateData;
  console.log('candidateData', candidateData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special charators not allowed')
      .required('Name is required')
      .min(2, 'First Name should not less than 2 characters')
      .max(30, 'First Name should not  more than 30 characters'),

      Job_Catagory: Yup.string()
      .required('Job Catagory is required')
      .max(30, 'Job Catagory should not  more than 30 characters'),

      experience: Yup.string()
      .required('Experience is required')
      .matches(/^\d{1,2}$/, 'Experience must be exactly 2 digits'),

      gender: Yup.string()
      .required('Gender is required'),

       email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email format')
      .matches(/(\.com|\.in)$/, 'Email must end with ".com" or ".in"')
      .required('Email is required'),

      contactnumber: Yup.string()
      .required('Contact Number is required')
      .test(
        'is-10-digits',
        'Phone number must be valid 10 digits',
        (value) => /^[1-9]{1}\d{9}$/.test(value) && !/^[0]{2,}/.test(value)
      ),
      education: Yup.string()
      .required('Education is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: candidateData ? candidateData.name : '',
      experience: candidateData ? candidateData.experience : '',
      email: candidateData ? candidateData.email : '',
      contactnumber: candidateData ? candidateData.contact_number_number : '',
      gender: candidateData ? candidateData.gender : '',
      education: candidateData ? candidateData.education : '',
      Job_Catagory: candidateData ? candidateData.Job_Catagory : '',
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = candidateData
           ? {
            uuid: candidateData.uuid,
            name: value.name,
            Job_Catagory: value.Job_Catagory,
            experience: value.experience,
            gender: value.gender,
            email: value.email,
            contactnumber: value.contactnumber,
            education: value.education,
          }
        : {
            name: value.name,
            Job_Catagory: value.Job_Catagory,
            experience: value.experience,
            gender: value.gender,
            email: value.email,
            contactnumber: value.contactnumber,
            education: value.education,
          };
      if (candidateData?.uuid) {
        dispatch(updateReviewer(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update reviewer', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addReviewer(params))
          .unwrap()
          .then((response) => {
            console.log('response of add reviewer', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Typography variant="h6" fontWeight={'600'}>
          {candidateData ? ' Update Candidate' : ' Create Candidate'}
        </Typography>
        <Typography fontSize={14}>Enter your Candidate details</Typography>

        <Divider sx={{ mt: '10px' }} />
        <Card sx={{ padding: '20px', paddingRight:'80px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Stack spacing={3} sx={{ mt: 3 }}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="name"
                      label="Name"
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="Job_Catagory"
                      label="Job Catagory"
                      {...getFieldProps('Job_Catagory')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.Job_Catagory && errors.Job_Catagory)}
                      helperText={touched.Job_Catagory && errors.Job_Catagory}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="experience"
                      type="text"
                      label="Experience"
                      {...getFieldProps('experience')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.experience && errors.experience)}
                      helperText={touched.experience && errors.experience}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="gender"
                      type="text"
                      label="Gender"
                      select
                      {...getFieldProps('gender')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="email"
                      type="text"
                      label="Email"
                      {...getFieldProps('email')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="contactnumber"
                      type="text"
                      label="Contact Number"
                      {...getFieldProps('contactnumber')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.contactnumber && errors.contactnumber)}
                      helperText={touched.contactnumber && errors.contactnumber}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      autoComplete="education"
                      type="text"
                      label="Education"
                      {...getFieldProps('education')}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" />,
                      }}
                      error={Boolean(touched.education && errors.education)}
                      helperText={touched.education && errors.education}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      //   loading={submitLoader && true}
                      size="large"
                      type="submit"
                      variant="contained"
                      style={{ float: 'right' }}
                    >
                      {candidateData ? 'Update' : ' Submit'}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={5} />
                </Grid>
              </Stack>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateCandidate;
