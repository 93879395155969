import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Stack, Grid, TextField, Container, Typography, InputAdornment, Divider, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowMessage from '../../Component/ShowMessage';
import { addSubcription, updateSubcription } from '../../Redux-Store/actions/subscriptionAction';

const CreateSubscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionFormData = useLocation();
  const subscriptionData = subscriptionFormData?.state?.subscriptionData;
  console.log('subscriptionData', subscriptionData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedPlanType, setSelectedPlanType] = useState('');
  const [noOfTotalLocation, setNoOfTotalLocation] = useState('');
  const [subscriptionType, setSubscriptionType] = useState('');
  // console.log('subscriptionType', subscriptionType);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: 1,
      },
    },
    textarea: {
      resize: 'both',
    },
  }));
  const classes = useStyles();
  const planType = [{ id: 1, name: 'Yearly' }];
  const noOfLocation = [
    { id: '1', location: '1', label: '1' },
    { id: '2', location: '2', label: '2' },
  ];
  const subscription = [
    { id: '1', value: 'vendor_recruitment_subscription', label: 'Vendor recruitment subscription' },
    { id: '2', value: 'consumer_matrimony_subscription', label: 'Consumer matrimony subscription' },
  ];
  const handlePlanType = (e) => {
    setSelectedPlanType(e.target.value);
  };

  const handleNoOfLocation = (e) => {
    setNoOfTotalLocation(e.target.value);
  };
  const handleSubscriptionTypeChnge = (e) => {
    setSubscriptionType(e.target.value);
  };

  const setDefaultData = () => {
    if (subscriptionData) {
      setNoOfTotalLocation(subscriptionData.no_of_locations);
      setSelectedPlanType(subscriptionData.plan_type);
      setSubscriptionType(subscriptionData.subscription_type);
    }
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const LoginSchema = Yup.object().shape({
    title: Yup.string()
      .matches(/^([^0-9]*)$/, 'number is not allowed')
      .required('Plan name is required')
      .min(2, 'Title should not less than 2 characters')
      .max(30, 'Title should  not more than 30 characters'),

    price: Yup.number()
      .positive('')
      .required(' Plan price is required')
      .test(
        'is-decimal',
        'The amount should be a decimal with maximum two digits after dot',

        (val) => {
          if (val !== undefined) {
            return patternTwoDigisAfterComma.test(val);
          }
          return true;
        }
      )
      .min(1, ' Plan price should not be less than one')

      .typeError('Plan rice must be a valid number'),
    type: Yup.string().required('Plan type is required'),

    subscriptionType: Yup.string().required('Subscription Type is required'),
    // locationtype: Yup.string().when('condition', {
    //   is: true,
    //   then: Yup.string().required('No. of Location is required'),
    //   otherwise: Yup.string(),
    // }),
    locationtype:
      subscriptionType === 'vendor_recruitment_subscription'
        ? Yup.string().required('No. of Location is required')
        : Yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: subscriptionData ? subscriptionData.plan_title : '',
      description: subscriptionData ? subscriptionData.plan_description : '',
      type: subscriptionData ? subscriptionData.plan_type : '',
      price: subscriptionData ? subscriptionData.plan_price : '',
      locationtype: subscriptionData ? subscriptionData.no_of_locations : '',
      subscriptionType: subscriptionData ? subscriptionData.subscription_type : '',
      // currency: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      setSubmitLoader(true);
      const params = subscriptionData
        ? {
            uuid: subscriptionData.uuid,
            plan_title: value.title,
            plan_description: value.description,
            plan_type: value.type,
            no_of_locations: subscriptionType === 'consumer_matrimony_subscription' ? 1 : value.locationtype,
            plan_price: value.price,
            subscription_type: value.subscriptionType,
          }
        : {
            plan_title: value.title,
            plan_description: value.description,
            plan_type: value.type,
            no_of_locations: subscriptionType === 'consumer_matrimony_subscription' ? 1 : value.locationtype,
            plan_price: value.price,
            subscription_type: value.subscriptionType,
          };
      if (subscriptionData?.uuid) {
        dispatch(updateSubcription(params))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update subscription', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1000);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addSubcription(params))
          .unwrap()
          .then((response) => {
            console.log('response of add reviewer', response);
            if (response.status === 200) {
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorMessage(response.data.message);
            }
            setTimeout(() => {
              setIsShowUpdate(false);
              navigate(-1);
            }, 1500);
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });
  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Typography variant="h6" fontWeight={'600'}>
          {subscriptionData ? ' Update Subscription' : ' Create Subscription'}
        </Typography>
        <Typography fontSize={14}>Enter new subscription plan details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ padding: '20px', marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={2} sx={{ padding: '20px' }}>
                <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                  <TextField
                    select
                    fullWidth
                    id="type"
                    name="subscriptionType"
                    label="Subscription Type *"
                    defaultValue=""
                    initialValues=""
                    style={{ marginTop: '7px' }}
                    a
                    value={subscriptionType}
                    onChange={(e) => {
                      // handleNoOfLocation(e);
                      handleSubscriptionTypeChnge(e);
                      formik.handleChange(e);
                    }}
                    error={formik.touched.subscriptionType && Boolean(formik.errors.subscriptionType)}
                    helperText={formik.touched.subscriptionType && formik.errors.subscriptionType}
                  >
                    <MenuItem disabled initialValues value="">
                      <em>Subscription Type</em>
                    </MenuItem>
                    {subscription?.map((option) => (
                      <MenuItem key={option.id} value={option.value} initialValues="">
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="title"
                    label="Plan Name *"
                    {...getFieldProps('title')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="description"
                    label="Description"
                    {...getFieldProps('description')}
                    inputProps={{ className: classes.textarea }}
                    multiline
                    rows={1}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                  <TextField
                    select
                    fullWidth
                    id="type"
                    name="type"
                    label="Plan Type *"
                    defaultValue=""
                    initialValues=""
                    style={{ marginTop: '7px' }}
                    value={selectedPlanType}
                    onChange={(e) => {
                      handlePlanType(e);
                      formik.handleChange(e);
                    }}
                    error={formik.touched.type && Boolean(formik.errors.type)}
                    helperText={formik.touched.type && formik.errors.type}
                  >
                    <MenuItem disabled initialValues value="">
                      <em>Plan Type</em>
                    </MenuItem>
                    {planType?.map((option) => (
                      <MenuItem key={option.id} value={option.name} initialValues="">
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {subscriptionType === 'vendor_recruitment_subscription' && (
                  <Grid item lg={6} md={6} xs={12} sx={{ mt: -1 }}>
                    <TextField
                      select
                      fullWidth
                      id="type"
                      name="locationtype"
                      label="No. of Location *"
                      defaultValue=""
                      initialValues=""
                      style={{ marginTop: '7px' }}
                      value={noOfTotalLocation}
                      onChange={(e) => {
                        handleNoOfLocation(e);
                        formik.handleChange(e);
                      }}
                      error={formik.touched.locationtype && Boolean(formik.errors.locationtype)}
                      helperText={formik.touched.locationtype && formik.errors.locationtype}
                    >
                      <MenuItem disabled initialValues value="">
                        <em>No. of Location</em>
                      </MenuItem>
                      {noOfLocation?.map((option) => (
                        <MenuItem key={option.id} value={option.location} initialValues="">
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="price"
                    label="Plan Price *"
                    {...getFieldProps('price')}
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                  />
                </Grid>

                {/* <Grid item xs={6} /> */}
                <Grid item xs={12}>
                  <LoadingButton
                    loading={submitLoader && true}
                    size="medium"
                    type="submit"
                    variant="contained"
                    sx={{ float: 'right', padding: '10px 20px', '&:hover': { bgcolor: 'green' } }}
                  >
                    {subscriptionData ? 'Update Subscription' : 'Create Subscription'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateSubscription;
