// @mui
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
// sections
import { LoginForm } from '../sections/auth/login';
import backgroundimage from '../Assets/bg.png';
import loginTop from '../Assets/loginLogoNew.png';
import loginMiddle from '../Assets/loginMiddle.png';
import footerIcon from '../Assets/sadhuImage.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  height: '100vh',
  backgroundColor: '#efefef',
}));

const SectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundImage: `url(${backgroundimage})`,
  height: '100vh',
  backgroundSize: 'cover',
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive('up', 'md');
  const navigate = useNavigate();

  useEffect(() => {
    const isAuth = JSON.parse(localStorage.getItem('dial4vyapar_state'));
    if (!isAuth?.auth?.isLogged || isAuth === null) {
      navigate('/login');
    } else {
      navigate('/dashboard/app');
    }
  }, []);
  return (
    <Grid container>
      <Grid item md={6}>
        {mdUp && (
          <SectionStyle>
            <Box
              sx={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: '30px',
                paddingTop: '20px',
                paddingBottom: '20px',
                flexDirection: 'column',
              }}
            >
              <img src={`${loginTop}`} alt="login" width={'130px'} />

              <Box>
                <Typography sx={{ fontSize: '22px', fontWeight: '400', color: '#FFFFFF' }}> Welcome Back,</Typography>
                <img src={`${loginMiddle}`} alt="login" height="140px" style={{ marginLeft: '-10px' }} />
                <Typography sx={{ fontSize: '18px', fontWeight: '200', color: '#FFFFFF' }}>
                  Your Business Ultimate Growth Partner
                </Typography>
              </Box>

              <span>
                <img src={footerIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
                <Box height={'5px'} />
                <p style={{ fontSize: '14px', fontWeight: '300', color: 'rgba(255,255,255,0.8)' }}>
                  In association with
                </p>
                <b style={{ fontSize: '18px', fontWeight: '600', color: '#FFFFFF' }}>
                  International Sindhi Samaj Sangathan
                </b>
                <Box height={'10px'} />
              </span>
            </Box>
          </SectionStyle>
        )}
      </Grid>

      <Grid item md={4}>
        <LoginForm />
      </Grid>
    </Grid>
  );
}
