import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, Container, Typography, IconButton, InputAdornment, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';

// component
import Iconify from '../../components/Iconify';
import apis from '../apis/apis';
import Page from '../../components/Page';
// HOOKS
import useResponsive from '../../hooks/useResponsive';

import backgroundimage from '../../Assets/bg.png';
import loginTop from '../../Assets/logintop.png';
import loginMiddle from '../../Assets/loginMiddle.png';
import footerIcon from '../../Assets/sadhuImage.png';
import ShowMessage from '../../Component/ShowMessage';

const SectionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  backgroundImage: `url(${backgroundimage})`,
  height: '100vh',
  backgroundSize: 'cover',
}));

// ----------------------------------------------------------------------
const ResetPasswordSectionStyle = styled('div')(({ theme }) => ({
  height: '100vh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(0, 0, 0, 0),
  borderRadius: 33,
  position: 'relative',
  alignItems: 'center',
}));

const ResetPasswordSubSection = styled('div')(({ theme }) => ({
  width: '100%',
  height: 'max-content',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '100px',
}));

export default function ResetPassword() {
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [uuid, setuuid] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [isValidUUID, setIsValidUUID] = useState(true);

  useEffect(() => {
    // Get the UUID value from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const uuid = urlParams.get('uuid');
    if(isUUID(uuid)){
      setIsValidUUID(true)
      setuuid(uuid);
    }
    else {
      setIsShow(true)
      setIsValidUUID(false)
      setErrorMessage("UUID is invalid")
      setError(true);
      setTimeout(() => {
        setIsShow(false);
      }, 1500);
    }
  }, []);

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .min(8, 'New password must be at least 8 characters long.')
      .matches(/^(?=.*[a-z])/, 'Password must contain at least one lowercase letter.')
      .matches(/^(?=.*[A-Z])/, 'Password must contain at least one uppercase letter.')
      .matches(/^(?=.*\d)/, 'Password must contain at least one numeric value.')
      .matches(/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/, 'Password must contain at least one special character.'),
    confirmpassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmpassword: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      if (uuid) {
        setSubmitLoader(true);
        const params = {
          uuid,
          password: value.password,
          confirm_password: value.confirmpassword,
        };
        apis
          .resetPassword(params)
          .then((response) => {
            if (response?.data?.status === 200) {
              setIsShow(true);
              setError(false);
              setSubmitLoader(false);
              setErrorMessage(response.data.message);
              setTimeout(() => {
                setIsShow(false);
                navigate('/dashboard', { replace: true });
              }, 1500);
            } else {
              setIsShow(true);
              setError(true);
              setErrorMessage(response.data.message);
              setTimeout(() => {
                setIsShow(false);
                setSubmitLoader(false);
                // navigate('/dashboard/app', { replace: true });
              }, 2500);
            }
          })
          .catch((err) => {
            setSubmitLoader(false);
          });
      } else {
        setIsShow(true);
        setError(true);
        setErrorMessage('Please use authorised link for set password.');
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  function isUUID(str) {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(str);
  }

  function resetPasswordFrom() {
    return (
      <Container maxWidth="sm">
        <ResetPasswordSectionStyle>
          <ResetPasswordSubSection>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" style={{ fontSize: 20, marginTop: 20 }}>
                  Reset Password
                </Typography>
                <Typography style={{ fontSize: 15, marginBottom: 15 }}>Enter your details below.</Typography>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <TextField
                        fullWidth
                        label={'New Password'}
                        placeholder="Enter New Password"
                        type={showPassword ? 'text' : 'password'}
                        // label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />

                      <TextField
                        fullWidth
                        label={'Confirm Password'}
                        placeholder="Enter Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                        // label="Confirm Password"
                        {...getFieldProps('confirmpassword')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowConfirmPassword} edge="end">
                                <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                        helperText={touched.confirmpassword && errors.confirmpassword}
                      />
                    </Stack>
                    <Stack sx={{ mt: 2 }}>
                      <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={submitLoader}
                        sx={{
                          '&:hover': {
                            bgcolor: 'green',
                          },
                        }}
                        style={{ borderRadius: 29.5, height: 46 }}
                      >
                        Set Password
                      </LoadingButton>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Grid>
            </Grid>
          </ResetPasswordSubSection>
        </ResetPasswordSectionStyle>
      </Container>
    );
  }

  return (
    <>
      <Page title="Reset Password">
        <Grid container>
          <Grid item md={6}>
            {mdUp && (
              <SectionStyle>
                <Box
                  sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginLeft: '30px',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    flexDirection: 'column',
                  }}
                >
                  <img src={`${loginTop}`} alt="login" width={'130px'} />

                  <Box>
                    <Typography sx={{ fontSize: '22px', fontWeight: '400', color: '#FFFFFF' }}>
                      {' '}
                      Welcome Back,
                    </Typography>
                    <img src={`${loginMiddle}`} alt="login" height="140px" style={{ marginLeft: '-10px' }} />
                    <Typography sx={{ fontSize: '18px', fontWeight: '200', color: '#FFFFFF' }}>
                      Your Business Ultimate Growth Partner
                    </Typography>
                  </Box>

                  <span>
                    <img src={footerIcon} alt="logo" style={{ width: '60px', height: '60px' }} />
                    <Box height={'5px'} />
                    <p style={{ fontSize: '14px', fontWeight: '300', color: 'rgba(255,255,255,0.8)' }}>
                      In association with
                    </p>
                    <b style={{ fontSize: '18px', fontWeight: '600', color: '#FFFFFF' }}>
                      International Sindhi Samaj Sangathan
                    </b>
                    <Box height={'10px'} />
                  </span>
                </Box>
              </SectionStyle>
            )}
          </Grid>

          <Grid item md={4}>
            {isValidUUID?
            resetPasswordFrom():
              null
            }
          </Grid>
        </Grid>
        <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={error ? 'error' : 'success'}
          open={isShow}
        />
      </Page>
    </>
  );
}
