import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

// Get Category
export const getCategories = createAsyncThunk(actionTypes.GET_CATEGORIES, async (params, thunkAPI) => {
  const url = params?.filterData
    ? `admin/categories?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
    : `/admin/categories?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;

  try {
    const response = await axiosInstance.get(url);
    console.log('response of categories', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Add Category
export const addCategories = createAsyncThunk(
  actionTypes.ADD_CATEGORIES,

  async (params, thunkAPI) => {
    console.log('paramshghgf', params);
    try {
      const response = await axiosInstance.post(`/admin/categories`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('response of add Category', response);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// Update categories
export const updateCategory = createAsyncThunk(actionTypes.UPDATE_CATEGORIES, async (params, thunkAPI) => {
  console.log('params in update', params);
  try {
    const response = await axiosInstance.patch(`/admin/categories`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response Category update', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

// Delete Category
export const deleteCategories = createAsyncThunk(actionTypes.DELETE_CATEGORIES, async (params, thunkAPI) => {
  console.log('paramsmmmm', params);

  try {
    const response = await axiosInstance.delete(`/admin/categories?id=${params.id}`);
    console.log('response of Delete', response);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
