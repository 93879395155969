import { useEffect, useState } from 'react';
// @mui
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { Grid, Container, Typography, Box, Card, CardContent, Stack, Divider } from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';

// components
import axiosInstance from '../api/withJWTServer';
import Page from '../components/Page';
// sections

import { dashboardStateAction } from '../Redux-Store/actions/dashboardActions';
import logoNew from '../Assets/grayLogo.png';
import footerLogo from '../Assets/sadhuImage.png';
import Iconify from '../components/Iconify';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#e4e4e4',
  },
}));

const MainBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '20px',
}));

const MainTypo = styled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '36px',
  color: '#07703C',
}));

const uData = [2400, 7898];
const pData = [2400, 7898];
const xLabels = ['Male', 'Female'];

const pJobData = [
  2400, 7898, 2400, 7898, 2400, 7898, 2400, 7898, 2400, 7898, 3400, 2400, 7898, 2400, 9899, 2400, 7898, 6745, 7880,
  6780, 8988, 9080,
];
const xJobLabels = [
  'Software Developer',
  'Delivery Manager',
  'Plumber',
  'Receptionist',
  'Manager',
  'Travel Coordinator',
  'Board Secretary',
  'Python Developer',
  'App Developer',
  'React Developer',
  'Backend Developer',
  'Salesform Developer',
  'Test1 Developer',
  'Test2 Developer',
  'Test3 Developer',
  'Test4 Developer',
  'Test5 Developer',
  'Test6 Developer',
  'Test7 Developer',
  'Test8 Developer',
  'Test9 Developer',
  'Test10 Developer',
];

export default function DashboardApp() {
  // const { isLogged, role } = useSelector((state) => ({
  //   isLogged: state.authentication.isLogged,
  //   // role: state.authentication.role,
  // }));
  // console.log('isLogged', isLogged, role);

  const role = localStorage.getItem('role');
  const isLogged = localStorage.getItem('isLogged');

  const { dashboardState } = useSelector((state) => ({
    dashboardState: state.dashboard.dashboardState?.data?.data[0],
  }));

  console.log('dashboardState', dashboardState);

  const dispatch = useDispatch();

  const [dashboardCount, setDashboardCount] = useState({});

  useEffect(() => {
    dispatch(dashboardStateAction());
  }, []);
  console.log('Total Category Count:', dashboardCount?.total_category);

  useEffect(() => {
    setDashboardCount(dashboardState);
  }, [dashboardState]);

  return (
    <RootStyle>
      <Page title="Dashboard" sx={{ width: '100%' }}>
        <Grid container md={12} sx={{ px: 4 }}>
          <Grid item mb={1}>
            <Typography fontWeight={'600'} fontSize={16}>
              Dashboard
            </Typography>
            <Typography fontWeight={'400'} fontSize={12}>
              Statistics of your business
            </Typography>
          </Grid>

          <Grid container columnSpacing={2} rowSpacing={2}>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-storefront'} width={24} height={24} color={'teal'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_vendors ? dashboardCount?.total_vendors : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Vendors
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your total vendors
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-person-3'} width={24} height={24} color={'purple'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_consumers ? dashboardCount?.total_consumers : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Users
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your total users
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-apps'} width={24} height={24} color={'red'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_categories ? dashboardCount?.total_categories : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Categories
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your all categories
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-groups-2'} width={24} height={24} color={'blue'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_reviewers ? dashboardCount?.total_reviewers : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Reviewers
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your all reviewers
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-work-history'} width={24} height={24} color={'#ED800E'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_candidates ? dashboardCount?.total_candidates : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Candidates
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is a statistics of all recruitment & matrimony candidates
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:outline-favorite-border'} width={24} height={24} color={'brown'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_matrimonyCandidates ? dashboardCount?.total_matrimonyCandidates : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Matrimony Candidates
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your all matrimony candidates
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box sx={{ background: 'white', borderRadius: '6px', boxShadow: 2, padding: '10px' }}>
                <Iconify icon={'ic:baseline-fitbit'} width={24} height={24} color={'#E01484'} />

                <Typography fontWeight={'700'} fontSize={20}>
                  {dashboardCount?.total_recruitmentCandidates ? dashboardCount?.total_recruitmentCandidates : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Recruitment Candidates
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your all recruitment candidates
                </Typography>
              </Box>
            </Grid>
            <Grid md={3} item>
              <Box
                sx={{
                  background: '#def7e6',
                  borderRadius: '6px',
                  borderWidth: 1,
                  borderColor: '#208442',
                  borderStyle: 'solid',
                  boxShadow: 2,
                  padding: '10px',
                }}
              >
                <Iconify icon={'ic:baseline-credit-card'} width={24} height={24} />

                <Typography fontWeight={'700'} fontSize={20}>
                  &#8377; {dashboardCount?.total_payments ? dashboardCount?.total_payments : 0}
                </Typography>
                <Typography fontWeight={'600'} fontSize={14}>
                  Total Payment
                </Typography>
                <Typography fontWeight={'400'} fontSize={13}>
                  It is showing statistics of your all payment done
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container md={12} sx={{ px: 4, marginTop: '20px' }} spacing={1}>
          <Grid item md={12}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Vendor Status Chart
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Vendor chart by vendor status
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 2,
                  }}
                >
                  <PieChart
                    sx={{
                      alignSelf: 'flex-start',
                    }}
                    series={[
                      {
                        arcLabel: (item) => `(${item.value})`,
                        arcLabelRadius: 130,
                        data: [
                          { id: 1, value: 1280, label: 'Active', color: 'green' },
                          { id: 2, value: 600, label: 'Application Started', color: 'purple' },
                          { id: 3, value: 400, label: 'With Hold', color: '#ED810D' },
                          { id: 4, value: 120, label: 'Rejected', color: '#E3232B' },
                          { id: 5, value: 700, label: 'Renewal', color: '#E11484' },
                        ],
                        innerRadius: 30,
                        outerRadius: 100,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 40, additionalRadius: -10, color: 'gray' },
                      },
                    ]}
                    slotProps={{
                      legend: {
                        itemGap: 20,
                        itemMarkWidth: 10,
                        itemMarkHeight: 8,
                        markGap: 10,
                      },
                    }}
                    height={350}
                    width={800}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} sx={{ px: 4, marginTop: '20px' }} spacing={1}>
          <Grid item md={6}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Matrimony Candidate Statistics
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Matrimony Candidates percentage by Gender
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 2,
                  }}
                >
                  <PieChart
                    sx={{
                      alignSelf: 'flex-start',
                    }}
                    series={[
                      {
                        arcLabel: (item) => `(${item.value})`,
                        arcLabelRadius: 120,

                        data: [
                          { id: 1, value: 1290, label: 'Male', color: '#ED800E' },
                          { id: 2, value: 1190, label: 'Female', color: '#E01484' },
                        ],
                        innerRadius: 50,
                        outerRadius: 80,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 40, additionalRadius: -10, color: 'gray' },
                      },
                    ]}
                    slotProps={{
                      legend: {
                        itemGap: 15,
                        itemMarkWidth: 10,
                        itemMarkHeight: 8,
                        markGap: 5,
                      },
                    }}
                    height={300}
                    width={500}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Vendors Plan Statistics
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Matrimony Candidates percentage by Gender
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 2,
                  }}
                >
                  <PieChart
                    sx={{
                      alignSelf: 'flex-start',
                    }}
                    series={[
                      {
                        arcLabel: (item) => `(${item.value})`,
                        arcLabelRadius: 120,
                        startAngle: -50,
                        data: [
                          { id: 1, value: 1280, label: 'Standard', color: 'teal' },
                          { id: 2, value: 600, label: 'Premium', color: 'purple' },
                          { id: 3, value: 400, label: 'Premium-Pro', color: '#ED810D' },
                        ],
                        innerRadius: 50,
                        outerRadius: 80,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 40, additionalRadius: -10, color: 'gray' },
                      },
                    ]}
                    slotProps={{
                      legend: {
                        itemGap: 15,
                        itemMarkWidth: 10,
                        itemMarkHeight: 8,
                        markGap: 5,
                      },
                    }}
                    height={300}
                    width={480}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} sx={{ px: 4, marginTop: '20px' }} spacing={1}>
          <Grid item md={7}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Payment Statistics
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Payment statistics by Payment Mode
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 2,
                  }}
                >
                  <PieChart
                    sx={{
                      alignSelf: 'flex-start',
                    }}
                    series={[
                      {
                        arcLabel: (item) => `(${item.value} Trans)`,
                        arcLabelRadius: 120,
                        startAngle: -50,
                        data: [
                          { id: 1, value: 1280, label: 'UPI', color: 'teal' },
                          { id: 2, value: 600, label: 'Card', color: 'purple' },
                          { id: 3, value: 400, label: 'NetBanking', color: '#ED810D' },
                          { id: 4, value: 120, label: 'Wallet', color: '#E3232B' },
                        ],
                        innerRadius: 30,
                        outerRadius: 80,
                        paddingAngle: 1,
                        cornerRadius: 5,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 40, additionalRadius: -10, color: 'gray' },
                      },
                    ]}
                    slotProps={{
                      legend: {
                        itemGap: 15,
                        itemMarkWidth: 10,
                        itemMarkHeight: 5,
                        markGap: 5,
                      },
                    }}
                    height={300}
                    width={550}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={5}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Payment Collection
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Payment collection by Sources
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: '#DEF7E6',
                    borderRadius: '10px',
                    boxShadow: 2,
                    padding: '18px',
                    border: '1px solid green',
                  }}
                >
                  <Box
                    style={{
                      position: 'absolute',
                      right: 10,
                    }}
                  >
                    <Iconify icon={'bx:rupee'} width={250} height={250} sx={{ opacity: 0.05 }} />
                  </Box>
                  <Typography fontWeight={'700'} fontSize={25} color={'black'}>
                    &#8377; 12,20,570
                  </Typography>
                  <Typography fontWeight={'600'} fontSize={14}>
                    Total Vendor Collection
                  </Typography>

                  <Divider sx={{ marginTop: '10px' }} />

                  <Stack direction={'row'} spacing={3} mt={'10px'} justifyContent={'space-between'} pr={5}>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        UPI
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#008080'}>
                        &#8377; 11,890
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Card
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#800080'}>
                        &#8377; 1200
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Netbanking
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#ED800E'}>
                        &#8377; 3400
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Wallet
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#E3232B'}>
                        &#8377; 300
                      </Typography>
                    </Stack>
                  </Stack>

                  <Divider sx={{ mt: '10px' }} />

                  <Typography fontWeight={'700'} fontSize={25} mt={'10px'} color={'black'}>
                    &#8377; 1,32,570
                  </Typography>
                  <Typography fontWeight={'600'} fontSize={14}>
                    Total Matrimony Collection
                  </Typography>
                  <Divider sx={{ marginTop: '10px' }} />

                  <Stack direction={'row'} spacing={3} mt={'10px'} justifyContent={'space-between'} pr={5}>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        UPI
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#008080'}>
                        &#8377; 11,890
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Card
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#800080'}>
                        &#8377; 1200
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Netbanking
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#ED800E'}>
                        &#8377; 3400
                      </Typography>
                    </Stack>
                    <Stack direction={'column'}>
                      <Typography fontSize={12} fontWeight={'500'}>
                        Wallet
                      </Typography>
                      <Typography fontSize={16} fontWeight={'600'} color={'#E3232B'}>
                        &#8377; 300
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container md={12} sx={{ px: 4, marginTop: '20px' }} spacing={1}>
          <Grid item md={12}>
            <Grid item mb={1}>
              <Typography fontWeight={'600'} fontSize={16}>
                Recruitement Candidate Statistics
              </Typography>
              <Typography fontWeight={'400'} fontSize={12}>
                Recruitement Candidates percentage by Job Category
              </Typography>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid md={12} item>
                <Box
                  sx={{
                    background: 'white',
                    borderRadius: '10px',
                    boxShadow: 2,
                    padding: '20px',
                  }}
                >
                  <Grid container spacing={1} rowSpacing={3}>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Software Developer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>400</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Human Resource
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>900</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>Manager</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>8990</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        App Developer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>987</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Business Analyst
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>968</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>Shop Worker</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Graphics Designer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>9356</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        UI/UX Designer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>989</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Sales Executive
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>378</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Delivery Manager
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Warehouse Manager
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Software Developer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>400</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Human Resource
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>900</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>Manager</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>8990</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        App Developer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>987</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Business Analyst
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>968</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>Shop Worker</Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Graphics Designer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>9356</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        UI/UX Designer
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>989</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Sales Executive
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>378</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Delivery Manager
                      </Typography>
                    </Grid>
                    <Grid item md={2}>
                      <Typography sx={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>30</Typography>
                      <Typography sx={{ textAlign: 'center', fontWeight: '400', fontSize: 12 }}>
                        Warehouse Manager
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <CardContent>
              <img src={logoNew} alt="SunnGrahk" style={{ marginTop: '50px' }} />
              <Typography style={{ fontSize: '16px', fontWeight: '400', marginLeft: '10px', color: '#898989' }}>
                “Do not figure out big plans at first, but, begin slowly, feel your ground and proceed up and up.” –
                Swami Vivekananda
              </Typography>

              <div style={{ display: 'flex', marginTop: '10px' }}>
                <img src={footerLogo} alt="logo" style={{ width: '50px', height: '50px', marginLeft: '10px' }} />
                <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                  <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#898989' }}>
                    In association with
                  </Typography>
                  <Typography style={{ fontSize: '14px', fontWeight: '600', color: '#898989' }}>
                    International Sindhi Samaj Sangathan
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Grid>
        </Grid> */}
      </Page>
    </RootStyle>
  );
}
