import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Vendor from './pages/vendor/Vendor';
import Reviewers from './pages/reviewer/Reviewers';
import ResetPassword from './sections/auth/ResetPassword';
import Category from './pages/category/Category';
import Subscription from './pages/subscription/Subscription';
import Profile from './pages/Profile';
import SingleVendorProfile from './pages/SingleVendorProfile';
import User from './pages/users/User';
import Userdetail from './pages/UserDetail';
import Location from './pages/Location';
import CreateReviewer from './pages/reviewer/CreateReviewer';
import CreateSubscription from './pages/subscription/CreateSubscription';
import CreateCategory from './pages/category/CreateCategory';
import RecruitmentMaster from './pages/recruitment/RecruitementMasters';
import VendorJobs from './pages/recruitment/vendorJobs/VendorJobs';
import Settings from './pages/settings/Settings';
import CreateVendorJob from './pages/recruitment/vendorJobs/CreateVendorJob';
import Candidates from './pages/recruitment/candidtes/Candidates';
import CreateCandidate from './pages/recruitment/candidtes/CreateCandidate';
import CreateJobCatrgories from './pages/recruitment/masterData/jobCategories/CreateJobCatrgories';
import CreateLanguage from './pages/recruitment/masterData/languages/CreateLanguage';
import CreateSkill from './pages/recruitment/masterData/skills/CreateSkill';

import CreateEducation from './pages/recruitment/masterData/education/CreateEducation';
import CreateCandidateDetails from './pages/recruitment/candidtes/CreateCandidateDetails';
import MaritalStatus from './pages/matrimony/maritalstatus/MaritalStatus';
import CreateMaritalStatus from './pages/matrimony/maritalstatus/CreateMaritalStatus';
import MatrimonyCandidates from './pages/matrimony/candidates/MatrimonyCandidates';
import MatrimonyCandidatesDetails from './pages/matrimony/candidates/MatrimonyCandidatesDetails';
import MatrimonyMaster from './pages/matrimony/master/MatrimonyMaster';
import Transactions from './pages/transactions/VendorTransactions';
import MatrimonyMasterCreateLanguage from './pages/matrimony/languages/MatrimonyMasterCreateLanguage';
import MatrimonyMasterCreateFamilyType from './pages/matrimony/family-type/MatrimonyMasterCreateFamilyType';
import MatrimonyMasterCreateFamilyValues from './pages/matrimony/family-value/MatrimonyMasterCreateFamilyValues';
import MatrimonyMasterCreateAnnualIncome from './pages/matrimony/annual-income/MatrimonyMasterCreateAnnualIncome';
import MatrimonyMasterCreateSmokingHabits from './pages/matrimony/smoking-habits/MatrimonyMasterCreateSmokingHabits';
import MatrimonyMasterCreateDrinkingHabits from './pages/matrimony/drinking-habits/MatrimonyMasterCreateDrinkingHabits';
import MatrimonyMasterCreateEatingHabits from './pages/matrimony/eating-habits/MatrimonyMasterCreateEatingHabits';
import MatrimonyMasterCreateEducation from './pages/matrimony/education/MatrimonyMasterCreateEducation';
import MatrimonyMasterCreateOccupation from './pages/matrimony/occupation/MatrimonyMasterCreateOccupation';
import MatrimonyMasterCreateEmploymentType from './pages/matrimony/employment-type/MatrimonyMasterCreateEmploymentType';
import MatrimonyMasterCreateSubcast from './pages/matrimony/subcast/MatrimonyMasterCreateSubcast';
import VendorJobDetails from './pages/recruitment/vendorJobs/VendorJobDetails';
import CandidatesRequests from './pages/matrimony/candidates/CandidatesRequests';
import TransactionsMaster from './pages/transactions/TransactionsMaster';
import FailPayment from './pages/FailPayment';
import ValidatingMatrimonyPayment from './pages/ValidatingMatrimonyPayment.js';
import SuceessPayment from './pages/SucessPayment';
import CreateJobType from './pages/recruitment/masterData/jobType/CreateJobType';

// ----------------------------------------------------------------------
export default function Router() {
  // const { isLogged } = useSelector((state) => ({
  //   isLogged: state.authentication.isLogged,
  // }));
  const isLogged = localStorage.getItem('isLogged');
  console.log('isLogged', isLogged);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: isLogged ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'vendor', element: <Vendor /> },

        { path: 'transactions', element: <TransactionsMaster /> },

        { path: 'reviewer', element: <Reviewers /> },
        { path: 'create_reviewer', element: <CreateReviewer /> },
        { path: 'settings', element: <Settings /> },
        { path: 'category', element: <Category /> },
        { path: 'create_category', element: <CreateCategory /> },

        { path: 'subscription', element: <Subscription /> },
        { path: 'create_subscription', element: <CreateSubscription /> },

        { path: 'profile', element: <Profile /> },
        { path: 'singlevendor', element: <SingleVendorProfile /> },
        { path: 'user', element: <User /> },
        { path: 'location', element: <Location /> },
        { path: 'userDetail/:uuid/:updatedBy', element: <Userdetail /> },

        { path: 'recruitment/master', element: <RecruitmentMaster /> },
        { path: 'recruitment/master/create_jobcategories', element: <CreateJobCatrgories /> },
        { path: 'recruitment/master/create_language', element: <CreateLanguage /> },
        { path: 'recruitment/master/create_skill', element: <CreateSkill /> },
        { path: 'recruitment/master/create_eduaction', element: <CreateEducation /> },
        { path: 'recruitment/master/create_jobtype', element: <CreateJobType /> },

        { path: 'recruitment/vendorjob', element: <VendorJobs /> },
        { path: 'recruitment/vendorjob/create_vendorjob', element: <CreateVendorJob /> },
        { path: 'recruitment/vendorjob/vendorjob_details', element: <VendorJobDetails /> },
        { path: 'recruitment/candidates', element: <Candidates /> },
        { path: 'recruitment/candidates/create_candidate', element: <CreateCandidate /> },
        { path: 'recruitment/candidates/create_candidateDetails', element: <CreateCandidateDetails /> },

        { path: 'matrimony/marital-status', element: <MaritalStatus /> },
        { path: 'matrimony/master/create-marital-status', element: <CreateMaritalStatus /> },
        { path: 'matrimony/candidates', element: <MatrimonyCandidates /> },
        { path: 'matrimony/candidates/view-details/:userId', element: <MatrimonyCandidatesDetails /> },
        { path: 'matrimony/candidates/view-details/:userId/candidateRequests', element: <CandidatesRequests /> },
        { path: 'matrimony/master', element: <MatrimonyMaster /> },
        { path: 'matrimony/master/create-languages', element: <MatrimonyMasterCreateLanguage /> },
        { path: 'matrimony/master/create-family-types', element: <MatrimonyMasterCreateFamilyType /> },
        { path: 'matrimony/master/create-family-value', element: <MatrimonyMasterCreateFamilyValues /> },
        { path: 'matrimony/master/create-annual-income', element: <MatrimonyMasterCreateAnnualIncome /> },
        { path: 'matrimony/master/create-smoking-habits', element: <MatrimonyMasterCreateSmokingHabits /> },
        { path: 'matrimony/master/create-drinking-habits', element: <MatrimonyMasterCreateDrinkingHabits /> },
        { path: 'matrimony/master/create-eating-habits', element: <MatrimonyMasterCreateEatingHabits /> },
        { path: 'matrimony/master/create-education', element: <MatrimonyMasterCreateEducation /> },
        { path: 'matrimony/master/create-occupation', element: <MatrimonyMasterCreateOccupation /> },
        { path: 'matrimony/master/create-employment-type', element: <MatrimonyMasterCreateEmploymentType /> },
        { path: 'matrimony/master/create-subcast', element: <MatrimonyMasterCreateSubcast /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'resetpassword', element: <ResetPassword /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    // -----------Payemnt routes---------
    { path: '/sucess_payment', element: <SuceessPayment /> },
    { path: '/fail_payment', element: <FailPayment /> },
    // { path: 'validating_payment', element: <ValidatingPayment /> },
    { path: '/validating_matrimony_payment', element: <ValidatingMatrimonyPayment /> },
  ]);
  return routes;
}
