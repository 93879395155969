import React from 'react';
import { Card, Stack, Grid, Container, Button, Box, Avatar, Divider } from '@mui/material';
import Page from '../components/Page';
import account from '../_mock/account';

export default function SingleVendorProfile() {
  return (
    <Page title="User">
      <Container>
        <br />
        <Card sx={{ p: 3 }}>
          <Stack spacing={1}>
            <h3>Profile</h3>
            <Grid container spacing={1}>
              <Grid item lg={12} md={6} xs={12}>
                <Avatar src={account.photoURL} alt="photoURL" />
                <Divider sx={{ mt: 2, mb: 2 }} />
              </Grid>

              <Grid item lg={6} md={6} xs={12}>
                <b>Name: </b>Vendor A
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <b>Bussiness Name: </b>Bussiness A
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <b>Address: </b>Nagpur
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <b>Email: </b>Vendor@gmail.com
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <b>Contact Number: </b>8888888888
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <b>Status: </b>Active
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <b>Document: </b>
                <Box sx={{ width: '40%', height: 100, border: '1px solid silver', mt: 1 }} />
              </Grid>
              <Grid item lg={12} md={6} xs={12}>
                <Box sx={{ display: 'flex', float: 'right' }}>
                  <Button variant="contained" size="large">
                    Download
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Card>
      </Container>
    </Page>
  );
}
