import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // material
import { Card, Box, Grid, Container, Typography, Chip } from '@mui/material';
import { green, red, orange, deepOrange, purple, pink } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { deleteReviewer, getReviewers, updateReviewer } from '../../../Redux-Store/actions/reviewerActions';
import { getCandidates, deleteCandidate } from '../../../Redux-Store/actions/recruitment/candidateActions';
import Page from '../../../components/Page';
import ActionColumn from '../../../Component/TableComponent/ActionColumn';
import PagesTopBar from '../../../Component/PagesTopBar';
import { downloadUserExcel, getUsersList } from '../../../Redux-Store/actions/userInfoActions';
import ShowMessage from '../../../Component/ShowMessage';
import CommonDeleteBox from '../../../Component/ModelBox/CommonDeleteBox';
import CommonActiveDeactiveBox from '../../../Component/ModelBox/CommonActiveDeactiveBox';
import JobCategoryButton from '../../../Component/TableComponent/JobCategoryButton';
import MasterTopBar from '../../../Component/MasterTopBar';
import {
  deleteMatrimonySendRequests,
  getMatrimonySendRequests,
} from '../../../Redux-Store/actions/Matrimony/MatrimonySendRequestAction';
import defaultImg from '../../../Assets/noImagecamera.png';

const SendRequest = (props) => {
  console.log('RecivedIdProps', props.RecivedId);
  const candidteId = props?.RecivedId;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { MatrimonySendRequests, isloading } = useSelector((state) => ({
    MatrimonySendRequests: state.MatrimonySendRequests?.MatrimonySendRequests?.data?.data,
    isloading: state.MatrimonySendRequests?.isLoading,
  }));
  console.log('MatrimonySendReq', MatrimonySendRequests);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [filterData, setFilterData] = useState(null);
  const [sendRequestDeleteId, setSendRequestDeleteId] = useState();
  const [open, setOpen] = useState(false);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [activeDeactivateObject, setActivateDeactivateObject] = useState({});
  const [isDeleteDialogShow, setIsDeleteDialogShow] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 5, page: 0 });
  const [serialNumber, setSerialNumber] = useState(0);

  const getPeginationModelValue = (e) => {
    setPaginationModel(e);
    setSerialNumber(e.page * e.pageSize);
    setPage(e.page);
    setRowsPerPage(e.pageSize);
  };

  const paramsData = {
    filterData,
    page,
    rowsPerPage,
    candidteId,
  };

  useEffect(() => {
    dispatch(getMatrimonySendRequests(paramsData));
  }, [filterData, paginationModel]);

  const handleDelete = (e) => {
    // console.log('eee564565', e);
    setSendRequestDeleteId(e.request_uuid);
    setOpen(!open);
  };
  const handleDeleteCancle = (e) => {
    // setSendRequestDeleteId(e.uuid);
    setOpen(!open);
  };

  const handleSubmit = () => {
    dispatch(deleteMatrimonySendRequests({ uuid: sendRequestDeleteId }))
      .unwrap()
      .then((response) => {
        if (response.status === 200) {
          setIsShow(true);
          setOpen(!open);
          setErrorMessage(response.data.message);
          dispatch(getMatrimonySendRequests(paramsData));
        }
        setTimeout(() => {
          setIsShow(false);
          // navigate(-1);
        }, 1500);
      })
      .catch((error) => {
        console.log('error');
      });
  };

  const CustomNoRowsOverlay = () => {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        {/* Your custom message here */}
        <h4>No Data Found</h4>
      </div>
    );
  };

  const columns = [
    {
      field: 'candidate_uuid',
      headerName: 'Sr no',
      width: 100,
      renderCell: (params) => serialNumber + params.api.getAllRowIds().indexOf(params.id) + 1,
    },
    {
      field: `category_Image`,
      headerName: 'Image',
      // flex: 0.7,
      width: 100,
      type: 'binary',
      renderCell: (params) => {
        const imagePath = defaultImg;
        console.log('imagePrams', params);
        // console.log('imagePath', imagePath);
        return (
          <img
            src={`${
              params.row?.image_paths !== null && params.row?.image_paths[0]?.image_path
                ? params.row?.image_paths[0]?.image_path
                : imagePath
            }`}
            // src={imagePath}
            height="40px"
            width="40px"
            style={{ margin: '10px', borderRadius: '6px' }}
            alt="img"
          />
        );
      },
    },
    { field: `first_name`, headerName: 'First Name', width: 100, type: 'string' },
    { field: `last_name`, headerName: 'Last Name', width: 150, type: 'string' },
    {
      field: `request_type`,
      headerName: 'Staus',
      width: 200,
      renderCell: (params) => {
        console.log('params in edu', params);

        if (params.row.request_type === 'reject_request') {
          return (
            <Typography
              sx={{
                bgcolor: red[50],
                borderRadius: '5px',
                padding: '2px 10px',
                color: red[900],
                fontSize: '12px',
                fontWeight: '550',
                textTransform: 'uppercase',
                borderColor: red[900],
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'max-content',
                // p: '5px',
              }}
            >
              REQUEST REJECTED
            </Typography>
          );
        }
        if (params.row.request_type === 'cancel_request') {
          return (
            <Typography
              sx={{
                bgcolor: red[50],
                borderRadius: '5px',
                padding: '2px 10px',
                color: red[900],
                fontSize: '12px',
                fontWeight: '550',
                textTransform: 'uppercase',
                borderColor: red[900],
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'max-content',
                // p: '5px',
              }}
            >
              CANCLE REQUEST
            </Typography>
          );
        }
        if (params.row.request_type === 'accept_request') {
          return (
            <Typography
              sx={{
                bgcolor: green[50],
                borderRadius: '5px',
                padding: '2px 10px',
                color: green[900],
                fontSize: '12px',
                fontWeight: '550',
                textTransform: 'uppercase',
                borderColor: green[900],
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'max-content',
                // p: '5px',
              }}
            >
              REQUEST ACCPETED
            </Typography>
          );
        }
        if (params.row.request_type === 'send_request') {
          return (
            <Typography
              sx={{
                bgcolor: pink[50],
                borderRadius: '5px',
                padding: '2px 10px',
                color: pink[600],
                fontSize: '12px',
                fontWeight: '550',
                textTransform: 'uppercase',
                borderColor: pink[600],
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'max-content',
                // p: '5px',
              }}
            >
              REQUEST SEND
            </Typography>
          );
        }
        if (params.row.request_type === 'recived_request') {
          return (
            <Typography
              sx={{
                bgcolor: orange[50],
                borderRadius: '5px',
                padding: '2px 10px',
                color: orange[600],
                fontSize: '12px',
                fontWeight: '550',
                textTransform: 'uppercase',
                borderColor: orange[600],
                borderWidth: '1px',
                borderStyle: 'solid',
                width: 'max-content',
                // p: '5px',
              }}
            >
              REQUEST RECIVED
            </Typography>
          );
        }
      },
    },

    {
      field: 'col6',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ActionColumn
            showView={false}
            showEdit={false}
            showDelete
            // handleView={() => handleView(params.row)}
            // handleEdit={() => handleEdit(params.row)}
            handleDelete={() => handleDelete(params.row)}
          />
        </div>
      ),
    },
  ];
  const rows = [];

  return (
    <Page title="User" sx={{ width: '100%' }}>
      {open ? (
        <CommonDeleteBox
          openDialog={open}
          handleDeleteYes={handleSubmit}
          handleClose={handleDeleteCancle}
          title="Send Request"
          description="Are you sure you want to delete send request ?"
        />
      ) : null}

      <Box id="maritalStatus" sx={{ width: '100%', mt: '20px' }} maxWidth="xl">
        {/* <MasterTopBar
          title="Send Requests"
          // subTitle="List of all  Marital Status in your app."
          handleSearch={handleSearch}
          clearInput={() => {
            handleSearch('');
          }}
        /> */}
        <Card sx={{ marginTop: '25px' }}>
          <Grid container>
            <Grid item lg={12} xs={12} sm={12}>
              <DataGrid
                disableColumnMenu
                style={{
                  padding: '10px',
                  height: 420,
                  width: '100%',
                  borderRadius: '10px',
                  fontSize: '14px',
                }}
                sx={{}}
                paginationModel={paginationModel}
                onPaginationModelChange={getPeginationModelValue}
                pageSizeOptions={[5, 10, 25]}
                paginationMode="server"
                loading={isloading}
                rows={
                  MatrimonySendRequests?.sent_request_candidates !== null &&
                  MatrimonySendRequests?.sent_request_candidates
                    ? MatrimonySendRequests?.sent_request_candidates
                    : rows
                }
                columns={columns}
                getRowId={(row) => row.candidate_uuid}
                rowCount={MatrimonySendRequests?.total_sent_request_count}
                localeText={{ noRowsLabel: <CustomNoRowsOverlay /> }}
                slots={
                  {
                    // toolbar: CustomToolbar,
                  }
                }
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {
                        bgcolor: '#ffffff',
                        // borderRadius: '8px',
                        // border: '1px solid rgba(0,0,0,0.1)',
                        pb: '0px',
                        '& fieldset': { border: 'none' },
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      {/* ----------------------Message box-------------------- */}
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
      <ShowMessage onClose={() => setIsShow(false)} title={errorMessage} type="success" open={isShow} />
    </Page>
  );
};

export default SendRequest;
