import { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Stack,
  Grid,
  TextField,
  Container,
  Typography,
  InputAdornment,
  Divider,
  Box,
  Button,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PhotoCamera } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { addCategories, updateCategory } from '../../Redux-Store/actions/categoryActions';
import ShowMessage from '../../Component/ShowMessage';
import Iconify from '../../components/Iconify';

const CreateReviewer = () => {
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const categoryFormData = useLocation();

  const categoryData = categoryFormData?.state?.categoryData;

  console.log('categoryData', categoryData);

  const [submitLoader, setSubmitLoader] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isShowUpdate, setIsShowUpdate] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState();
  const [trendingValue, setTrendingValue] = useState();
  console.log('trendingValue', trendingValue);

  const handleTrendingChange = (e) => {
    setTrendingValue(e.target.value);

    // apiRef.current.state = e.target.value;
  };

  const categorySchema = Yup.object().shape({
    Title: Yup.string()
      .matches(/^([^0-9]*)$/, 'Number is not allowed')
      .matches(/^[A-Za-z0-9 ]+$/, ' Special characters not allowed')
      .required('Title is required')
      .min(2, 'Title should not less than 2 characters')
      .max(30, 'Title should not  more than 30 characters'),

    Description: Yup.string()

      .required('Description is required')
      .min(2, 'Description should not less than 2 characters'),

    image: Yup.string().required('Please select category image.'),
    isTrending: Yup.string().required('Please select isTrending.'),
    // trendingValue:
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Title: categoryData ? categoryData?.category_name : '',
      Description: categoryData ? categoryData?.category_description : '',
      image: categoryData ? categoryData?.icon_file_path : '',
      isTrending: categoryData ? categoryData?.istrending : '',
      remember: true,
    },
    validationSchema: categorySchema,

    onSubmit: (values) => {
      console.log('value', values);
      setSubmitLoader(true);

      // const formData = new FormData();
      // if (categoryData) {
      //   formData.append('id', JSON.parse(categoryData.id));
      //   formData.append('category_icon', values.image);
      //   formData.append('category_name', values.Title);
      //   formData.append('category_description', values.Description);
      // } else {
      //   formData.append('category_icon', values.image);
      //   formData.append('category_name', values.Title);
      //   formData.append('category_description', values.Description);
      // }

      const paramsData = categoryData
        ? {
            id: JSON.parse(categoryData.id),
            category_icon: values.image,
            category_name: values.Title,
            category_description: values.Description,
            istrending: values.isTrending,
          }
        : {
            category_icon: values.image,
            category_name: values.Title,
            category_description: values.Description,
            istrending: values.isTrending,
          };
      // console.log('formData', formData);
      //   const editformData = cataenew FormData();
      //   formData.append('id', categoryData.id);
      //   formData.append('category-icon', categoryData.image);
      //   formData.append('category_name', categoryData.Title);
      //   formData.append('category_description', categoryData.Description);
      // }

      // const params = categoryData
      //   ? {
      //       id: categoryData.id,
      //       image: categoryData?.icon_file_path,
      //       Title: value.Title,
      //       Description: value.Description,
      //     }
      //   : {
      //       categary_icon: value?.image,
      //       category_name: value?.Title,
      //       category_description: value?.Description,
      //     };

      if (categoryData?.id) {
        dispatch(updateCategory(paramsData))
          .unwrap()
          .then((response) => {
            // setSubmitLoader(false);
            console.log('response in update Category', response);
            if (response.status === 200) {
              if (response.data.name) {
                setErrorUpdate(true);
                setSubmitLoader(false);
                setIsShowUpdate(true);
                setErrorMessage(response.data.message);
              } else {
                setErrorUpdate(false);
                setSubmitLoader(false);
                setIsShowUpdate(true);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                  setIsShowUpdate(false);
                  navigate(-1);
                }, 1000);
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 400) {
              console.log('error', error.response.status, error.response.data.error);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      } else {
        dispatch(addCategories(paramsData))
          .unwrap()
          .then((response) => {
            console.log('response of add category', response);
            if (response.status === 200) {
              if (response.data.name) {
                setErrorUpdate(true);
                setSubmitLoader(false);
                setIsShowUpdate(true);
                setErrorMessage(response.data.message);
              } else {
                setErrorUpdate(false);
                setSubmitLoader(false);
                setIsShowUpdate(true);
                setErrorMessage(response.data.message);
                setTimeout(() => {
                  setIsShowUpdate(false);
                  navigate(-1);
                }, 1000);
              }
            }
          })
          .catch((error) => {
            console.log('error', error);
            if (error.response.status === 409) {
              console.log('error', error.response.status, error.response.data.error);
              setSubmitLoader(false);
              setIsShowUpdate(true);
              setErrorUpdate(true);
              setErrorMessage(error.response.data.error);
              setTimeout(() => {
                setIsShowUpdate(false);
              }, 3000);
            } else {
              setErrorUpdate(false);
            }
          });
      }
    },
  });

  const handleImageChange = (event) => {
    console.log('Open file selected ');
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      formik.setFieldValue('image', selectedFile);
    }
  };

  const handleRemoveImage = () => {
    if (categoryData) {
      console.log('here with object ', categoryData?.icon_file_path);
      formik.setFieldValue('image', categoryData?.icon_file_path);
    } else {
      console.log('here no object ');
      formik.setFieldValue('image', null);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };

  const { errors, touched, getFieldProps } = formik;
  return (
    <div>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Typography variant="h6" fontWeight={'600'}>
          {categoryData ? ' Update Category' : ' Create Category'}
        </Typography>
        <Typography fontSize={14}>Enter your Category details</Typography>

        <Divider sx={{ mt: '15px' }} />
        <Card sx={{ padding: '20px', marginTop: '15px' }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate>
              <Grid container spacing={4} style={{ padding: '20px' }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="Title"
                    label="Title *"
                    {...getFieldProps('Title')}
                    error={Boolean(touched.Title && errors.Title)}
                    helperText={touched.Title && errors.Title}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    autoComplete="Description"
                    label="Description *"
                    {...getFieldProps('Description')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end" />,
                    }}
                    error={Boolean(touched.Description && errors.Description)}
                    helperText={touched.Description && errors.Description}
                  />
                </Grid>
                {/* ---------------------istrending------------------- */}
                <Grid item xs={6}>
                  <TextField
                    select
                    name="isTrading"
                    label="Is Trending*"
                    variant="outlined"
                    fullWidth
                    onChange={handleTrendingChange}
                    value={trendingValue}
                    {...getFieldProps('isTrending')}
                    error={Boolean(touched.isTrending && errors.isTrending)}
                    helperText={touched.isTrending && errors.isTrending}
                  >
                    <MenuItem key={1} value={1}>
                      Yes
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      No
                    </MenuItem>
                  </TextField>
                </Grid>
                {/* ----------------iMAGE -------------*/}
                {categoryData ? (
                  <>
                    <Grid item xs={12} sm={12} lg={6} sx={{ marginTop: '20px  ' }}>
                      Category Image *
                      <Box height={'20px'} />
                      {formik?.values?.image?.lastModified && <p>New Selected Image</p>}
                      <label
                        htmlFor="image"
                        style={{
                          display: 'inline-block',
                          color: '#333',
                          border: '1px solid #ccc',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      >
                        {formik?.values?.image?.lastModified ? (
                          <>
                            {formik?.values?.image instanceof Blob && (
                              <img
                                src={URL.createObjectURL(formik.values.image)}
                                alt="Selected"
                                style={{ objectFit: 'cover', borderRadius: '10px' }}
                              />
                            )}
                            <input
                              id="image"
                              name="image"
                              type="file"
                              style={{ display: 'none' }}
                              accept="image/png, image/jpg, image/jpeg"
                              onChange={(event) => formik.setFieldValue('image', event.target.files[0])}
                              // error={Boolean(touched.Description && errors.Description)}
                              // helperText={touched.Description && errors.Description}
                            />
                            <Iconify
                              icon="eva:minus-outline"
                              sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                                height: '30px',
                                width: '30px',
                                color: '#fff',
                                backgroundColor: 'red',
                                borderRadius: '20px',
                                border: '2px solid #fff',
                                cursor: 'pointer',
                              }}
                              onClick={handleRemoveImage}
                            />
                          </>
                        ) : (
                          <>
                            <Box sx={{ padding: '20px' }}>
                              <center>
                                <Iconify icon={'ic:baseline-cloud-upload'} width={'30px'} height={'30px'} />
                              </center>

                              <Typography
                                style={{
                                  display: 'block',
                                  fontWeight: '600',
                                  fontSize: '15px',
                                  textAlign: 'center',
                                  marginTop: '2px',
                                }}
                              >
                                Upload New Image
                              </Typography>
                              <Typography
                                style={{
                                  display: 'block',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  textAlign: 'center',
                                  marginTop: '3px',
                                }}
                              >
                                Tap to update category new image
                              </Typography>

                              <input
                                id="image"
                                name="image"
                                type="file"
                                style={{ display: 'none' }}
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(event) => {
                                  if (event.target.files[0]) {
                                    formik.setFieldValue('image', event.target.files[0]);
                                  }
                                }}
                                // error={Boolean(touched.Description && errors.Description)}
                                // helperText={touched.Description && errors.Description}
                              />
                            </Box>
                          </>
                        )}
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <br />
                      {!formik?.values?.image?.lastModified && (
                        <>
                          <p>Uploaded Image</p>
                          <Box height={'5px'} />
                          {categoryData?.icon_file_path && (
                            <img
                              src={`${categoryData?.icon_file_path}`}
                              height="100px"
                              width="150px"
                              alt="img"
                              style={{ borderRadius: '10px', objectFit: 'cover' }}
                            />
                          )}
                        </>
                      )}
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} sm={12} lg={6}>
                    <span>
                      Category Image *
                      <Box height={'10px'} />
                      <div>
                        <input
                          ref={fileInputRef}
                          id="image"
                          name="image"
                          type="file"
                          style={{
                            display: 'none',
                          }}
                          accept="image/png, image/jpg, image/jpeg"
                          onChange={handleImageChange}
                        />
                        <label
                          htmlFor="image"
                          style={{
                            display: 'inline-block',
                            color: '#333',
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            cursor: 'pointer',
                            width: '50%',
                            height: '90%',
                            position: 'relative',
                          }}
                        >
                          {formik.values.image ? (
                            <>
                              <img
                                src={URL.createObjectURL(formik.values.image)}
                                alt="Selected"
                                style={{
                                  width: '100%',
                                  height: 'auto',

                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                }}
                              />
                              <Iconify
                                icon="eva:minus-outline"
                                sx={{
                                  position: 'absolute',
                                  top: '8px',
                                  right: '8px',
                                  height: '30px',
                                  width: '30px',
                                  color: '#fff',
                                  backgroundColor: 'red',
                                  borderRadius: '20px',
                                  border: '2px solid #fff',
                                  cursor: 'pointer',
                                }}
                                onClick={handleRemoveImage}
                              />
                            </>
                          ) : (
                            <>
                              <Box height={'30px'} />

                              <center>
                                <Iconify icon={'ic:baseline-cloud-upload'} width={'30px'} height={'30px'} />
                              </center>

                              <Typography
                                style={{
                                  display: 'block',
                                  fontWeight: '600',
                                  fontSize: '15px',
                                  textAlign: 'center',
                                  marginTop: '2px',
                                }}
                              >
                                Upload Image
                              </Typography>
                              <Typography
                                style={{
                                  display: 'block',
                                  fontWeight: '400',
                                  fontSize: '12px',
                                  textAlign: 'center',
                                  marginTop: '3px',
                                }}
                              >
                                Tap to upload category image
                              </Typography>
                              <Box height={'30px'} />
                            </>
                          )}
                        </label>
                        {formik.touched.image && formik.errors.image && (
                          <p style={{ color: 'red', fontSize: '12px' }}>{'Please select category image'}</p>
                        )}
                      </div>
                    </span>
                  </Grid>
                )}

                {/* ----------------iMAGE  end-------------*/}
                <Grid item xs={12}>
                  <LoadingButton
                    loading={submitLoader && true}
                    size="medium"
                    type="submit"
                    variant="contained"
                    sx={{ float: 'right', padding: '10px 20px', '&:hover': { bgcolor: 'green' } }}
                  >
                    {categoryData ? 'Update Category' : 'Create Category'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Card>
      </Container>

      <ShowMessage
        onClose={() => setIsShow(false)}
        title={errorMessage}
        type={error ? 'error' : 'success'}
        open={isShow}
      />
      <ShowMessage
        onClose={() => setIsShowUpdate(false)}
        title={errorMessage}
        type={errorUpdate ? 'error' : 'success'}
        open={isShowUpdate}
      />
    </div>
  );
};

export default CreateReviewer;
