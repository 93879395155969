import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Padding } from '@mui/icons-material';
import {
  Card,
  Container,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import Page from '../../components/Page';
import SwitchBtn from '../../Component/DataGridComponents/SwitchBtn';
import {
  fetchSettingsStatus,
  updateMatrimonyStatus,
  updateRecruitmentStatus,
} from '../../Redux-Store/actions/settingActrions';

const Settings = () => {
  const dispatch = useDispatch();
  const settingsCardOption = useSelector((state) => state.settings.settingsCardOption);
  const [matrimonyActive, setMatrimonyActive] = useState(settingsCardOption?.data[0]?.MATRIMONY);
  const [recruitmentActive, setRecruitmentActive] = useState(settingsCardOption?.data[0]?.RECRUITMENT);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [toggleAction, setToggleAction] = useState(null);

  useEffect(() => {
    dispatch(fetchSettingsStatus());
  }, [dispatch]);

  useEffect(() => {
    if (settingsCardOption) {
      setMatrimonyActive(settingsCardOption.data[0]?.MATRIMONY);
      setRecruitmentActive(settingsCardOption.data[0]?.RECRUITMENT);
    }
  }, [settingsCardOption]);

  const handleMatrimonyToggle = () => {
    setToggleAction('matrimony');
    setConfirmationDialogOpen(true);
  };

  const handleRecruitmentToggle = () => {
    setToggleAction('recruitment');
    setConfirmationDialogOpen(true);
  };

  const handleDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      if (toggleAction === 'matrimony') {
        const newMatrimonyStatus = !matrimonyActive;
        setMatrimonyActive(newMatrimonyStatus);
        dispatch(updateMatrimonyStatus(newMatrimonyStatus));
      } else if (toggleAction === 'recruitment') {
        const newRecruitmentStatus = !recruitmentActive;
        setRecruitmentActive(newRecruitmentStatus);
        dispatch(updateRecruitmentStatus(newRecruitmentStatus));
      }
    }
    setConfirmationDialogOpen(false);
  };

  return (
    <Page title="User" sx={{ width: '100%' }}>
      <Container id="reviewer" sx={{ width: '100%' }} maxWidth="xl">
        <Grid container sx={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
          <Grid lg={6} xs={12} sm={12} item gap={2}>
            <Typography fontSize={'25px'} fontWeight={'600'}>
              Settings
            </Typography>
            <Typography fontSize={'12px'} fontWeight={'500'}>
              It is recruitment and matrimony setting options
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid lg={6} xs={12} sm={12} item>
            <Card sx={{ padding: '30px', cursor: 'pointer' }} onClick={handleMatrimonyToggle}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '3px' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>Matrimony Option</Typography>
                <SwitchBtn
                  statusData={{ isactive: matrimonyActive ? 1 : 0 }}
                  handleActiveDeactiveClick={handleMatrimonyToggle}
                />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                  If you enable the option, it will be visible on the app. If disabled, it will be hidden.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid lg={6} xs={12} sm={12} item>
            <Card sx={{ padding: '30px', cursor: 'pointer' }} onClick={handleRecruitmentToggle}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '3px' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>Recruitment Option</Typography>
                <SwitchBtn
                  statusData={{ isactive: recruitmentActive ? 1 : 0 }}
                  handleActiveDeactiveClick={handleRecruitmentToggle}
                />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: '400', fontSize: '14px' }}>
                  If you enable the option, it will be visible on the app. If disabled, it will be hidden.
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to toggle this option?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: '25px', paddingRight: '25px' }}>
          <Button
            onClick={() => handleConfirmation(false)}
            variant="contained"
            disableElevation
            sx={{ fontSize: '12px', background: 'teal', '&:hover': { bgcolor: 'teal' } }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmation(true)}
            variant="contained"
            color="error"
            disableElevation
            sx={{ fontSize: '12px', '&:hover': { bgcolor: 'red' } }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Settings;
