import { createSlice } from '@reduxjs/toolkit';
import {
  addMaritalStatus,
  addMatrimonyLanguage,
  deleteMaritalStatus,
  deleteMatrimonyCandidate,
  deleteMatrimonyLanguage,
  getMaritalStatus,
  getMatrimonyCandidates,
  getMatrimonyLanguage,
  getMatrimonyLanguages,
  updateMaritalStatus,
  updateMatrimonyLanguage,
  addMatrimonyMasterField,
  getMatrimonyMasterField,
  updateMatrimonyMasterField,
  updateMatrimonyMasterFieldStatus,
  deleteMatrimonyMasterField,
  getMatrimonyCast,
} from '../../actions/MatrimonyActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  maritalStatus: {},
  error: '',
  isLoading: false,
  language: {},
  matrimonyMaster: {},
  cast: {},
};

// slice or reducer
const Matrimony = createSlice({
  name: 'Matrimony',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseMatrimony(builder, addMaritalStatus);
    caseMatrimony(builder, updateMaritalStatus);
    caseMatrimony(builder, getMaritalStatus);
    caseMatrimony(builder, deleteMaritalStatus);
    caseMatrimony(builder, getMatrimonyCandidates);
    caseMatrimony(builder, deleteMatrimonyCandidate);
    caseMatrimony(builder, addMatrimonyLanguage);
    caseMatrimony(builder, updateMatrimonyLanguage);
    caseMatrimony(builder, getMatrimonyLanguages);
    caseMatrimony(builder, getMatrimonyLanguage);
    caseMatrimony(builder, deleteMatrimonyLanguage);
    caseMatrimony(builder, addMatrimonyMasterField);
    caseMatrimony(builder, updateMatrimonyMasterField);
    caseMatrimony(builder, getMatrimonyMasterField);
    caseMatrimony(builder, deleteMatrimonyMasterField);
    caseMatrimony(builder, getMatrimonyCast);
  },
});
export default Matrimony.reducer;

const caseMatrimony = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    // state updated in fulfilled state

    if (action.type.includes(actionTypes.ADD_MARITAL_STATUS)) {
      state.maritalStatus = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_MARITAL_STATUS)) {
      state.isLoading = false;
      state.maritalStatus = action.payload;
    } else if (action.type.includes(actionTypes.GET_MARITAL_STATUS)) {
      state.isLoading = false;
      state.maritalStatus = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_MARITAL_STATUS)) {
      state.maritalStatus = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.ADD_MATRIMONY_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_MATRIMONY_LANGUAGE)) {
      state.isLoading = false;
      state.language = action.payload;
    } else if (action.type.includes(actionTypes.GET_MATRIMONY_SINGLE_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_MATRIMONY_LANGUAGES)) {
      state.isLoading = false;
      state.language = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_MATRIMONY_LANGUAGE)) {
      state.language = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.ADD_MATRIMONY_MASTER_FIELD)) {
      state.matrimonyMaster = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_MATRIMONY_MASTER_FIELD)) {
      state.isLoading = false;
      state.matrimonyMaster = action.payload;
    } else if (action.type.includes(actionTypes.GET_MATRIMONY_MASTER_FIELD)) {
      state.isLoading = false;
      state.matrimonyMaster = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_MATRIMONY_MASTER_FIELD)) {
      state.matrimonyMaster = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_MATRIMONY_CAST)) {
      state.cast = action.payload;
      state.isLoading = false;
    }
  });
};
