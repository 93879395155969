import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function VendorBusinessImages(props) {
  const { isOpen, docs, data } = props;

  const handleClose = () => {
    props.handleClose();
  };

  const DistrictsSchema = Yup.object().shape(data ? {} : {});

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data ? {} : {},
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {},
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange } = formik;

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={isOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Business Images
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={12}>
            <Grid item xs={12} style={{ width: '1000px', height: '650px' }}>
              <Carousel>
                {docs.length === 0 ? (
                  <div>
                    <p className="legend">AadharCard</p>
                  </div>
                ) : (
                  docs.map((val, index) => (
                    <>
                      <div>
                        {/\.pdf$/i.test(val?.doc_path) ? (
                          <iframe
                            title="PDF Viewer"
                            src={(val?.doc_path)? (val?.doc_path ): (val?.image_path)}
                            width="100%"
                            height="350px"
                            frameBorder="0"
                            scrolling="auto"
                          />
                        ) : (
                          <img
                            src={(val?.doc_path) ? (val?.doc_path) : (val?.image_path)}
                            alt="im"
                            style={{ width: '600px', height: '500px' }}
                          />
                        )}
                      </div>
                    </>
                  ))
                )}
              </Carousel>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
