import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionTypes } from '../actionTypes';
import axiosInstance from '../../api/withJWTServer';

export const addSubcription = createAsyncThunk(actionTypes.ADD_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/subscriptions-plan', params);
    console.log('response of add subscription', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateSubcription = createAsyncThunk(actionTypes.UPDATE_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.patch('/admin/subscriptions-plan', params);
    console.log('response of update subscription', response);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export const getSubcription = createAsyncThunk(actionTypes.GET_REVIEWER, async (params, thunkAPI) => {
  try {
    const response = await axiosInstance.post('/admin/subscription', params);
    console.log('response of get subscription', response);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSubcriptions = createAsyncThunk(actionTypes.GET_SUBSCRIPTIONS, async (params, thunkAPI) => {
  console.log('params?.filterData');
  try {
    let url;
    if (params?.filterData) {
      url = `admin/subscriptions-plan?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    } else if (params?.page && params?.rowsPerPage) {
      url = `admin/subscriptions-plan?page=${params?.page + 1}&limit=${params?.rowsPerPage}`;
    } else {
      url = 'admin/subscriptions-plan';
    }
    // const url = params?.filterData
    //   ? `admin/subscriptions-plan?key=${params?.filterData}&page=${params?.page + 1}&limit=${params?.rowsPerPage}`
    //   : `/admin/subscriptions-plan?page=${params?.page + 1}&limit=${params?.rowsPerPage}`

    const response = await axiosInstance.get(url);

    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteSubcription = createAsyncThunk(actionTypes.DELETE_REVIEWER, async (params, thunkAPI) => {
  try {
    console.log('paramsinsubscription', params);
    const response = await axiosInstance.delete(`/admin/subscriptions-plan?uuid=${params?.uuid}`);
    console.log('response of delet subscription', response.data);
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
