import axios from 'axios';

// const isStage = window.location.hostname.includes('stage');
// const isStage = true;

const axiosInstancePayment = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_ADMIN,
  headers: {
    'Content-Type': 'application/json',
    'Content-Language': 'browser',
  },
});

export default axiosInstancePayment;
