import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';

const SuspendModelBox = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      keepMounted
      onClose={props.handleSuspendClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: { borderRadius: '10px', padding: '30px' },
      }}
    >
      <DialogTitle sx={{ fontWeight: '500', fontSize: '16px', p: 0, mb: 1 }}>
        {' '}
        {`Are you sure you want to ${props?.activeObject?.isactive ? 'Unsuspend' : 'Suspend'}?`}
      </DialogTitle>
      <DialogContent sx={{ p: 0, mb: 3 }}>
        <DialogContentText id="alert-dialog-slide-description" sx={{ fontWeight: '500', fontSize: '14px', p: 0, m: 0 }}>
          {props?.activeObject?.isactive === 1 ? (
            <>After unsuspend vendor can login to app and web portal</>
          ) : (
            <>After suspend vendor can't login app and web portal it will logout automatically from both apps.</>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{}}>
        <Button
          //   loading={submitLoader && true}
          size="small"
          type="submit"
          onClick={props.handleSuspend}
          variant="contained"
          style={{
            float: 'center',
            margin: 10,
            background: 'red',
            height: '34px',
            paddingRight: '15px',
            paddingLeft: '15px',
            borderRadius: '6px',
          }}
          sx={{
            '&:hover': { bgcolor: 'red' },
          }}
        >
          {props?.activeObject?.isactive === 1 ? 'Unsuspend' : 'Suspend'}
        </Button>
        <Button
          size="small"
          type="submit"
          onClick={props.handleSuspendCancel}
          variant="contained"
          sx={{
            '&:hover': { bgcolor: 'teal' },
          }}
          style={{
            float: 'center',
            background: 'teal',
            margin: 10,
            width: '86px',
            height: '34px',
            borderRadius: '6px',
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuspendModelBox;
