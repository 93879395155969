import { createSlice } from '@reduxjs/toolkit';
import { addJobType, updateJobType, getJobType, deleteJobType } from '../../actions/recruitment/JobTypeActions';
import { actionTypes } from '../../actionTypes';
// redux state
const initialState = {
  jobType: undefined,
  error: '',
  isLoading: false,
};

// slice or reducer
const JobType = createSlice({
  name: 'JobType',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseSalaryType(builder, addJobType);
    caseSalaryType(builder, updateJobType);
    // caseSalaryType(builder, getJobTypes);
    caseSalaryType(builder, getJobType);
    caseSalaryType(builder, deleteJobType);
  },
});
export default JobType.reducer;

const caseSalaryType = (builder, action) => {
  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  });
  handleAllFulfillCases(builder, action);

  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_JOB_TYPE)) {
      state.JobType = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.UPDATE_JOB_TYPE)) {
      state.isLoadingt = false;
      state.JobType = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_JOB_TYPE)) {
      state.JobType = action.payload;
      state.isLoading = false;
    } else if (action.type.includes(actionTypes.GET_JOB_TYPES)) {
      state.isLoading = false;
      state.JobType = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_JOB_TYPE)) {
      state.JobType = action.payload;
      state.isLoading = false;
    }
  });
};
