import { createSlice } from '@reduxjs/toolkit';
import {
  addJobCategory,
  updateJobCategory,
  getJobCategories,
  getJobCategory,
  deleteJobCategory,
} from '../../actions/recruitment/jobCategoryActions';
import { actionTypes } from '../../actionTypes';

// redux state
const initialState = {
  jobCategory: undefined, // Corrected: 'JobCategory' should be 'jobCategory'
  error: '',
  isLoading: false,
};

// slice or reducer
const JobCategory = createSlice({
  name: 'JobCategory',
  initialState,
  // normal reducers
  reducers: {
    clearError(state) {
      state.error = undefined;
    },
  },
  // async reducers
  extraReducers: (builder) => {
    caseJobCategory(builder, addJobCategory);
    caseJobCategory(builder, updateJobCategory);
    caseJobCategory(builder, getJobCategories);
    caseJobCategory(builder, getJobCategory);
    caseJobCategory(builder, deleteJobCategory);
  },
});
export default JobCategory.reducer;

const caseJobCategory = (builder, action) => {

  builder.addCase(action.pending, (state) => {
    state.isLoading = true;
  }
  );

  handleAllFulfillCases(builder, action);
  builder.addCase(action.rejected, (state) => {
    state.isLoading = false;
  });
};

const handleAllFulfillCases = (builder, action) => {
  builder.addCase(action.fulfilled, (state, action) => {
    if (action.type.includes(actionTypes.ADD_JOB_CATEGORY)) {
      state.jobCategory = action.payload;
    } else if (action.type.includes(actionTypes.UPDATE_JOB_CATEGORY)) {
      state.jobCategory = action.payload;
    } else if (action.type.includes(actionTypes.GET_SINGLE_JOB_CATEGORY)) {
      state.jobCategory = action.payload;
    } else if (action.type.includes(actionTypes.GET_JOB_CATEGORIES)) {
      state.jobCategory = action.payload;
    } else if (action.type.includes(actionTypes.DELETE_JOB_CATEGORY)) {
      state.jobCategory = action.payload;
    }
    state.isLoading = false; // Moved isLoading assignment outside if-else
  });
};
